import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";
import { User } from "../userAccess/UserContext";

export interface CarrierDriverSettings {
  requireCarrierNumber: boolean;
  requireTransportNumber: boolean;
  requireDriverNumber: boolean;
  requireDriverPassword: boolean;
}

export default function useUpdateCarrierDriverSettings(user: User, options?: RequestOptions) {
  return useUserRequest<CarrierDriverSettings>({
    path: `/api/sites/${user.selectedSite!.id}/carrierDriverSettings`,
    method: HttpMethod.PUT
  }, options)
}
