import { useEffect, useRef } from "react";
import { createHashForStrings } from "../utilities";

export interface SelectOption {
  value: string,
  name?: string,
}

export interface SelectSearchProps {
  id: string,
  onChange: (value: string) => void;
  options: SelectOption[],
  hideSearch?: boolean,
  className?: string,
  selectedValue?: string,
  placeholder?: string,
  disabled?: boolean,
}

const SelectSearch = (props: SelectSearchProps) => {
  const ref = useRef<HTMLSelectElement>(null)
  const disabled = props.disabled ?? false;
  const key = createHashForStrings(
    [...props.options.map(o => o.value), props.selectedValue ?? "", props.disabled?.toString() ?? "false"]);

  useEffect(() => {
    // @ts-ignore
    if (!ref.current.tomselect) {
      // @ts-ignore
      window.initJsSelect(ref.current);
    }
  });

  const selectOptions = JSON.stringify({ placeholder: props.placeholder, hideSearch: props.hideSearch });

  return <div className={`tom-select-custom ${props.className}`} key={key}>
    <select className="js-select form-select"
      onChange={ev => props.onChange(ev.target.value)}
      autoComplete="off"
      id={props.id}
      data-hs-tom-select-options={selectOptions}
      ref={ref}
      key={key}
      disabled={disabled}
      placeholder={props.placeholder}
      value={props.selectedValue}
    >
      <option value="">
        {props.placeholder}
      </option>
      {props.options.map(o =>
        <option
          key={o.value}
          value={o.value}>
          {o.name ?? o.value}
        </option>)
      }
    </select>
  </div>
}

export default SelectSearch;
