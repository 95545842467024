import { useEffect } from "react";
import { MultiSelectSearch, SelectOption, SelectSearch } from "../../../common/forms";
import Row from "../../../common/Row";
import { Product } from "../../../requests/useGetProducts";
import { Answer, LoadQuestion, QuestionType } from "../../../requests/useUpdateLoadQuestions";
import DraggableAnswer from "./DraggableAnswer";

type QuestionProps = {
  loadQuestion: LoadQuestion;
  updateQuestion: (update: Partial<LoadQuestion>) => void;
  products: Product[];
  officePhoneNumber?: string;
}

export const ALL_PRODUCT_ID = '00000000-0000-0000-0000-000000000000';

const newAnswer = (text?: string): Answer =>
  ({ id: crypto.randomUUID(), text: text ?? '', correct: true });

const Question = ({ loadQuestion, updateQuestion, products, officePhoneNumber }: QuestionProps) => {
  const allSelected: SelectOption = { value: ALL_PRODUCT_ID, name: 'Require for all products' };
  const productOptions: SelectOption[] =
    [allSelected, ...products.map(p => ({ name: p.name, value: p.id }))];

  const pageState = {
    canAddAnswer: loadQuestion.type === QuestionType.MultipleChoice,
    canDragAndDrop: loadQuestion.type === QuestionType.MultipleChoice,
    showCorrectIncorrect: loadQuestion.type !== QuestionType.UserDefined,
    showYesOrNo: loadQuestion.type === QuestionType.YesNo,
    canRemoveAnswer: loadQuestion.type === QuestionType.MultipleChoice,
    canEditAnswer: loadQuestion.type === QuestionType.MultipleChoice,
    hasMinAndMax: loadQuestion.type === QuestionType.MultipleChoice,
  };

  const defaultIncorrectResponse = !!officePhoneNumber
    ? `Please call the office at ${officePhoneNumber}.`
    : "Please call the office.";

  useEffect(() => {
    if (loadQuestion.allProducts && !loadQuestion.productIds.includes(ALL_PRODUCT_ID)) {
      updateQuestion({ allProducts: loadQuestion.allProducts, productIds: productOptions.map(po => po.value) })
    }
  });

  useEffect(() => {
    if (loadQuestion.type === QuestionType.MultipleChoice && loadQuestion.answers.length === 0) {
      updateQuestion({ answers: [newAnswer()] });
    }
  });

  const updateQuestionType = (value: string) => {
    const newType = (value as unknown) as QuestionType;
    let update: Partial<LoadQuestion> = { type: newType };

    if (newType !== loadQuestion.type) {
      if (newType === QuestionType.MultipleChoice) {
        update.answers = [newAnswer()];
        update.minNumOfAnswers = 1;
        update.maxNumOfAnswers = 1;
        update.allowPlainTextAnswer = false;
      } else if (newType === QuestionType.YesNo) {
        update.answers = [newAnswer('Yes'), newAnswer('No')];
        update.minNumOfAnswers = 1;
        update.maxNumOfAnswers = 1;
        update.allowPlainTextAnswer = false;
      } else if (newType === QuestionType.UserDefined) {
        update.answers = [];
        update.minNumOfAnswers = 1;
        update.maxNumOfAnswers = 1;
        update.allowPlainTextAnswer = true;
      }
    }

    updateQuestion(update);
  }

  const updateProducts = (productIds: string[], allProducts: boolean) =>
    updateQuestion({ productIds, allProducts });

  const updateText = (value: string) =>
    updateQuestion({ question: value.substring(0, 100) });

  const updateIncorrectResponse = (value: string) =>
    updateQuestion({ incorrectAnswerMessage: value.substring(0, 100) });

  const productSelectUpdated = (values: string[]) => {
    if (values.includes(ALL_PRODUCT_ID)) {
      if (loadQuestion.productIds.includes(ALL_PRODUCT_ID)) {
        const productsWithoutAll = values.filter(v => v !== ALL_PRODUCT_ID);
        updateProducts(productsWithoutAll, false);
      } else {
        const allProductIds = productOptions.map(po => po.value);
        updateProducts(allProductIds, true);
      }
    } else {
      if (loadQuestion.productIds.includes(ALL_PRODUCT_ID)) {
        updateProducts([], false);
      } else {
        updateProducts(values, false);
      }
    }
  }

  const addAnswer = () => {
    updateQuestion({ answers: [...loadQuestion.answers, newAnswer()] });
  }

  const setMinAnswers = (value: number) => {
    updateQuestion({ minNumOfAnswers: value });
  }

  const setMaxAnswers = (value: number) => {
    updateQuestion({ maxNumOfAnswers: value });
  }

  const setOtherCheckbox = (value: boolean) => {
    var update = { ...loadQuestion, allowPlainTextAnswer: value };
    if (value) {
      update.minNumOfAnswers = 1;
      update.maxNumOfAnswers = 1;
    }
    updateQuestion(update);
  }

  const toggleCorrectIncorrect = (answerIndex: number) => {
    loadQuestion.answers[answerIndex].correct = !loadQuestion.answers[answerIndex].correct;
    updateQuestion({ answers: loadQuestion.answers });
  }

  const removeAnswer = (answerIndex: number) => {
    loadQuestion.answers.splice(answerIndex, 1);
    updateQuestion({ answers: loadQuestion.answers });
  }

  const updateAnswerText = (answerIndex: number, text: string) => {
    loadQuestion.answers[answerIndex].text = text;
    updateQuestion({ answers: loadQuestion.answers });
  }

  const moveAnswer = (dragIndex: number, hoverIndex: number) => {
    const spliceAnswers = [...loadQuestion.answers];
    spliceAnswers.splice(dragIndex, 1);
    spliceAnswers.splice(hoverIndex, 0, loadQuestion.answers[dragIndex]);

    const newAnswers = spliceAnswers.map((a, i) => ({ ...a, position: i }));

    updateQuestion({ answers: newAnswers });
  }

  return <div className="mb-5">
    <Row>
      <div className="col-5">
        <SelectSearch
          id={`type`}
          hideSearch={true}
          onChange={updateQuestionType}
          selectedValue={loadQuestion.type ?? QuestionType.MultipleChoice}
          placeholder="Select Question Type"
          options={[
            { value: QuestionType.MultipleChoice, name: 'Multiple Choice' },
            { value: QuestionType.YesNo, name: 'Yes/No' },
            { value: QuestionType.UserDefined, name: "User Defined" }]} />
      </div>
      <div className="col-1" />
      <div className="col-5">
        <MultiSelectSearch
          id={`products`}
          singleMultiple={true}
          hideSelected={false}
          hideSearch={productOptions.length < 10}
          values={loadQuestion.productIds}
          onChange={productSelectUpdated}
          placeholder="Select products"
          options={productOptions} />
      </div>
    </Row>
    <Row className="mt-4">
      <label className="col-8" htmlFor='questionText'>
        Question Text*
      </label>
      <span className="col-3 text-end text-muted">
        {`${loadQuestion.question?.length.toString() ?? 0} / 100`}
      </span>
    </Row>
    <Row>
      <div className="col-11">
        <textarea
          id='questionText'
          style={{ resize: 'none' }}
          className="form-control"
          value={loadQuestion.question ?? ''}
          onChange={ev => updateText(ev.target.value)} />
      </div>
    </Row>
    <Row className="mt-3">
      {
        pageState.showCorrectIncorrect &&
        <label className="col-3 px-6 text-center">
          Correct or Incorrect
        </label>
      }
      {
        pageState.hasMinAndMax &&
        <>
          <label htmlFor="minAnswers" className="col-1 col-form-label text-end text- pe-0">Min:</label>
          <div className="col-1">
            <input
              id="minAnswers"
              type="number"
              disabled={loadQuestion.allowPlainTextAnswer}
              className="form-control form-control-sm px-2"
              value={loadQuestion.minNumOfAnswers}
              onChange={ev => setMinAnswers(ev.target.valueAsNumber)} />
          </div>
          <label htmlFor="maxAnswers" className="col-1 form-label col-form-label text-end pe-0">Max:</label>
          <div className="col-1">
            <input
              id="maxAnswers"
              type="number"
              disabled={loadQuestion.allowPlainTextAnswer}
              className="form-control form-control-sm px-2"
              value={loadQuestion.maxNumOfAnswers}
              onChange={ev => setMaxAnswers(ev.target.valueAsNumber)} />
          </div>
          <div className="col-4 mt-2 text-end">
            <div className="form-check form-check-inline">
              <input
                id="otherCbx"
                type="checkbox"
                checked={loadQuestion.allowPlainTextAnswer}
                onChange={ev => setOtherCheckbox(ev.target.checked)}
                className="form-check-input" />
              <label className="form-check-label" htmlFor="otherCbx">Other (please specify)</label>
            </div>
          </div>
        </>
      }
    </Row>
    {loadQuestion.answers.length === 0 && <Row className="mt-3" />}
    {
      loadQuestion.answers.map((answer, i) =>
        <DraggableAnswer
          key={answer.id}
          id={answer.id}
          dragAndDropEnabled={pageState.canDragAndDrop}
          index={i}
          moveAnswer={moveAnswer}>
          <div className="col-1 ps-1">
            {
              answer.correct
                ? <button type="button" className="btn btn-ghost-success btn-icon" onClick={() => toggleCorrectIncorrect(i)}>
                  <strong>
                    <i className="bi-check2-circle" style={{ fontSize: '1.75rem' }} />
                  </strong>
                </button>
                : <button type="button" className="btn btn-ghost-danger btn-icon" onClick={() => toggleCorrectIncorrect(i)}>
                  <strong>
                    <i className="bi-x-circle" style={{ fontSize: '1.75rem' }} />
                  </strong>
                </button>
            }
          </div>
          <div className="col-9">
            <input
              className="form-control"
              disabled={!pageState.canEditAnswer}
              value={answer.text}
              onChange={(ev) => updateAnswerText(i, ev.target.value)} />
          </div>
          <div className="col-1">
            {pageState.canRemoveAnswer &&
              <button
                type="button"
                disabled={loadQuestion.answers.length === 1}
                className="btn-close btn-sm mt-2 btn btn-link"
                onClick={() => removeAnswer(i)}>
                <strong />
              </button>
            }
          </div>
        </DraggableAnswer>)
    }
    {
      pageState.canAddAnswer &&
      <Row>
        <button type="button" className="offset-2 col-2 btn-ghost-primary btn btn-link btn-sm" onClick={addAnswer}>
          <strong>
            Add Answer
          </strong>
        </button>
      </Row>
    }
    <Row>
      <label className="col-8 mt-2" htmlFor="incorrectResponse">
        Incorrect Response to Driver
      </label>
      <span className="col-3 text-end text-muted mt-2">
        {`${loadQuestion.incorrectAnswerMessage?.length.toString() ?? 0} / 100`}
      </span>
    </Row>
    <Row>
      <div className="col-11">
        <textarea
          id="incorrectResponse"
          className="form-control"
          style={{ resize: 'none' }}
          placeholder={defaultIncorrectResponse}
          value={loadQuestion.incorrectAnswerMessage ?? ''}
          onChange={ev => updateIncorrectResponse(ev.target.value)} />
      </div>
    </Row>
  </div >
}

export default Question;
