import { SubmitHandler, useForm } from "react-hook-form";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../requests/useGetSiteSettings";
import useUpdateLoadConfigurationSettings from "../../requests/useUpdateLoadConfigurationSettings";

interface LoadConfigSettings {
  allowExceedOrderAmount: boolean;
}

const LoadConfigSettingsForm = ({ initialSettings }: { initialSettings: LoadConfigSettings }) => {
  const displayMessage = useDisplayMessage();

  const updateLoadConfig = useUpdateLoadConfigurationSettings({
    onSuccess: () => displayMessage.success("Load configuration updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const { register, handleSubmit } = useForm<LoadConfigSettings>({
    defaultValues: initialSettings,
  });

  const onSubmit: SubmitHandler<LoadConfigSettings> = (formValue) =>
    updateLoadConfig.request({ allowExceedOrderAmount: formValue.allowExceedOrderAmount });

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form className="m-auto w-50">
      <h2>Load Configuration</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <div className="form-check">
            <input
              type="checkbox"
              id="allowExceedOrderAmount"
              className="form-check-input"
              {...register("allowExceedOrderAmount")} />
            <label
              className="form-check-label"
              htmlFor="allowExceedOrderAmount">
              Allow driver to request more than ordered amount
            </label>
          </div>
        </div>
      </div>
    </form>
    <div className="align-self-end">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateLoadConfig.isLoading}
        onClick={handleSubmit(onSubmit)}>
        Save
      </SpinnerButton>
    </div>
  </>
}

const LoadConfiguration = () => {
  const useGetLoadConfigSettings = useGetSiteSettings<LoadConfigSettings>();

  return <QueryView
    query={useGetLoadConfigSettings}
    renderData={response => <LoadConfigSettingsForm initialSettings={response} />
    } />
}

export { LoadConfiguration };
