import KaModal from './KaModal';
import Button from './Button';

interface ConfirmProps {
  visible: boolean;
  title: string;
  body: string;
  onDismiss: () => void;
  onConfirm: () => void;
}

const Confirm = (props: ConfirmProps) => {
  return (<KaModal
    isOpen={props.visible}
    title={props.title}
    body={props.body}
    footer={
      <div className="container">
        <div className="row justify-content-between">
          <Button className='col-3 btn-secondary' onClick={props.onDismiss}>Cancel</Button>
          <Button className='col-3 btn-danger' onClick={props.onConfirm}>Yes</Button>
        </div>
      </div>
    }
  />)
};

export default Confirm;