import React, { useState } from "react";
import QueryView from "../common/lib/QueryView";
import { useGetSites } from "../requests/useGetSites";
import { AdminSite } from "../userAccess/UserContext";
import { KAEmployeeLayout } from "./KAEmployeeLayout";

const KaEmployeeContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const getSites = useGetSites();

  return <QueryView
    query={getSites}
    renderData={sites =>
      <SelectedSiteProvider defaultSite={sites[0]}>
        <KAEmployeeLayout
          sites={sites}>
          {children}
        </KAEmployeeLayout>
      </SelectedSiteProvider>
    }
  />
}

interface SiteContextType {
  selectedSite: AdminSite;
  setSelectedSite: (site: AdminSite) => void;
}

export const SelectedSiteContext = React.createContext<SiteContextType>({ selectedSite: { state: "", city: "", id: "" }, setSelectedSite: () => null });

export const SelectedSiteProvider: React.FC<{ defaultSite: AdminSite, children?: React.ReactNode }> = ({ defaultSite, children }) => {
  const [selectedSite, setSelectedSite] = useState<AdminSite>(defaultSite);

  return (
    <SelectedSiteContext.Provider value={{ selectedSite, setSelectedSite }}>
      {children}
    </SelectedSiteContext.Provider>
  );
};

export { KaEmployeeContainer };