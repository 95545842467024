import { Units } from "./units";

const toLbsFactor = (to: Units) => {
  switch (to) {
    case Units.lb:
      return 1;

    case Units.kg:
      return 1 / 2.20462;

    case Units.oz:
      return 16;

    case Units.t:
      return 1 / 1000;

    case Units.ton:
      return 1 / 2000;
  }
}

const convertUnit = (value: (number | string), from: Units, to: Units) =>
  parseFloat(convertUnitToString(value, from, to));

const convertUnitToString = (value: (number | string), from: Units, to: Units) => {
  if (typeof value === 'number') {
    value = value.toString();
  }

  const precision = getSigFigs(value);

  var valueAsLbs = parseFloat(value) / toLbsFactor(from);
  var valueAsTo = valueAsLbs * toLbsFactor(to);
  
  return (valueAsTo).toPrecision(precision === 0 ? undefined : precision);
}
  
const log10 = Math.log(10);

const getSigFigs = (s: string) => {
  var n = Math.abs(parseFloat(s.replace(".", "")));

  if (n === 0) return 0;
  while (n !== 0 && n % 10 === 0) n /= 10;

  return Math.floor(Math.log(n) / log10) + 1;
}

export { convertUnit, getSigFigs };
