import React from 'react'

export enum KioskState {
  NotRegistered,
  Authorizing,
  SignedIn,
}

export enum Role {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Both = 'Both',
}

export type KioskStatus =
  | {
    state: KioskState.NotRegistered;
    register: (kioskId: string, secret: string) => void;
  }
  | { state: KioskState.Authorizing; }
  | Kiosk;

export interface Kiosk {
  state: KioskState.SignedIn;
  id: string;
  token: string;
  siteId: string;
  scaleId?: string;
  role: Role;
}

export interface AdminSite {
  id: string,
  city: string,
  state: string
}

export const KioskContext = React.createContext<KioskStatus>({ state: KioskState.NotRegistered, register: () => undefined });
