import { useContext, useEffect } from "react";
import FullscreenLoadingSpinner from "./common/FullscreenLoadingSpinner";
import { AdminPortalLayout } from "./pages/AdminPortal/AdminPortalLayout";
import { UserContext, UserState } from "./userAccess/UserContext";

const SiteAdminRequired: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const userStatus = useContext(UserContext);

  useEffect(() => {
    if (userStatus.state === UserState.SignedOut) {
      userStatus.signIn();
    }
  }, [userStatus]);

  if (userStatus.state === UserState.Loading) return <FullscreenLoadingSpinner />
  if (userStatus.state === UserState.SignedOut) return <></>;

  return <AdminPortalLayout>{children}</AdminPortalLayout>;
}

export default SiteAdminRequired;