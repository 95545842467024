import { useUserQuery } from "../common/lib/fetch";
import { QueryOptions } from "../common/lib/fetch/useQuery";
import { useUser } from "../userAccess/useUser";

export interface StagedOrderDetails {
  id: string;
  orderNumber: string;
  userEmail: string;
  step: string;
}

export const useGetStagedOrders = (options?: QueryOptions<StagedOrderDetails[]>) => {
  const user = useUser();

  return useUserQuery<StagedOrderDetails[]>({
    path: `/api/sites/${user.selectedSite?.id}/stagedOrders`,
  }, options);
}
