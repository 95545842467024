import React from 'react';
import { FormCommonProps } from './FormCommon';
import { FormLabel } from './FormLabel';
import { FormErrorProps, useFormError } from '../lib/hooks/useFormError';

interface FormTextProps<FormType> extends FormCommonProps<FormType> {
  autoComplete?: boolean,
  placeholder?: string,
}

export function FormText(props: FormTextProps<any>) {
  return <div className="mb-4">
    <FormLabel {...props} />
    <FormTextControl {...props} />
  </div>
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function toDisplayFromCamelCase(string: string) {
  return capitalizeFirstLetter(string.replace(/([A-Z])/g, ' $1'))
}

function FormTextControl(props: FormTextProps<any>) {
  const { inError, errorMessage } = useFormError(props as FormErrorProps<any>);
  const placeholder = (props.displayName ?? toDisplayFromCamelCase(props.name)).toLowerCase();
  const controlProps = props.register(props.name, { ...props.options });

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.setValue(props.name, e.target.value?.trim());
    controlProps.onBlur(e);
  }

  return <>
    <input
      type='text'
      className={`form-control ${inError ? 'is-invalid' : ''}`}
      placeholder={props.placeholder ?? `Enter ${placeholder}`}
      id={props.name}
      ref={controlProps.ref}
      name={controlProps.name}
      onChange={controlProps.onChange}
      onBlur={onBlur}
      autoComplete={props.autoComplete === undefined || props.autoComplete ? "on" : "off" }
      disabled={!(props.enabled ?? true)}
    />
    {inError && <span className="invalid-feedback">
      {errorMessage}
    </span>}
  </>
}
