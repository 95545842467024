import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import { Units } from "../../common/units/units";
import useWeight from "../../common/units/useWeight";
import Container from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import LoadTareWeight from "./LoadTareWeight";
import useAcceptTareWeight from "../../requests/useAcceptTareWeight";
import { driverFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import { useLingui } from "@lingui/react";

export type KioskTareWeightProps = {
  siteId: string;
  scaleId: string;
  soId: string;
  requestedAmount: number,
  tareAccepted: () => void;
}

const KioskTareWeight = (props: KioskTareWeightProps) => {
  const { _ } = useLingui();
  const tareWeight = useWeight({ value: 0, unit: Units.lb });
  const displayMessage = useDisplayMessage();

  const acceptTare = useAcceptTareWeight(props.soId, {
    onSuccess: props.tareAccepted,
    onError: err => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  return <Container
    title="Verify Tare Weight">
    <div className="m-auto w-75">
      <KaAlert
        displayMessage={displayMessage.message}
        onClose={displayMessage.clear} />
      <LoadTareWeight
        requestedAmount={props.requestedAmount}
        tareWeight={tareWeight}
        tareInLb={tareWeight.as(Units.lb)}
        siteId={props.siteId}
        scaleId={props.scaleId}
        newTare={tareWeight.setWeight} />
    </div>
    <Footer nextButton={
      <FooterButton
        spinning={acceptTare.isLoading}
        onClick={() => acceptTare.request()}
        disabled={!tareWeight.value || tareWeight.value <= 0}>
        Accept Weight
      </FooterButton>
    } />
  </Container>
}

export default KioskTareWeight;
