import { useState } from "react";
import Container, { Progress } from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import { LoadQuestion, LoadQuestionAnswer } from "../../UserFlow";
import { FooterProps } from "./StageOrderTypes";
import { AnsweredQuestion } from "../../requests/useCreateStagedOrder"
import { MultiSelectSearch, SelectSearch } from "../../common/forms";
import Row from "../../common/Row";
import KaAlert, { DisplayMessage } from "../../common/lib/KaAlert";
import SwitchToMobileQRCode from "../../common/SwitchToMobileQRCode";
import useIsAnonymous from "../../common/lib/hooks/useIsAnonymous";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { PopUpMessageView } from "../../common/PopUpMessageView";

interface LoadQuestionProps extends FooterProps {
  loadQuestions: LoadQuestion[],
  message: DisplayMessage | null,
  clearMessage: () => void,
  loadQuestionsAnswered: (answeredQuestions: AnsweredQuestion[]) => void,
  loadQuestionsAnswerRejected: () => void,
  progress?: Progress,
}

type QuestionProps = {
  loadQuestion: LoadQuestion,
  setAnswer: (answer: LoadQuestionAnswer[]) => void,
}

const Question = ({ loadQuestion, setAnswer }: QuestionProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const OtherAnswerValue = _(msg`Other...`);
  const onlyPlainText = loadQuestion.answers.length === 0 && loadQuestion.allowPlainTextAnswer;
  const [dropdownAnswer, setDropdownAnswer] = useState<string>();
  const [dropdownAnswers, setDropdownAnswers] = useState<string[]>([]);
  const [otherAnswer, setOtherAnswer] = useState<string | null>(null);

  const updateDropdownAnswers = (indices: string[]) => {
    setDropdownAnswers(indices);
    setAnswer(
      options.filter(
        (_, i) => indices.includes(i.toString())
      )
    );
  }

  const updateDropdownAnswer = (index: number) => {

    const newValue = options[index];

    if (newValue.text === OtherAnswerValue) {
      setOtherAnswer("");
      setAnswer([]);
    } else {
      setAnswer([newValue]);
      setOtherAnswer(null);
    }

    setDropdownAnswer(index.toString());
  }

  const updateOtherAnswer = (newValue: string) => {
    setAnswer([{ text: newValue, correct: newValue !== "" }]);
    setOtherAnswer(newValue);
  }

  const options = [...loadQuestion.answers];
  if (loadQuestion.allowPlainTextAnswer) {
    options.push({ text: OtherAnswerValue, correct: false });
  }
  
  const displayAnswerCount = (loadQuestion: LoadQuestion) =>
    loadQuestion.minNumOfAnswers !== loadQuestion.maxNumOfAnswers
      ? loadQuestion.minNumOfAnswers + "-" + loadQuestion.maxNumOfAnswers
      : loadQuestion.maxNumOfAnswers;

  return <div className="mt-5">
    {!onlyPlainText && loadQuestion.maxNumOfAnswers === 1 &&
      <Row className="mx-2">
        <h2>{loadQuestion.question}</h2>

        <SelectSearch
          id={loadQuestion.id}
          className="p-0"
          onChange={(v) => updateDropdownAnswer(parseInt(v))}
          hideSearch={options.length < 5}
          selectedValue={dropdownAnswer ?? "0"}
          placeholder={_(msg`No answer selected`)}
          options={options.map((a, i) => ({ name: a.text, value: i.toString() }))} />
      </Row>
    }
    {onlyPlainText &&
      <Row className="mx-2 mt-3">
        <h2>{loadQuestion.question}</h2>
        <input
          className="form-control"
          type="text"
          placeholder={_(msg`Enter answer...`)}
          onChange={ev => updateOtherAnswer(ev.target.value)}
          value={otherAnswer ?? ""}
          autoComplete={!isAnonymous ? "on" : "off"} />
      </Row>
    }
    {otherAnswer !== null && !onlyPlainText &&
      <Row className="mx-2 mt-3">
        <input
          className="form-control"
          type="text"
          placeholder={_(msg`Enter other answer...`)}
          onChange={ev => updateOtherAnswer(ev.target.value)}
          value={otherAnswer ?? ""}
          autoComplete={!isAnonymous ? "on" : "off"} />
      </Row>
    }
    {!onlyPlainText && loadQuestion.maxNumOfAnswers !== 1 &&
      <Row className="mx-2">
        <h2>{loadQuestion.question}</h2>
        <div className="mb-1"> {_(msg`(Select ${displayAnswerCount(loadQuestion)} answers)`)}</div>
      
        <MultiSelectSearch
          id={loadQuestion.id}
          className="p-0"
          onChange={v => updateDropdownAnswers(v)}
          singleMultiple={true}
          hideSelected={false}
          hideSearch={options.length < 5}
          values={dropdownAnswers}
          placeholder={_(msg`No answers selected`)}
          options={options.map((a, i) => ({ name: a.text, value: i.toString() }))}
          testId={loadQuestion.id} /> 
      </Row>
    }
  </div>
}

const LoadQuestions = (props: LoadQuestionProps) => {
  const { _ } = useLingui();
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [answers, setAnswers] = useState<LoadQuestionAnswer[][]>(
    props.loadQuestions.map(
      (lq, i) => lq.maxNumOfAnswers > 1 ? [] : [lq.answers[0]]
    ));

  const isComplete = (answeredQuestion: LoadQuestionAnswer[][]) => {
    for (var i = 0; i < answeredQuestion.length; i++) {
      const answer = answeredQuestion[i];
      if (answer.length === 0) {
        return false;
      }

      const minNumOfAnswers = props.loadQuestions[i].minNumOfAnswers;
      const maxNumOfAnswers = props.loadQuestions[i].maxNumOfAnswers;

      if (answer.length === 1)
        if (answer[0] === undefined || answer[0].text === "")
          return false;

      if (answer.length > maxNumOfAnswers || answer.length < minNumOfAnswers)
        return false;

    }
    return true;
  }

  const [complete, setComplete] = useState(isComplete(answers));

  const isAnonymous = useIsAnonymous();

  const submitLoadQuestion = () => {

    const incorrectQuestionIndex = answers.findIndex(
      (lqa) => !lqa.every(
        (a) => a.correct === true
      )
    );

    if (incorrectQuestionIndex === -1) {
      const answeredQuestions = props.loadQuestions.map(
        (lq, i) => ({ id: lq.id, answers: answers[i].map((a) => a.text) })
      );
      props.loadQuestionsAnswered(answeredQuestions);
    } else {
      setAlertMessage(props.loadQuestions[incorrectQuestionIndex].incorrectAnswerMessage);
    }
    
  }

  const updateAnswer = (index: number, newAnswer: LoadQuestionAnswer[]) => {
    const updated = [...answers];
    updated[index] = newAnswer;
    setAnswers(updated);
    setComplete(isComplete(updated));
  }

  const formWidth = isAnonymous ? "w-65 " : "";

  return <PopUpMessageView message={alertMessage} messageAcknowledged={() => props.loadQuestionsAnswerRejected()}>
    <Container
      title={_(msg`Please answer these questions`)}
      progress={props.progress}>

      {isAnonymous && <SwitchToMobileQRCode />}
      <div className={formWidth + "my-auto container"}>
        <KaAlert displayMessage={props.message} onClose={props.clearMessage} />
        {
          props.loadQuestions.map((lq, i) =>
            <Question
              key={i}
              loadQuestion={lq}
              setAnswer={(a) => updateAnswer(i, a)} />
          )
        }
      </div>
      <Footer
        backButton={<FooterButton {...props.backButton} />}
        nextButton={<FooterButton
          {...props.nextButton}
          disabled={!complete}
          onClick={submitLoadQuestion}>
          <Trans>Complete</Trans>
        </FooterButton>}
      />
    </Container>
  </PopUpMessageView>
}

export default LoadQuestions;
