import { msg, Trans } from "@lingui/macro";
import { useContext, useEffect } from "react";
import { defaultLocale, dynamicActivate, locales } from "../i18n";
import { UserContext, UserState } from "../userAccess/UserContext";
import useIsAnonymous from "./lib/hooks/useIsAnonymous";
import SlideOutButton from "./SlideOutButton";
import useLocalStorage from "./lib/hooks/useLocalStorage";
import { isProdEnvironment } from "./utilities";
import { useLingui } from "@lingui/react";

export interface HeaderProps {
  children: React.ReactNode,
  devToolItems?: React.ReactNode,
  actionItems?: React.ReactNode,
}

export const Header = (props: HeaderProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const userStatus = useContext(UserContext);
  const showLogout = userStatus.state === UserState.SignedIn;

  const [currentLanguage, setCurrentLanguage] = useLocalStorage("language", defaultLocale);

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
    window.location.reload();
  }

  const showDevToolItems = !isProdEnvironment() && !!props.devToolItems;
  const showMenuButton = showDevToolItems || !isAnonymous;

  return <div className="w-100 h-100 bg-primary">
    <div className="position-relative w-100 h-100">
      <div className="row w-100 position-absolute top-50 translate-middle-y gx-0">
        {props.children}
      </div>
      {showMenuButton &&
        <>
          <div className="dropdown position-absolute top-0 start-0">
            <button className="btn btn-primary btn-sm p-0" type="button" id="mobileMenuButton"
              data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-label="Toggle Mobile Menu" aria-expanded="false" aria-controls="mobileMenu">
              <span className="d-flex justify-content-between align-items-center">
                <span className="navbar-toggler-default">
                  <i className="bi-list" style={{ fontSize: "2rem" }} />
                </span>
              </span>
            </button>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} style={{ width: "60%" }} id="mobileMenu" aria-labelledby="mobileMenuLabel">
            <div className="offcanvas-header bg-primary p-3">
              <h2 className="offcanvas-title text-white" id="mobileMenuLabel">LOADPASS</h2>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close Mobile Menu" />
            </div>

            <div className="offcanvas-body p-0">
              <div className="nav nav-pills nav-vertical container mt-1 ps-2">
                {showDevToolItems &&
                  <>
                    <span className="text-cap mb-1 mt-1">Dev Tools</span>
                    {props.devToolItems}
                  </>
                }
                <span className="text-cap mb-1 mt-1"><Trans>Settings</Trans></span>
                <div className="input-group my-2 mr-5">
                  <label className="input-group-text" htmlFor="selectLocale"><i className="bi dropdown-item-icon bi-globe2"/></label>
                  <select
                    id="selectLocale"
                    value={currentLanguage}
                    onChange={ev => changeLanguage(ev.target.value)}
                    className="form-select">
                    {Object.keys(locales).map((key) => 
                      <option key={key} value={key}>{locales[key]}</option>)}
                  </select>
                </div>
                {(props.actionItems || showLogout) &&
                  <>
                    <span className="text-cap mb-1 mt-1"><Trans>Actions</Trans></span>
                    {props.actionItems}
                    {showLogout &&
                      <SlideOutButton
                        icon="box-arrow-right"
                        text={_(msg`Logout`)}
                        data-bs-toggle="modal"
                        data-bs-target="#logoutModal" />
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </>
      }
      {showLogout &&
        <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="logoutLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title" id="logoutLabel"><Trans>Are you sure?</Trans></h5>
              </div>
              <div className="modal-body">
                <Trans>We recommend that you stay logged in for quicker access to order check-in.</Trans>
              </div>
              <div className="modal-footer justify-content-evenly align-items-stretch">
                <button type="button" className="btn btn-white w-35" data-bs-dismiss="modal"><Trans>Cancel</Trans></button>
                <button type="button"
                  className="btn btn-primary w-35"
                  onClick={() => userStatus.signOut()}
                  data-bs-dismiss="modal">
                  <Trans>Logout</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
