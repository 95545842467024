
interface SlideOutButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: string;
  text: string;
}

const SlideOutButton = (props: SlideOutButtonProps) =>
  <button className="nav-link py-1" {...props}>
    {props.icon && <i className={`me-2 bi-${props.icon}`} />}
    {props.text}
  </button>

export default SlideOutButton;
