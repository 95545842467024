import { BrowserCacheLocation } from "@azure/msal-browser";
import { MsalConfiguration } from "./common/lib/hooks/msal/useMsalAuth";

const scopes: Array<string> = ["openid", "https://LoadPass.onmicrosoft.com/LoadPass/All"];

export const KaEmployeeAuthority: string =
  "https://LoadPass.b2clogin.com/LoadPass.onmicrosoft.com/b2c_1_Kahler_Employee";

export const msalConfig: MsalConfiguration = {
  pcaConfig: {
    auth: {
      clientId: "c069d4f0-e00a-49e6-a9b5-3cca4824eaca",
      authority: "https://LoadPass.b2clogin.com/LoadPass.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
      knownAuthorities: ["LoadPass.b2clogin.com"],
      redirectUri: "/",
      postLogoutRedirectUri: "/"
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  },
  signInRequest: {
    scopes,
    authority: "https://LoadPass.b2clogin.com/LoadPass.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
  },
  forgotPasswordRequest: {
    scopes,
    authority: "https://LoadPass.b2clogin.com/LoadPass.onmicrosoft.com/b2c_1_forgot_password"
  }
};
