import Button from "./Button";

interface SpinnerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  spinning: boolean,
}

const SpinnerButton = (props: SpinnerButtonProps) => {
  const { spinning, ...rest } = props
  return <Button
    {...rest}
    disabled={props.disabled || spinning}>
    {spinning
      ? <span className="spinner-border spinner-border-sm" role="status"></span>
      : props.children}
  </Button>
}

export default SpinnerButton;