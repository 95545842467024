export enum DayOfTheWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export interface Hours {
  day: DayOfTheWeek;
  openTime?: string;
  closeTime?: string;
}
