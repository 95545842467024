import { useUserOrKioskQuery, QueryOptions } from "../common/lib/fetch";
import { Units } from "../common/units/units";

export interface GetScaleWeightResp {
  value: string;
  unit: Units;
}

type Options = QueryOptions<GetScaleWeightResp>;

const useGetScaleWeight = (siteId: string, scaleId: string, options: Options) =>
  useUserOrKioskQuery<GetScaleWeightResp>({
    path: `/api/sites/${siteId}/scales/${scaleId}/scaleWeight`
  }, options);

export default useGetScaleWeight;
