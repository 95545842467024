import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import useInterval from "../common/lib/hooks/useInterval";
import KaAlert from "../common/lib/KaAlert";
import Row from "../common/Row";
import { Units } from "../common/units/units";
import useWeight, { Weight } from "../common/units/useWeight";
import Container, { Progress } from "../common/Container";
import Footer from "../common/Footer";
import FooterButton from "../common/FooterButton";
import useAcceptGrossWeight from "../requests/useAcceptGrossWeight";
import useGetScaleWeight from "../requests/useGetScaleWeight";
import useRejectGrossWeight from "../requests/useRejectGrossWeight";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { driverFacingErrorMessage } from "../common/userFacingMessages/userFacingMessages";
import { unitsToDisplay } from "../common/units/unitsToDisplay";

interface GrossWeightProps {
  siteId: string,
  scaleId: string,
  stagedOrderId: string,
  tareWeight: Weight,
  allowReject: boolean,
  devToolItems?: React.ReactNode,
  progress?: Progress,
  grossAccepted: () => void,
  grossRejected?: (officePhoneNumber?: string) => void,
}

const GrossWeight = (props: GrossWeightProps) => {
  const { _ } = useLingui();
  const refreshTimeMs = 2000;
  const grossWeight = useWeight({ value: 0, unit: Units.lb });
  const tareWeight = useWeight(props.tareWeight);

  const getGross = useGetScaleWeight(
    props.siteId,
    props.scaleId,
    {
      onSuccess: gross => grossWeight.setWeight(
        gross.value,
        gross.unit
      ),
      onError: () => grossWeight.setWeight(0, Units.lb),
    });

  const displayMessage = useDisplayMessage();

  const acceptGross = useAcceptGrossWeight(props.stagedOrderId, {
    onSuccess: () => props.grossAccepted(),
    onError: err => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  const rejectGross = useRejectGrossWeight(props.stagedOrderId, {
    onSuccess: resp => props.grossRejected?.(resp.officePhoneNumber),
    onError: err => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  const netWeight = grossWeight.as(Units.lb) - tareWeight.as(Units.lb);

  useInterval(() => getGross.query(), refreshTimeMs, false);

  return <Container
    title={_(msg`Verify Gross Weight`)}
    progress={props.progress}
    devToolItems={props.devToolItems}>
    <div className="m-auto w-75">
      <KaAlert
        displayMessage={displayMessage.message}
        onClose={displayMessage.clear} />
      <Row>
        <div className="col-7 mt-5">
          <label className="h5" htmlFor='grossWeight'><Trans>Gross Weight</Trans></label>
          <div className="input-group input-group-sm mt-1">
            <input
              id='grossWeight'
              type='text'
              className='form-control form-control-sm p-2 text-end'
              value={grossWeight.value}
              readOnly />
            <div className="input-group-append input-group-text">
              {_(unitsToDisplay(grossWeight.unit))}
            </div>
          </div>
        </div>
        <div className="col-5 mt-5 text-end px-0">
          <div>
            <h5><Trans>Net Weight</Trans></h5>
          </div>
          <div>
            <label>
              {_(msg`${netWeight > 0 ? netWeight : 0} lb`)}
            </label>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-7" />
        <div className="col-5 mt-5 text-end px-0">
          <div>
            <h5><Trans>Tare Weight</Trans></h5>
          </div>
          <div>
            <label>
              {_(msg`${tareWeight.as(Units.lb)} lb`)}
            </label>
          </div>
        </div>
      </Row>
    </div>
    <Footer
      backButton={
        props.allowReject &&
        <FooterButton
          spinning={rejectGross.isLoading || acceptGross.isLoading}
          onClick={() => rejectGross.request()}>
          <Trans>Reject Weight</Trans>
        </FooterButton>
      }
      nextButton={
        <FooterButton
          spinning={rejectGross.isLoading || acceptGross.isLoading}
          onClick={() => acceptGross.request()}
          disabled={!grossWeight.value || netWeight <= 0}>
          <Trans>Accept Weight</Trans>
        </FooterButton>
      }
    />
  </Container>
}

export default GrossWeight;
