import useRequest, { Request, RequestOptions } from './useRequest';
import { useUser } from '../../../userAccess/useUser';
import { HttpMethod } from './useFetch';

export interface UserRequestSpec {
    path: string;
    method: HttpMethod;
}

export default function useUserRequest<Req, Resp = void>(spec: UserRequestSpec, options?: RequestOptions<Resp>): Request<Resp, Req> {
    const user = useUser();
    return useRequest<Req, Resp>({
        path: spec.path,
        method: spec.method,
        headers: user.fetchHeaders,
    }, options);
}