import useQuery, { QueryOptions } from "../common/lib/fetch/useQuery";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface Site {
  id: string;
  city: string;
  state: string;
  business: string;
  number: string;
  serviceContractNumber: string;
  admins: string[];
}

export const useGetSites = (options?: QueryOptions<Site[]>) => {
  const kaEmployee = useKaEmployee();

  return useQuery<Site[]>({
    headers: kaEmployee.fetchHeaders,
    path: '/api/sites'
  }, options);
}
