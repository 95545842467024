import { Compartment } from "../requests/useCreateStagedOrder";

export const sum = (values: number[]) =>
  values.reduce((s, v) => s = +s + +v, 0);

export const sumWeights = (compartments: Compartment[]) =>
  sum(compartments.map(c => c.weight).filter(w => w));

export const arraysEqual = (a: string[], b: string[]) =>
  (a.length === b.length) && a.every((element) => b.includes(element));

export const isProdEnvironment = () =>
  process.env.REACT_APP_ENVIRONMENT === "prod";

export const isPwaMode = () => {
  // iOS progressive web app detection
  const navigator: any = window.navigator;
  if (navigator.standalone)
    return true;

  // default progressive web app detection
  return ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}

const ALIAS_DICTIONARY: Record<string, string> = {
  "st.": "Saint", "st": "Saint",
  "mt.": "Mount", "mt": "Mount",
  "ft.": "Fort", "ft": "Fort",

  "n.": "North", "n": "North",
  "s.": "South", "s": "South",
  "w.": "West", "w": "West",
  "e.": "East", "e": "East",
  "n.w.": "Northwest", "nw": "Northwest",
  "n.e.": "Northeast", "ne": "Northeast",
  "s.w.": "Southwest", "sw": "Southwest",
  "s.e.": "Southeast", "se": "Southeast",
}

export const getFullNameFromAlias = (a: string) => {
  let words: string[] = a.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (ALIAS_DICTIONARY[words[i].toLowerCase()]) {
      words[i] = ALIAS_DICTIONARY[words[i].toLowerCase()];
    }
  }
  return words.join(" ");
}

function createHashForString(s: string) {
  var hash = 0, i, chr;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const createHashForStrings = (strings: Array<string>) => 
  strings.reduce((sum, s) => sum + createHashForString(s), 0);

export const capitalizeFirstLetter = (s: string) =>
  s ? s[0].toUpperCase() + s.slice(1) : ""