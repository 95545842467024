import QRCode from "react-qr-code";

const SwitchToMobileQRCode = () => {
  return (
    <div>
      <div className="position-relative">
        <div className="position-absolute top-0 end-0" style={{ width: "min-content" }}>
          <div className="text-center px-3 pt-3 pb-1">
            <QRCode
              value={window.location.origin}
              size={126}
            />
          </div>
          <div>
            Scan to check-in with your phone next time.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwitchToMobileQRCode;