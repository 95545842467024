import { useFieldArray, useForm } from "react-hook-form";
import KaAlert, { DisplayMessage } from "../../common/lib/KaAlert";
import { sumWeights } from "../../common/utilities";
import Container, { Progress } from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import { Compartment } from "../../requests/useCreateStagedOrder";
import { FooterProps } from "./StageOrderTypes";
import SwitchToMobileQRCode from "../../common/SwitchToMobileQRCode";
import useIsAnonymous from "../../common/lib/hooks/useIsAnonymous";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";

interface ConfigureLoadProps extends FooterProps {
  requested: number,
  orderNumber: string,
  message: DisplayMessage | null,
  clearMessage: () => void,
  initialCompartments?: Compartment[],
  compartmentsConfigured: (compartments: Compartment[]) => void,
  progress?: Progress,
  allowExceedOrderAmount: boolean;
}

interface ConfigureLoadForm {
  compartments: { name: string, weight: number }[]
}

const ConfigureLoad = (props: ConfigureLoadProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const maxCompartments = 8;

  const toFormCompartments = (compartments: Compartment[]) =>
    compartments.map(({ weight }, i) => ({ name: _(msg`Compartment ${i + 1}`), weight }));

  const { register, handleSubmit, watch, control, formState: { errors } } = useForm<ConfigureLoadForm>({
    defaultValues: {
      compartments: props.initialCompartments
        ? toFormCompartments(props.initialCompartments)
        : [{ name: _(msg`Compartment 1`), weight: undefined }]
    }
  });

  const compartments = watch('compartments');

  const fa = useFieldArray({ control, name: 'compartments' });

  const addCompartment = () => {
    fa.append({ name: _(msg`Compartment ${fa.fields.length + 1}`) }, { shouldFocus: false });
  }

  const removeCompartment = () => {
    fa.remove(fa.fields.length - 1);
  }

  const allocatedWeight = sumWeights(compartments);

  const submit = (form: ConfigureLoadForm) => {
    const compartments = form.compartments.map(c => ({ weight: c.weight }));
    props.compartmentsConfigured(compartments);
  }

  const doCompartmentsHaveInvalidWeights = (compartments: Compartment[], allowExceedOrderAmount: boolean) => {
    if (!allowExceedOrderAmount && allocatedWeight > props.requested) {
      return true;
    }
    return compartments.some(c => c.weight === undefined || isNaN(c.weight));
  }

  const NumberRowWithLabel = (props: { label: string, number: number, invalid: boolean }) => <>
    <div className="row">
      <label className="h5 text-end px-0">
        {props.label}
      </label>
    </div>
    <div className="row mb-5 text-end">
      <label className={`h5 ${props.invalid ? "text-danger" : ""}`}>
        {_(msg`${props.number} lb`)}
      </label>
    </div>
  </>

  return <Container
    title={_(msg`Configure Load`)}
    subtitle={_(msg`Add Compartments`)}
    progress={props.progress}>

    {isAnonymous && <SwitchToMobileQRCode />}
    <div className="my-auto">
      <div className="mx-auto mb-lg-6">
        <div className="col-auto">
          <label className="mt-1 d-flex justify-content-center text-black h2">
            <Trans>Number of compartments:</Trans>
          </label>
          <div className="mb-2 d-flex justify-content-center h-100">
            <button
              title={'removeCompartment'}
              className="btn btn-ghost-dark btn-icon rounded-circle me-3"
              onClick={removeCompartment}
              disabled={compartments.length <= 1}>
              <i className="bi-dash" style={{ fontSize: 45 }}></i>
            </button>
            <label className="text-black h2 mt-2">
              {fa.fields.length}
            </label>
            <button
              title={'addCompartment'}
              className="btn btn-ghost-dark btn-icon rounded-circle ms-3 "
              onClick={addCompartment}
              disabled={compartments.length >= maxCompartments}>
              <i className="bi-plus" style={{ fontSize: 45 }}></i>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-auto w-75">
        <KaAlert displayMessage={props.message} onClose={props.clearMessage} />
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-7 col-lg-4 overflow-auto pe-5">
            {fa.fields.map((field, index) => {
              const id = `compartment${index + 1}`;
              const error = errors?.compartments?.at(index);
              return <div key={field.id}>
                <label className="h5 mb-2" htmlFor={id}>
                  {field.name}
                </label>
                <div className="input-group mb-4">
                  <input
                    id={id}
                    type="number"
                    autoComplete={!isAnonymous ? "on" : "off"}
                    className={`form-control ${!!error && 'is-invalid'}`}
                    {...register(`compartments.${index}.weight` as const, {
                      onChange: () => watch(),
                      valueAsNumber: true,
                      required: _(msg`Compartment weight required`),
                      validate: {
                        positive: v => v > 0 || _(msg`Must be greater than 0`),
                      },
                    })}
                  />
                  <div className="input-group-append input-group-text fs-5 px-2 text-center">
                    <Trans>lb</Trans>
                  </div>
                  <span className="invalid-feedback">{error?.weight?.message}</span>
                </div>
              </div>
            })}
          </div>
          <div className="col-5 col-lg-4">
            <NumberRowWithLabel label={_(msg`Weight Remaining`)} number={props.requested - allocatedWeight} invalid={allocatedWeight > props.requested} />
            <NumberRowWithLabel label={_(msg`Weight Allocated`)} number={allocatedWeight} invalid={allocatedWeight > props.requested} />
          </div>
        </div>
      </div>
    </div>
    <Footer
      backButton={<FooterButton {...props.backButton} />}
      nextButton={<FooterButton
        onClick={handleSubmit(submit)}
        disabled={doCompartmentsHaveInvalidWeights(compartments, props.allowExceedOrderAmount)}
        {...props.nextButton} />}
    />
  </Container>
}

export default ConfigureLoad;
