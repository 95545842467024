import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export enum ReleaseOption {
  DoNotRelease = "DoNotRelease",
  AfterTime = "AfterTime",
}

export interface GeneralSettingsDto {
  unclaimedOption: ReleaseOption,
  unclaimedHours?: number,
  completedDays: number,
  officePhoneNumber?: string,
  shutOffAccess?: boolean,
  emailTickets?: boolean,
}

export default function useUpdateGeneralSettings(options?: RequestOptions) {
  const user = useUser();

  return useUserRequest<GeneralSettingsDto>({
    path: `/api/sites/${user.selectedSite!.id}/generalSettings`,
    method: HttpMethod.PUT
  }, options)
}
