import { HttpMethod, RequestOptions } from "../common/lib/fetch";
import useUserOrKioskRequest from "../common/lib/fetch/useUserOrKioskRequest";

export type AuthenticateCarrierDriver = {
  carrierNumber?: string;
  transportNumber?: string;
  driverNumber?: string;
  driverPassword?: string;
  remember: boolean;
}

const useAuthenticateForSite = (siteId: string, options: RequestOptions) =>
  useUserOrKioskRequest<AuthenticateCarrierDriver>({
    path: `/api/sites/${siteId}/authenticateDriverCarrier`,
    method: HttpMethod.POST
  }, options);

export default useAuthenticateForSite;
