import { i18n } from "@lingui/core";
import { detect, fromNavigator } from "@lingui/detect-locale";

export const locales: { [key: string]: string } = {
  en: "English",
  es: "Español",
};

const fallbackLocale = "en";

const DEFAULT_FALLBACK = () => defaultLocale;

let browserLocale = detect(fromNavigator(), DEFAULT_FALLBACK)!;

if (browserLocale.includes("-"))
  browserLocale = browserLocale.substring(0, browserLocale.indexOf("-"));

export const defaultLocale = locales[browserLocale] ? browserLocale : fallbackLocale;

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(selectedLocale: string) {
  const locale = locales[selectedLocale] ? selectedLocale : defaultLocale;

  const { messages } = await import(`./locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}