import React from 'react';
import { useUser } from '../../userAccess/useUser';
import { AdminNavMenu } from './AdminNavMenu';
import { AdminNavSideMenu } from './AdminNavSideMenu';

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => <>
  <AdminNavMenu />
  <main id="content" role="main" className="navbar-sidebar-aside-sm">
    <AdminNavSideMenu />
    <div className="navbar-sidebar-aside-content content-space-t-3 content-space-b-2 px-lg-5 px-xl-10 height-100 d-flex flex-column">
      {children}
    </div>
  </main>
</>

const AdminPortalLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const user = useUser();

  if (user.adminSites.length <= 0)
    return <Layout><h1>Please login as a site administrator to access the admin portal</h1></Layout>;

  if (!user.selectedSite)
    return <Layout><h1>Please select a site</h1></Layout>;

  return <Layout>{children}</Layout>;
}

export { AdminPortalLayout };
