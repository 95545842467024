import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Container from "../../common/Container";
import FullscreenLoadingSpinner from "../../common/FullscreenLoadingSpinner";
import QueryView from "../../common/lib/QueryView";
import ScanButton from "../../dev/ScanButton";
import { useGetBayDirectionsValidationSettings } from "../../requests/useGetBayDirectionsValidationSettings";
import DataMatrixDisplay from "./DataMatrixDisplay";

interface BayDirectionsProps {
  stagedOrderId: string,
  siteId: string,
  bayName?: string,
  orderNumber: string,
  devToolItems?: React.ReactNode,
  useDataMatrix: boolean,
}

const BayDirections = (props: BayDirectionsProps) => {
  const { _ } = useLingui();
  const getBayDirectionsValidationSettings = useGetBayDirectionsValidationSettings(props.siteId);

  const helperText = props.useDataMatrix ? _(msg`Scan at outbound terminal after loadout`) : "";

  return <Container
    title={props.bayName ? _(msg`Proceed to ${props.bayName}`) : helperText}
    subtitle={props.bayName ? helperText : undefined}
    devToolItems={
      <>
        {props.useDataMatrix
          ? <ScanButton location='AtOutbound' siteId={props.siteId} stagedOrderId={props.stagedOrderId} />
            : <ScanButton location='DispensingComplete' stagedOrderId={props.stagedOrderId} />
        }
        {getBayDirectionsValidationSettings.data?.showBarcodeForAutomatedLoadout &&
          <ScanButton location='ArrivedAtBay' siteId={props.siteId} stagedOrderId={props.stagedOrderId} />
        }
        {props.devToolItems}
      </>
    }>
    <QueryView
      query={getBayDirectionsValidationSettings}
      renderLoading={() => <FullscreenLoadingSpinner/>}
      renderData={settings =>
        <div className="mt-5 px-5 h-75">
          {settings.showBarcodeForAutomatedLoadout || props.useDataMatrix
            ? <DataMatrixDisplay stagedOrderId={props.stagedOrderId} orderNumber={props.orderNumber} />
            : <div className="position-relative">
              <div className="text-center mt-10">
                <h2><Trans>You will be prompted for signature after loadout is completed.</Trans></h2>
              </div>
            </div>}
        </div>
    }/>
  </Container>
}

export default BayDirections;
