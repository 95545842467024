import useCancelStagedOrder from "../requests/useCancelStagedOrder";

interface CancelStagedOrderButtonProps {
  onCancelled: () => void;
}

const CancelStagedOrderButton = (props: CancelStagedOrderButtonProps) => {
  const cancelRequest = useCancelStagedOrder({
    onSuccess: () => props.onCancelled()
  });

  return <button className="dropdown-item" onClick={() => cancelRequest.request()}>
    Cancel Load
  </button>
}

export default CancelStagedOrderButton;