import { useUserOrKioskRequest } from "../common/lib/fetch";
import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";

export interface AcceptTareWeightResp {
  bayName?: string
}

const useAcceptTareWeight = (stagedOrderId: string, options: RequestOptions<AcceptTareWeightResp>) =>
  useUserOrKioskRequest<void, AcceptTareWeightResp>({
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/tareWeight`
  }, options);

export default useAcceptTareWeight;
