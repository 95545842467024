import { Path, useForm } from "react-hook-form";
import { FormText } from "../common/forms/FormText";
import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import KaAlert from "../common/lib/KaAlert";
import Container, { Progress } from "../common/Container";
import Footer from "../common/Footer";
import FooterButton from "../common/FooterButton";
import useGetOutboundStagedOrder, { OutboundStagedOrder } from "../requests/useGetOutboundStagedOrder";
import useIsAnonymous from "../common/lib/hooks/useIsAnonymous";
import { driverFacingErrorMessage } from "../common/userFacingMessages/userFacingMessages";
import { useLingui } from "@lingui/react";

interface StagedOrderNumberProps {
  siteId: string;
  kioskId: string;
  progress?: Progress;
  initialOrderNumber?: string;
  stagedOrderReceived: (stagedOrder: OutboundStagedOrder) => void
}

interface Form {
  number: string;
}

const StagedOrderNumber = (props: StagedOrderNumberProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, setValue, formState, watch } = useForm<Form>({
    defaultValues: { number: props.initialOrderNumber ?? "" }
  });

  const orderNumber = watch("number");

  const formProps = (name: Path<Form>) => ({
    name,
    setValue,
    register,
    errors: formState.errors
  });

  const getOrder = useGetOutboundStagedOrder(props.siteId, orderNumber, props.kioskId, {
    onSuccess: props.stagedOrderReceived,
    onError: (err) => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  return <Container
    progress={props.progress}
    title="Load Information"
    subtitle="What's your order number?">
    <div className="w-75 m-auto">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <FormText {...formProps("number")}
        displayName="Enter Order Number"
        placeholder="Enter order number"
        autoComplete={!isAnonymous}
        options={{
          required: 'Order Number is required',
          validate: {
            length: v => v.length < 51 || 'Order Number cannot be longer than 50 characters'
          },
        }}
      />
    </div>
    <Footer nextButton={
      <FooterButton
        onClick={handleSubmit(() => getOrder.request())}
        spinning={getOrder.isLoading}
        disabled={!orderNumber}>
        Next
      </FooterButton>
    } />
  </Container>
}

export default StagedOrderNumber;
