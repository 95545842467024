import React, { useEffect, useReducer } from 'react'
import { UserContext, UserStatus, User, UserState, AdminSite } from './UserContext';
import { MsalConfiguration, MsalUser, useMsalAuth }
  from '../common/lib/hooks/msal/useMsalAuth';

enum Step {
  NotAuthenticated,
  GettingUserProfile,
  UserDone,
}

enum ActionType {
  GetUserProfile,
  UserProfileAcquired,
  SiteSelected
}

type State =
  | { step: Step.NotAuthenticated }
  | { step: Step.GettingUserProfile, msalUser: MsalUser }
  | { step: Step.UserDone, msalUser: MsalUser, user: User }

type Action =
  | { type: ActionType.GetUserProfile, msalUser: MsalUser }
  | { type: ActionType.UserProfileAcquired, userProfile: User }
  | { type: ActionType.SiteSelected, selectedSite: AdminSite | null }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.GetUserProfile:
      return { step: Step.GettingUserProfile, msalUser: action.msalUser }

    case ActionType.UserProfileAcquired:
      if (state.step === Step.GettingUserProfile) {
        return { step: Step.UserDone, msalUser: state.msalUser, user: action.userProfile }
      }
      break;

    case ActionType.SiteSelected:
      if (state.step === Step.UserDone) {
        const user = { ...state.user, selectedSite: action.selectedSite };
        return { step: Step.UserDone, msalUser: state.msalUser, user: user };
      }
      break;
  }

  return state;
}

type Props = {
  config: MsalConfiguration;
  children: React.ReactNode;
}

export const UserProvider: React.FC<Props> = ({ children, config }) => {
  const msalAccount = useMsalAuth(config);
  const [state, dispatch] = useReducer(reducer, { step: Step.NotAuthenticated });

  const fetchHeaders = async (user: MsalUser) =>
    new Headers({ 'Authorization': `Bearer ${await user.getToken()}` });

  if (state.step === Step.NotAuthenticated && msalAccount.user) {
    dispatch({ type: ActionType.GetUserProfile, msalUser: msalAccount.user });
  }

  useEffect(() => {
    if (state.step !== Step.GettingUserProfile) return;
    fetchHeaders(state.msalUser)
      .then(headers => fetch('/api/users/me', { headers, method: 'POST' }))
      .then(response => response.json())
      .then(userInfo =>
        dispatch({
          type: ActionType.UserProfileAcquired,
          userProfile: {
            state: UserState.SignedIn,
            id: userInfo.id,
            email: userInfo.email,
            adminSites: userInfo.adminSites,
            selectedSite: userInfo.adminSites.length > 0 ? userInfo.adminSites[0] : null,
            setSelectedSite: setSelectedSite,
            fetchHeaders: () => fetchHeaders(state.msalUser),
            signOut: (postLogoutUrl) => state.msalUser.signOut({ postLogoutUrl }),
          }
        })
      );
  }, [state]);

  function toUserStatus(state: State): UserStatus {
    if (msalAccount.busy) return { state: UserState.Loading };

    switch (state.step) {
      case Step.UserDone:
        return state.user;

      case Step.GettingUserProfile:
        return { state: UserState.Loading };

      case Step.NotAuthenticated:
        return {
          state: UserState.SignedOut,
          signIn: msalAccount.signIn
        }
    }
  }

  function setSelectedSite(site: AdminSite | null) {
    dispatch({
      type: ActionType.SiteSelected,
      selectedSite: site
    });
  }

  return (
    <UserContext.Provider value={toUserStatus(state)}>
      {children}
    </UserContext.Provider>
  );
};
