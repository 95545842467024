import { useState } from "react";
import Confirm from "../../common/Confirm";
import { Query } from "../../common/lib/fetch/useQuery";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import { StagedOrderDetails, useGetStagedOrders } from "../../requests/useGetStagedOrders";
import { useRemoveStagedOrder } from "../../requests/useRemoveStagedOrder";

const StagedOrders = () => {
  const displayMessage = useDisplayMessage();
  const useGetStagedOrdersQuery = useGetStagedOrders();

  return <QueryView
      query={useGetStagedOrdersQuery}
      renderData={response => <>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <StagedOrdersForm 
          displayMessageSuccess={(m) => displayMessage.success(m)}  
          displayMessageFail={(m) => displayMessage.fail(m)}  
          getStagedOrders={useGetStagedOrdersQuery} 
          stagedOrders={response} />
      </>
    }/>
}

interface StagedOrdersFormProps {
  displayMessageSuccess: (message: string) => void;
  displayMessageFail: (message: string) => void;
  getStagedOrders: Query<StagedOrderDetails[]>;
  stagedOrders: StagedOrderDetails[];
}

const StagedOrdersForm = (props: StagedOrdersFormProps) => {
  const [stagedOrderToRemove, setStagedOrderToRemove] = useState<StagedOrderDetails | undefined>(undefined);

  const removeStagedOrder = useRemoveStagedOrder(stagedOrderToRemove?.id ?? "", {
    onSuccess: () => {
      props.displayMessageSuccess(`Staged order for order number ${stagedOrderToRemove?.orderNumber} successfully removed`);
      setStagedOrderToRemove(undefined);
      props.getStagedOrders.query();
    },
    onError: (err) => {
      setStagedOrderToRemove(undefined);
      props.displayMessageFail(adminFacingErrorMessage(err))
    }
  });

  const formatStagedOrderStep = (step: string) => step.replace(/([A-Z])/g, ' $1').trim();

  const alphabeticallyByName = (a: StagedOrderDetails, b: StagedOrderDetails) => a.orderNumber.localeCompare(b.orderNumber);

  return <>
    {stagedOrderToRemove && <Confirm
      visible={!!stagedOrderToRemove}
      title={"Remove Staged Order"}
      body={`Are you sure you want to remove Staged Order for Order ${stagedOrderToRemove.orderNumber}?`}
      onConfirm={() => removeStagedOrder.request({})}
      onDismiss={() => setStagedOrderToRemove(undefined)}/>}
    <h2>Staged Orders</h2>
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Order Number</th>
          <th scope="col">Driver Email</th>
          <th scope="col">Order Status</th>
        </tr>
      </thead>
      <tbody>
        {props.stagedOrders.sort(alphabeticallyByName).map((so) =>
          <tr key={so.id}>
            <td>{so.orderNumber}</td>
            <td>{so.userEmail}</td>
            <td className="d-flex justify-content-between">
              {formatStagedOrderStep(so.step)}
              {(so.step !== "Signature" && so.step !== "TicketInstructions") && <button
                type="button"
                className="btn btn-danger w-50"
                onClick={() => setStagedOrderToRemove(so)}>
                Remove
              </button>}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </>
}

export { StagedOrders };
