import { Trans } from "@lingui/macro";
import SpinnerButton from "./SpinnerButton";

interface ToastProps { 
  headerText: string,
  bodyText: string,
  okClicked: () => void,
  loading: boolean,
}

export const Toast = (props: ToastProps) => 
  <div className="toast-container position-fixed top-50 start-50 translate-middle p-3" style={{ zIndex: 11 }}>
    <div className="toast toast-show fade show" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <h5 className="mb-0">{props.headerText}</h5>
      </div>
      <div className="toast-body">
        {props.bodyText}
        <div className="mt-2 pt-2 border-top d-flex flex-row-reverse">
          <SpinnerButton onClick={() => props.okClicked()} spinning={props.loading}><Trans>OK</Trans></SpinnerButton>
        </div>
      </div>
    </div>  
  </div>
