import { useEffect } from "react";
import bwipjs from "bwip-js";

interface DataMatrixProps {
  content: string,
}

const DataMatrix = (props: DataMatrixProps) => {
  const canvasId = 'dataMatrixCanvas';

  useEffect(() => {
    //@ts-ignore
    bwipjs.toCanvas(canvasId, {
      bcid: "datamatrix", 
      text: props.content, 
      scale: 6.5,
      backgroundcolor: "#FFFFFF",
      padding: 2,
    });
  }, [props.content]);

  return <canvas id={canvasId} />
}

export default DataMatrix;
