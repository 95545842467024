import { Header } from "../common/Header";
import Row from "./Row";

export interface Progress {
  currentStep: number;
  totalSteps: number;
}

type ContainerProps = {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
  progress?: Progress;
  devToolItems?: React.ReactNode;
  actionItems?: React.ReactNode;
}

type TextHeaderProps = Pick<ContainerProps, 'title' | 'subtitle' | 'devToolItems'>;

export const TextHeader = (props: TextHeaderProps) => {
  return <Header {...props}>
    <Row className="p-0 g-0 mt-2 mb-2">
      <div className="col-1" />
        {titleHeader(props.title)}
      <div className="col-1" />
    </Row>
    <Row className="p-0 g-0 mb-1">
      {
        props.subtitle &&
        <>
          <div className="col-1" />
          <h4 className="col-10 text-white text-center p-0">
            {props.subtitle}
          </h4>
          <div className="col-1" />
        </>
      }
    </Row>
  </Header>
}

const titleHeader = (title: String) => {
  const displaySize = title.length < 18 ? "display-5" : "display-6";

  return <h2 className={"col-10 text-white text-center mb-0 p-1 " + displaySize}>
    {title}
  </h2>
};

export interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const currentProgressPercent = calcProgressBarPercent(props.currentStep, props.totalSteps);

  return <div className="progress m-2" style={{ minHeight: 8 }}> 
    <div className="progress-bar" role="progressbar" style={{ width: currentProgressPercent }} />
  </div>
}

const calcProgressBarPercent = (stepNumber: number, totalSteps: number) => {
  return ((stepNumber / totalSteps) * 100).toFixed(2) + "%"
}

const Container = (props: ContainerProps) => {
  return <div className="height-100">
    <div className="height-15">
      {
        <TextHeader {...props} />
      }
    </div>
    
    <div className="d-flex flex-column height-85">
      { (props.progress) &&
        <ProgressBar {...props.progress} />
      }
      {props.children}
    </div>
  </div>
}

export default Container;