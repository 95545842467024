import { useCallback, useEffect } from 'react';
import useFetch, { HttpMethod, Error } from './useFetch';

export interface QuerySpec {
  path: string;
  headers: () => Promise<Headers>,
}

export interface Query<Resp> {
  isIdle: boolean,
  isLoading: boolean,
  isError: boolean,
  isSuccess: boolean,
  query: () => void,
  data: Resp | null,
  error: Error | null,
}

export interface QueryOptions<Resp> {
  autoQuery?: boolean,
  onSuccess?: (resp: Resp) => void,
  onError?: (error: Error) => void,
  onComplete?: () => void,
}

export default function useQuery<Resp>(
  spec: QuerySpec, options?: QueryOptions<Resp>): Query<Resp> {
  const fetchSpec = {
    path: spec.path,
    headers: spec.headers,
    method: HttpMethod.GET
  };
  const autoQuery = options?.autoQuery ?? true;
  const fetch = useFetch<Resp>(fetchSpec, { ...options });
  const query = useCallback(() => {
    fetch.fetch();
  }, [fetch.fetch]); // eslint-disable-line

  useEffect(() => {
    if (autoQuery) {
      query();
    }
  }, [query, autoQuery, fetchSpec.path]);

  const loadingOnStart = autoQuery && !fetch.isSuccess && !fetch.isError;

  return {
    isIdle: fetch.isIdle && !loadingOnStart,
    isLoading: fetch.isLoading || loadingOnStart,
    isError: fetch.isError,
    isSuccess: fetch.isSuccess,
    data: fetch.data,
    error: fetch.error,
    query: query,
  }
}

