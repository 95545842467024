import React from 'react';
import { FormErrorProps, useFormError } from '../lib/hooks/useFormError';
import { FormCommonProps } from './FormCommon';

interface FormCheckboxProps<FormType> extends FormCommonProps<FormType> {
  label: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export function FormCheckbox(props: FormCheckboxProps<any>) {
  const { inError, errorMessage } = useFormError(props as FormErrorProps<any>);
  const controlProps = props.register(props.name, { ...props.options });

  return <div className="form-check mb-3">
    <input
      type="checkbox"
      id={props.name}
      className={`form-check-input ${inError ? 'is-invalid' : ''}`}
      ref={controlProps.ref}
      name={controlProps.name}
      onChange={controlProps.onChange}
      disabled={!(props.enabled ?? true)}
    />
    <label className="form-check-label" htmlFor={props.name}>{props.label}</label>
    {inError && <span className="invalid-feedback">
      {errorMessage}
    </span>}
  </div>
}
