import { useRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface AddSite {
  city: string;
  state: string;
  number: string;
  business: string;
  serviceContractNumber: string;
}

export const useAddSite = (options?: RequestOptions) => {
  const kaEmployee = useKaEmployee();

  return useRequest<AddSite>({
    headers: kaEmployee.fetchHeaders,
    method: HttpMethod.POST,
    path: '/api/sites'
  }, options);
}
