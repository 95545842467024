import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export enum QuestionType {
  MultipleChoice = 'MultipleChoice',
  YesNo = 'YesNo',
  UserDefined = 'UserDefined',
}

export type Answer = {
  id: string;
  text: string;
  correct: boolean;
}

export type LoadQuestion = {
  id: string;
  type: QuestionType;
  question: string;
  answers: Answer[];
  allowPlainTextAnswer: boolean;
  minNumOfAnswers: number,
  maxNumOfAnswers: number,
  allProducts: boolean;
  productIds: string[];
  incorrectAnswerMessage?: string;
}

const useUpdateLoadQuestions = (options?: RequestOptions) => {
  const user = useUser();

  return useUserRequest<LoadQuestion[]>({
    path: `/api/sites/${user.selectedSite!.id}/loadQuestionsSettings`,
    method: HttpMethod.PUT
  }, options);
}


export default useUpdateLoadQuestions;
