import { useEffect, useRef } from "react";

const useInterval = (callback: (isCancelled: () => boolean) => void, interval: number, immediate: boolean) => {
  const ref = useRef<(isCancelled: () => boolean) => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    let cancelled = false;

    const fn = () => {
      if (ref.current) {
        ref.current(() => cancelled);
      }
    };

    const id = setInterval(fn, interval);
    if (immediate) fn();

    return () => {
      cancelled = true;
      clearInterval(id);
    };
  }, [interval, immediate]);
};

export default useInterval;
