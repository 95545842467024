import { FieldError, FieldErrors, Path } from 'react-hook-form';

export interface FormErrorProps<FormType> {
  name: Path<FormType>,
  errors?: FieldErrors<FormType>,
}

interface FormErrorReturn {
  inError: boolean,
  errorMessage: string,
}

export function useFormError<FormType>(props: FormErrorProps<FormType>): FormErrorReturn {
  if (props.errors) {
    const error = (props.errors as any)[props.name] as FieldError;
    if (error) {
      return { inError: true, errorMessage: error.message ?? '' }
    }
  }

  return { inError: false, errorMessage: '' };
}
