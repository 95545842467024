import { useState } from "react";
import SelectSearch, { SelectOption } from "../../common/forms/SelectSearch";
import Container, { Progress } from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import { Destination, Order, SODestination } from "../../UserFlow";
import { FooterProps } from "./StageOrderTypes";
import SwitchToMobileQRCode from "../../common/SwitchToMobileQRCode";
import useIsAnonymous from "../../common/lib/hooks/useIsAnonymous";
import { useLingui } from "@lingui/react";
import { msg, Plural, Trans } from "@lingui/macro";

export interface SelectDestinationProps extends FooterProps {
  allowDestinationChange: boolean;
  allowEnteredShipTo: boolean;
  requireDestination: boolean;
  destinations: Array<Destination>;
  order: Order,
  initialDestination?: SODestination;
  destinationSelected: (d?: SODestination) => void;
  progress?: Progress;
}


const SelectDestination = (props: SelectDestinationProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const [destination, setDestination] = useState(props.initialDestination);
  const OtherOption: SelectOption = { value: 'f02e4784-8ab1-4b44-b7c4-9b91bb2e5637', name: _(msg`Other...`) };

  const initialDropdownValue = (initialDestination?: SODestination) => {
    if (!initialDestination) return "";

    if ("id" in initialDestination) {
      return initialDestination.id;
    }

    return OtherOption.value;
  }

  const [selectValue, setSelectValue] = useState(initialDropdownValue(props.initialDestination));

  const destinations = props.destinations.concat(props.order.customer.destinations ?? []);

  const destOptions = destinations.map<SelectOption>(d => {
    const otherDestinationWithSameName = destinations.some(dto => dto.name === d.name && dto.id !== d.id);

    var destOptionName = `${d.name}`;

    if (otherDestinationWithSameName) {
      if (!!d.city && !!d.state) {
        destOptionName += ` (${d.city}, ${d.state})`;
      } else if (!!d.city) {
        destOptionName += ` (${d.city})`;
      } else if (!!d.state) {
        destOptionName += ` (${d.state})`;
      }
    }
    
    return { value: d.id, name: destOptionName };
  });
  if (props.allowEnteredShipTo) { destOptions.push(OtherOption); }

  const destinationSelected = (value: string) => {
    if (value === OtherOption.value) {
      setDestination({ name: "" });
    } else {
      setDestination(destinations.find(d => d.id === value));
    }

    setSelectValue(value);
  }

  const dropdownAvailable = props.allowDestinationChange && destOptions.length > 0;

  const formWidth = isAnonymous ? "w-65" : "w-75";

  return <Container
    title={_(msg`Load Information`)}
    subtitle={_(msg`Where are you taking the load?`)}
    progress={props.progress} >

    {isAnonymous && <SwitchToMobileQRCode />}
    <div className={formWidth + " m-auto"}>
      <label className="h2" htmlFor="orderNumber"><Trans>Enter Order Number</Trans></label>
      <input
        type="text"
        id="orderNumber"
        className="form-control form-control-lg"
        value={props.order.number}
        disabled />
      <label className="h5 mt-4 mb-0">
        <Plural 
          value={props.order.products.length} 
          one="Product"
          other="Products"/>
      </label>
      <p className="m-0 line-clamp-2">{props.order.products.join(", ")}</p>
      <label className="h5 mt-4 mb-0"><Trans>Customer</Trans></label>
      <p className="m-0 line-clamp-2">{props.order.customer.name}</p>
      <label className="h5 mt-4" htmlFor="destination"><Trans>Destination</Trans></label>
      <SelectSearch
        id="destination"
        disabled={!dropdownAvailable}
        options={destOptions}
        hideSearch={destOptions.length < 5}
        selectedValue={selectValue}
        placeholder={dropdownAvailable ? _(msg`Select a destination...`) : _(msg`No destinations available`)}
        onChange={destinationSelected} />
      {
        selectValue === OtherOption.value &&
        <input
          className="form-control mt-4"
          type="text"
          placeholder={_(msg`Enter destination name...`)}
          onChange={ev => setDestination({ name: ev.target.value })}
          value={destination?.name} 
          autoComplete={!isAnonymous ? "on" : "off"}/>
      }
    </div>
    <Footer
      backButton={<FooterButton {...props.backButton} />}
      nextButton={
        <FooterButton
          disabled={(selectValue === OtherOption.value && !destination?.name) || (props.requireDestination && !destination)}
          onClick={() => props.destinationSelected(destination)}>
          <Trans>Next</Trans>
        </FooterButton>
      }
    />
  </Container>
}

export default SelectDestination;
