import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Button from "../common/Button";
import Container from "../common/Container";
import Footer from "../common/Footer";
import FooterButton from "../common/FooterButton";
import InstallPwaModal from "../common/InstallPwaModal";
import useLocalStorage from "../common/lib/hooks/useLocalStorage";
import SlideOutButton from "../common/SlideOutButton";

interface SafetyMessageProps {
  signedIn: boolean;
  ackSafetyMessage: () => void;
}

const SafetyMessage = (props: SafetyMessageProps) => {
  const { _ } = useLingui();
  const [hasSeenInstallPwaModal, setHasSeenInstallPwaModal] = useLocalStorage("hasSeenInstallPwaModal", false);

  const installPwaModalDevButton = () => {
    return <SlideOutButton
      text="Reset Pwa Install Modal"
      onClick={() => setHasSeenInstallPwaModal(false)} />
  };

  return <Container title={_(msg`Be Safe Out There!`)}
    devToolItems={installPwaModalDevButton()}>
    <InstallPwaModal />
    <div className="m-auto d-block text-center">
      <h2 className="mb-3 mx-4">
        <Trans>Please do not use this service while driving. Observe all laws and safety measures.</Trans>
      </h2>
      {!props.signedIn && <Button className='px-5 py-3 align-center' onClick={props.ackSafetyMessage}>
        <Trans>Sign In</Trans>
      </Button>}
    </div>
    {props.signedIn && <Footer nextButton={
      <FooterButton onClick={props.ackSafetyMessage}>
        <Trans>Continue</Trans>
      </FooterButton>
    } />}
  </Container>
}

export default SafetyMessage;
