import { useUserOrKioskQuery } from "../common/lib/fetch";
import { QueryOptions } from "../common/lib/fetch/useQuery";

export interface PastOrder {
  orderId: string;
  stagedOrderId: string;
  orderNumber: string;
  completedAt: string;
}

export const useGetPastOrderByStagedOrderId = (stagedOrderId: string, options: QueryOptions<PastOrder>) =>
  useUserOrKioskQuery<PastOrder>({
    path: `/api/pastOrders/${stagedOrderId}`
  }, options);