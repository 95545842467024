import { useLocation } from "react-router";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import { redirect } from "../../common/redirect";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useRegisterSite, { RegisterResponse } from "../../requests/useRegisterSite";
import { useUser } from "../../userAccess/useUser";

const SiteRegistration = () => {
  const user = useUser();
  const displayMessage = useDisplayMessage();
  const search = new URLSearchParams(useLocation().search);
  const redirectUri = search.get('redirect_uri');

  const siteRegistered = (registered: RegisterResponse) => {
    let uri = redirectUri;
    uri = uri + `?client_secret=${encodeURIComponent(registered.clientSecret)}`;
    uri = uri + `&site_id=${registered.siteId}`;
    redirect(uri);
  }

  const registerSite = useRegisterSite(user.selectedSite!.id, {
    onSuccess: siteRegistered,
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err)),
  });

  return redirectUri
    ? <>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="m-auto w-50">
        <h2>Site Registration for {user.selectedSite?.city}, {user.selectedSite?.state}</h2>
        <SpinnerButton
          className="mb-4 px-5 py-3 col-xl-4 col-lg-6 col-md-12"
          spinning={registerSite.isLoading}
          onClick={() => registerSite.request()}
        >
          Register
        </SpinnerButton>
      </div>
    </>
    : <div className={`alert alert-soft-danger show`} role="alert">
      An unexpected error occurred. Please contact Kahler Automation
    </div>
}

export { SiteRegistration };
