import { FormCommonDisplayProps } from './FormCommon'

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function toDisplayFromCamelCase(string: string) {
  return capitalizeFirstLetter(string.replace(/([A-Z])/g, ' $1'))
}

export function FormLabel(props: FormCommonDisplayProps<any>) {
  return <label className="form-label" htmlFor={props.name}>
    <h2 className="mb-2">{props.displayName ?? toDisplayFromCamelCase(props.name)}</h2>
  </label>
}
