import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import QueryView from "../../../common/lib/QueryView";
import Row from "../../../common/Row";
import SpinnerButton from "../../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../../requests/useGetSiteSettings";
import useUpdateBusinessHours, { BusinessHours, BusinessTimeZone } from "../../../requests/useUpdateBusinessHours";
import BusinessHoursDayEntry from "./BusinessHoursEntry";

type BusinessHoursSettings = {
  businessHours: BusinessHours;
}

type EditProps = { initialBusinessHours: BusinessHours };

const Edit = ({ initialBusinessHours }: EditProps) => {
  const dayJsTimeFormat = "HH:mm:ss";
  const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  const displayMessage = useDisplayMessage();

  const [isEnabled, setIsEnabled] = useState(!!initialBusinessHours);
  const [canSave, setCanSave] = useState(false);

  interface AreDaysValid {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
  }

  const [areDaysValid, setAreDaysValid] = useState<AreDaysValid>({
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true
  });

  if (!initialBusinessHours) {
    initialBusinessHours = {
      mondayOpenTime: "08:00:00", mondayCloseTime: "17:00:00",
      tuesdayOpenTime: "08:00:00", tuesdayCloseTime: "17:00:00",
      wednesdayOpenTime: "08:00:00", wednesdayCloseTime: "17:00:00",
      thursdayOpenTime: "08:00:00", thursdayCloseTime: "17:00:00",
      fridayOpenTime: "08:00:00", fridayCloseTime: "17:00:00",
      timeZone: BusinessTimeZone.Central,
      shutOffAccessWhenClosed: false
    }
  }

  const [businessHours, setBusinessHours] = useState<BusinessHours>(initialBusinessHours);

  const updateBusinessHoursSettings = useUpdateBusinessHours({
    onSuccess: () => displayMessage.success("Business Hours updated"),
    onError: err => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const save = () => {
    const businessHoursToUpdate = isEnabled ? businessHours : {};
    updateBusinessHoursSettings.request(businessHoursToUpdate);
  }

  const changeTimeZone = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setBusinessHours({ ...businessHours, timeZone: BusinessTimeZone[ev.target.value as keyof typeof BusinessTimeZone] });
  }

  const updateAndValidateBusinessDay = (day: string, openTime: Dayjs | null, closeTime: Dayjs | null) => {
    validateBusinessDay(day, openTime, closeTime);

    const openTimeString = openTime ? openTime.format(dayJsTimeFormat) : null;
    const closeTimeString = closeTime ? closeTime.format(dayJsTimeFormat) : null;

    setBusinessHours({ ...businessHours,
      [`${day}OpenTime`]: openTimeString, [`${day}CloseTime`]: closeTimeString
    });
  }

  const validateBusinessDay = (day: string, openTime: Dayjs | null, closeTime: Dayjs | null) => {
    var isTimeValid = true;

    if (openTime == null && closeTime == null) {
      isTimeValid = true;
    } else if (!openTime || !openTime.isValid()) {
      isTimeValid = false;
    } else if (!closeTime || !closeTime.isValid()) {
      isTimeValid = false;
    } else if (openTime.isAfter(closeTime)
      || openTime.isSame(closeTime)
      || closeTime.isBefore(openTime)) {

      isTimeValid = false;
    }

    setAreDaysValid({ ...areDaysValid, [day]: isTimeValid });
  }

  const getOpenTime = (day: string) => {
    let openTime;
    switch (day) {
      case "sunday":
        openTime = businessHours.sundayOpenTime;
        break;
      case "monday":
        openTime = businessHours.mondayOpenTime;
        break;
      case "tuesday":
        openTime = businessHours.tuesdayOpenTime;
        break;
      case "wednesday":
        openTime = businessHours.wednesdayOpenTime;
        break;
      case "thursday":
        openTime = businessHours.thursdayOpenTime;
        break;
      case "friday":
        openTime = businessHours.fridayOpenTime;
        break;
      case "saturday":
        openTime = businessHours.saturdayOpenTime;
        break;
    }
    return openTime ? dayjs(openTime, dayJsTimeFormat) : null;
  }

  const getCloseTime = (day: string) => {
    let closeTime;
    switch (day) {
      case "sunday":
        closeTime = businessHours.sundayCloseTime;
        break;
      case "monday":
        closeTime = businessHours.mondayCloseTime;
        break;
      case "tuesday":
        closeTime = businessHours.tuesdayCloseTime;
        break;
      case "wednesday":
        closeTime = businessHours.wednesdayCloseTime;
        break;
      case "thursday":
        closeTime = businessHours.thursdayCloseTime;
        break;
      case "friday":
        closeTime = businessHours.fridayCloseTime;
        break;
      case "saturday":
        closeTime = businessHours.saturdayCloseTime;
        break;
    }
    return closeTime ? dayjs(closeTime, dayJsTimeFormat) : null;
  }

  const allDaysValidForSave = () => {
    const isValidValues = Object.values(areDaysValid);
    const isEveryDayValid = isValidValues.every(value => value === true);
    setCanSave(isEveryDayValid);
  }

  useEffect(() => {
    allDaysValidForSave();
  }, [areDaysValid]);

  return <>
    <div className="m-auto w-65">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <Row>
        <h2>Business Hours</h2>
        <p>
          Drivers can view business hours within the LOADPASS application on the 'Where are
          you loading?' screen. These hours can be adjusted before holidays to communicate any
          changes to the current operating schedule.
        </p>
      </Row>
      <Row>
        <div className="col-7 col-form-label-lg">
          <div>
            <input
              id={`enableBusinessHours`}
              type="checkbox"
              className="form-check-input me-2"
              checked={isEnabled}
              onChange={(ev) => setIsEnabled(ev.target.checked)}
            />
            <label
              className="form-check-label-lg"
              htmlFor={`enableBusinessHours`}>
              Enable business hours
            </label>
          </div>
          <div style={isEnabled? {} : {pointerEvents: "none", opacity: "0.4" }}>
            <input
              id={`shutOffAccessWhenClosed`}
              type="checkbox"
              className="form-check-input me-2"
              checked={businessHours.shutOffAccessWhenClosed}
              onChange={(ev) => setBusinessHours({
                ...businessHours,
                shutOffAccessWhenClosed: ev.target.checked
              })}
            />
            <label
              className="form-check-label-lg"
              htmlFor={`shutOffAccessWhenClosed`}>
              Shut off access to the site when closed
            </label>
          </div>
        </div>
        <div className="col-5 d-flex flex-column">
          <Row className="mt-auto">
            <label className="col-6 col-form-label-lg text-end" htmlFor="timeZone" style={isEnabled ? {} : { pointerEvents: "none", opacity: "0.4" }}>
              Time Zone
            </label>
            <div className="col-6 bottom-0" style={isEnabled ? {} : { pointerEvents: "none", opacity: "0.4" }}>
              <select
                id="timeZone"
                className="form-select form-select-sm mt-1"
                onChange={changeTimeZone}
                value={businessHours.timeZone}
              >
                <option value={BusinessTimeZone.Eastern}>Eastern Time</option>
                <option value={BusinessTimeZone.Central}>Central Time</option>
                <option value={BusinessTimeZone.Mountain}>Mountain Time</option>
                <option value={BusinessTimeZone.Pacific}>Pacific Time</option>
              </select>
            </div>
          </Row>
        </div>
      </Row>
      <div className="border border-dark rounded p-4"
        data-testid="business-hours-container"
        style={isEnabled ? {} : { pointerEvents: "none", opacity: "0.4" }}>
        {
          daysOfWeek.map((day, i) =>
            <BusinessHoursDayEntry
              key={i}
              day={day}
              openTime={getOpenTime(day)}
              closeTime={getCloseTime(day)}
              updateAndValidateBusinessDay={updateAndValidateBusinessDay}
            />
          )
        }
      </div>
    </div>
    <div className="align-self-end mt-3">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateBusinessHoursSettings.isLoading}
        onClick={save}
        disabled={!canSave}>
        Save
      </SpinnerButton>
    </div>
  </>
}

const BusinessHoursPage = () => {
  const useSettings = useGetSiteSettings<BusinessHoursSettings>();
  return <QueryView
    query={useSettings}
    renderData={settings => {
      return <Edit initialBusinessHours={settings.businessHours} />
    }}
  />
};

export default BusinessHoursPage;