import React from "react";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import { Units } from "../../common/units/units";
import useWeight from "../../common/units/useWeight";
import Container from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import useAcceptTareWeight from "../../requests/useAcceptTareWeight";
import DisplayTareWeight from "./LoadTareWeight";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { driverFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";

interface TareWeightProps {
  siteId: string;
  scaleId: string;
  soId: string;
  requestedAmount: number;
  tareAccepted: (bayName?: string) => void;
  devToolItems?: React.ReactNode;
}

const TareWeight = (props: TareWeightProps) => {
  const { _ } = useLingui();
  const tareWeight = useWeight({ value: 0, unit: Units.lb });
  const displayMessage = useDisplayMessage();

  const acceptTare = useAcceptTareWeight(props.soId, {
    onSuccess: ({ bayName }) => props.tareAccepted(bayName),
    onError: err => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  return <Container
    title={_(msg`Verify Tare Weight`)}
    devToolItems={props.devToolItems}>
    <div className="m-auto w-75">
      <KaAlert
        displayMessage={displayMessage.message}
        onClose={displayMessage.clear} />
      <DisplayTareWeight
        siteId={props.siteId}
        scaleId={props.scaleId}
        newTare={tareWeight.setWeight}
        requestedAmount={props.requestedAmount}
        tareWeight={tareWeight}
        tareInLb={tareWeight.as(Units.lb)} />
    </div>
    <Footer nextButton={
      <FooterButton
        spinning={acceptTare.isLoading}
        onClick={() => acceptTare.request()}
        disabled={!tareWeight.value || tareWeight.value <= 0}>
        <Trans>Accept Weight</Trans>
      </FooterButton>
    } />
  </Container>
}

export default TareWeight;
