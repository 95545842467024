import { HttpMethod, RequestOptions, useUserRequest } from "../common/lib/fetch";

export interface RegisterKiosk {
  id: string,
}

export interface RegisterKioskResponse {
  id: string,
  clientSecret: string,
}

const useRegisterKiosk = (siteId: string, options: RequestOptions<RegisterKioskResponse>) =>
  useUserRequest<RegisterKiosk, RegisterKioskResponse>({
    path: `/api/sites/${siteId}/registerKiosk`,
    method: HttpMethod.POST
  }, options);

export default useRegisterKiosk;