import { useUserQuery, QueryOptions } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export default function useGetSiteSettings<Resp>(options?: QueryOptions<Resp>) {
  const user = useUser();

  return useUserQuery<Resp>({
    path: `/api/sites/${user.selectedSite!.id}/settings`,
  }, options);
}
