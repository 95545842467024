import { EventType, IPublicClientApplication } from "@azure/msal-browser";
import { useEffect } from "react";
import { MsalConfiguration } from "./useMsalAuth";

const useHandleForgotPassword = (pca: IPublicClientApplication, config: MsalConfiguration) => {
  useEffect(() => {
    const callbackId = pca.addEventCallback((event: any) => {
      if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
        pca.loginRedirect(config.forgotPasswordRequest);
      }
    });

    return () => {
      if (callbackId) {
        pca.removeEventCallback(callbackId);
      }
    };
  }, [pca, config]);
}

export default useHandleForgotPassword;