import { Trans } from "@lingui/macro";
import DataMatrix from "../../common/DataMatrix";
import Row from "../../common/Row"

type DataMatrixDisplayProps = {
  stagedOrderId: string,
  orderNumber: string,
  bayDirections?: string,
  ref?: React.RefObject<HTMLDivElement>,
}

const DataMatrixDisplay = (props: DataMatrixDisplayProps) => {
  const sizePx = 320;

  return <>
    {props.bayDirections &&
      <Row className="text-center mt-10">
        <h2>{props.bayDirections}</h2>
      </Row>
    }
    <Row className="mt-10" style={{ height: sizePx }}>
      <div className="position-relative">
        <div className="position-absolute top-0 start-50 translate-middle-x">
          <DataMatrix content={props.stagedOrderId} />
        </div>
      </div>
    </Row>
    <Row className="text-center mt-10">
      <h2><Trans>Order Number {props.orderNumber}</Trans></h2>
    </Row>
  </>
}

export default DataMatrixDisplay;