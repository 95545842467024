import { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { useIsOverflow } from "./lib/hooks/useIsOverflow";

const OverflowTooltip = ({ id, text, className }: { id: string, text: string, className?: string }) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflow = useIsOverflow(ref);

  return <>
    <p className={`line-clamp-2 ${className}`} ref={ref} id={id}>
      {text}
    </p>
    {
      isOverflow && <UncontrolledTooltip target={id} >
        {text}
      </UncontrolledTooltip>
    }
  </>
}

export default OverflowTooltip;
