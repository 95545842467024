import { useState } from "react";
import QueryView from "../common/lib/QueryView";
import InputPredict from "../common/InputPredict";
import useBusinesses, { Business, Site } from "../requests/useBusinesses";
import Container from "../common/Container";
import Footer from "../common/Footer";
import FooterButton from "../common/FooterButton";
import { getFullNameFromAlias } from "../common/utilities";
import SelectSearch, { SelectOption } from "../common/forms/SelectSearch";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { BusinessHours } from "../common/BusinessHours";

interface SelectSiteProps {
  siteSelected: (siteId: string) => void;
}

const compareNames = (first: string, second: string) =>
  first.toLowerCase() === second.toLowerCase() || first.toLowerCase() === getFullNameFromAlias(second).toLowerCase();


const SelectSite = (props: SelectSiteProps) => {
  const { _ } = useLingui();

  const [business, setBusiness] = useState<undefined | Business>(undefined);
  const [site, setSite] = useState<undefined | Site>(undefined);

  const [businessName, setBusinessName] = useState('');
  const [siteName, setSiteName] = useState('');

  const [siteError, setSiteError] = useState<undefined | string>();

  const [businessOptions, setBusinessOptions] = useState<string[]>([]);

  const selectionValid = business !== undefined && site !== undefined;

  const siteSelected = (name: string, match: boolean, businesses: Business[]) => {
    setSiteName(name);

    if (match) {
      const matchSite = businesses.flatMap(b => b.sites).find(s => compareNames(toSiteDisplay(s), name));
      setSite(matchSite)
      setSiteError(undefined);

      let possibleBusinesses = businesses.filter(b => b.sites.some(s => toSiteDisplay(s) === toSiteDisplay(matchSite!))).map(b => b.name);
      setBusinessOptions(possibleBusinesses);

      if (possibleBusinesses.length === 1)
        businessSelected(possibleBusinesses[0], businesses, name);

    } else {
      setSite(undefined);
      setBusiness(undefined);
      setBusinessName('');
      setBusinessOptions([]);
    }
  }

  const businessSelected = (name: string, businesses: Business[], siteMatch: string) => {
    setBusinessName(name);

    const matchBusiness = businesses.find(b => compareNames(b.name, name));
    const matchSite = matchBusiness!.sites.find(s => compareNames(toSiteDisplay(s), siteMatch));

    setBusiness(matchBusiness);
    setSite(matchSite);
  }

  const validateSite = () => {
    if (!site) {
      setBusiness(undefined);

      const error = siteName
        ? _(msg`Site named "${siteName}" does not exist`)
        : _(msg`Site is required`);

      setSiteError(error);
    }
  }

  const useBusinessesQuery = useBusinesses();

  const submit = () => {
    props.siteSelected(site!.id);
  }

  const toSiteDisplay = (site: Site) => `${site.city}, ${site.state}`;

  return <Container title={_(msg`Where are you loading from?`)}>
    <QueryView
      query={useBusinessesQuery}
      renderData={businesses => <>
        <div className="d-block m-auto w-75">
          <div className="position-relative mt-4">
            <label className="h1 mb-2" htmlFor="site"><Trans>City, State</Trans></label>
            <div className="mt-2">
              <InputPredict
                placeholder={_(msg`Enter a location...`)}
                onChange={(name, match) => siteSelected(name, match, businesses)}
                value={siteName}
                dictionary={businesses.flatMap(b => b.sites.map(toSiteDisplay)) ?? []}
                onBlur={validateSite}
                error={siteError} />
            </div>
            <label className="h1 mt-sm-8 mt-3" htmlFor="businessName"><Trans>Business Name</Trans></label>
            <div className="mt-2">
              <SelectSearch
                id="businessName"
                placeholder={_(msg`Enter a business...`)}
                onChange={(name) => businessSelected(name, businesses, siteName)}
                selectedValue={businessName}
                options={businessOptions.map<SelectOption>(b => ({ value: b, name: b }))}
                disabled={site === undefined}
                hideSearch={businessOptions.length < 4} />
            </div>
          </div>
          <div className={(site?.hours && business) ? 'visible' : 'invisible'}>
            <BusinessHours hours={site?.hours} timeZone={site?.timeZone} />
          </div>
        </div>
        <Footer nextButton={
          <FooterButton
            onClick={submit}
            disabled={!selectionValid}>
            <Trans>Next</Trans>
          </FooterButton>
        } />
      </>
      } />
  </Container>
}

export default SelectSite;
