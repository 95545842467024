import { useUserOrKioskQuery, QueryOptions } from "../common/lib/fetch";
import { Hours } from "./dtos/Hours";

export enum OpenStatus {
  Open = 'Open',
  Closed = 'Closed',
  OutboundOnlyShutOffAccess = 'OutboundOnlyShutOffAccess',
  ClosedShutOffAccess = 'ClosedShutOffAccess',
}

export type CarrierDriverInfo = {
  carrierNumber?: string;
  transportNumber?: string;
  driverNumber?: string;
  driverPassword?: string;
}

export type StagingSettings = {
  savedCarrierDriverInfo?: CarrierDriverInfo;
  requireAuthentication: boolean;
  requireCarrierNumber: boolean;
  requireTransportNumber: boolean;
  requireDriverNumber: boolean;
  requireDriverPassword: boolean;
  printBarcodesAtKiosk: boolean;
  showCustomerDestination: boolean;
  allowDestinationChange: boolean;
  allowEnteredShipTo: boolean;
  requireDestination: boolean;
  allowExceedOrderAmount: boolean;
  destinations: Array<Destination>;
  openStatus: OpenStatus
  timeZone?: string;
  hours?: Hours[];
}

export interface Destination {
  id: string;
  name: string;
}

export const useGetStagingSettings = (siteId: string, options?: QueryOptions<StagingSettings>) =>
  useUserOrKioskQuery<StagingSettings>({
    path: `/api/sites/${siteId}/stagingSettings`
  }, options);
