import { StagingSettings, useGetStagingSettings } from "../requests/useGetStagingSettings";
import QueryView from "./lib/QueryView";

type Props = {
  siteId: string;
  render: (settings: StagingSettings) => React.ReactElement;
}

export const StagingSettingsQuery: React.FC<Props> = (props) => {
  const useSiteQuery = useGetStagingSettings(props.siteId);

  return <QueryView
    query={useSiteQuery}
    renderData={settings => props.render(settings)} />
}

export default StagingSettingsQuery;
