import { SubmitHandler, useForm } from "react-hook-form";
import { HttpMethod } from "../../common/lib/fetch/useFetch";
import useUserQuery from "../../common/lib/fetch/useUserQuery";
import useUserRequest from "../../common/lib/fetch/useUserRequest";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useUpdateCarrierDriverSettings, { CarrierDriverSettings } from "../../requests/useUpdateCarrierDriverSettings";
import { User } from "../../userAccess/UserContext";
import { useUser } from "../../userAccess/useUser";

const CarrierDriverInfo = () => {
  const displayMessage = useDisplayMessage();
  const user = useUser(); 

  const useGetSiteSettings = useUserQuery<CarrierDriverSettings>({
    path: `/api/sites/${user.selectedSite!.id}/settings`
  }, {
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  return <QueryView
    query={useGetSiteSettings}
    renderData={carrierDriverSettings => <>
      {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
      <CarrierDriverInfoForm 
        initialSettings={carrierDriverSettings}
        user={user} />
    </>
  }/>
}

interface CarrierDriverInfoFormProps {
  initialSettings: CarrierDriverSettings,
  user: User
}

const CarrierDriverInfoForm = (props: CarrierDriverInfoFormProps) => {
  const displayMessage = useDisplayMessage();

  const updateSiteSettings = useUpdateCarrierDriverSettings(props.user, {
    onSuccess: () => displayMessage.success("Carrier Driver settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const { register, handleSubmit, watch, setValue } = useForm<CarrierDriverSettings>({defaultValues: props.initialSettings});

  const onSubmit: SubmitHandler<CarrierDriverSettings> = (data) => updateSiteSettings.request(data);

  const requireDriverNumber = watch("requireDriverNumber");
  if (!requireDriverNumber) { setValue("requireDriverPassword", false) }

  return <>
      {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
      <form data-testid="carrierDriverSettings-form" className="m-auto w-50">
        <h2>Carrier/Driver Info</h2>
        <div className="border border-dark rounded">
          <div className="m-3">
            <div className="form-check">
              <input type="checkbox" id="RequireCarrierNumber" className="form-check-input" {...register("requireCarrierNumber") }/>
              <label className="form-check-label" htmlFor="RequireCarrierNumber">Prompt for Carrier Number</label>
            </div>
            <div className="form-check">
              <input type="checkbox" id="RequireTransportNumber" className="form-check-input" {...register("requireTransportNumber") }/>
              <label className="form-check-label" htmlFor="RequireTransportNumber">Prompt for Transport Number</label>
            </div>
            <div className="form-check">
              <input type="checkbox" id="RequireDriverNumber" className="form-check-input" {...register("requireDriverNumber") }/>
              <label className="form-check-label" htmlFor="RequireDriverNumber">Prompt for Driver Number</label>
            </div>
            <div className="form-check">
              <input type="checkbox" id="RequireDriverPassword" className="form-check-input" disabled={!requireDriverNumber} {...register("requireDriverPassword") } />
              <label className="form-check-label" htmlFor="RequireDriverPassword">Prompt for Driver Password</label>
            </div>
          </div>
        </div>
      </form>
      <div className="align-self-end">
        <SpinnerButton 
          className="btn btn-primary mx-4 mb-4 px-5 py-3" 
          spinning={updateSiteSettings.isLoading}
          onClick={handleSubmit(onSubmit)}>
          Save
        </SpinnerButton>
      </div>
  </>
}

export { CarrierDriverInfo };
