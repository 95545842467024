import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export interface BayValidationSettings {
  printBarcodesAtKiosk: boolean;
  showBarcodeForAutomatedLoadout: boolean;
}

export default function useUpdateBayValidationSettings(options?: RequestOptions) {
  const user = useUser();

  return useUserRequest<BayValidationSettings>({
    path: `/api/sites/${user.selectedSite!.id}/bayDirectionsValidationSettings`,
    method: HttpMethod.PUT
  }, options);
}
