import { TimePicker, TimeValidationError } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import Row from "../../../common/Row";
import { capitalizeFirstLetter } from "../../../common/utilities";

type BusinessHoursDayEntryProps = {
  day: string
  openTime: Dayjs | null
  closeTime: Dayjs | null
  updateAndValidateBusinessDay: (day: string, openTime: Dayjs | null, closeTime: Dayjs | null) => void
};

const BusinessHoursDayEntry = ({ day, openTime, closeTime, updateAndValidateBusinessDay }: BusinessHoursDayEntryProps) => {
  const dayJsTimeFormat = "HH:mm:ss";

  const [isClosed, setIsClosed] = useState<boolean>((!openTime || !closeTime));

  const [prevTimes, setPrevTimes] = useState({
    openTime: openTime ?? dayjs("08:00:00", dayJsTimeFormat),
    closeTime: closeTime ?? dayjs("17:00:00", dayJsTimeFormat)
  });

  const [openError, setOpenError] = useState<TimeValidationError | null>(null);
  const [closeError, setCloseError] = useState<TimeValidationError | null>(null);

  const openErrorMessage = useMemo(() => {
    if (!isClosed && (!openTime || !openTime.isValid())) {
      return 'Please select a valid time';
    }

    if (openError === 'maxTime' || openTime?.isSame(closeTime)) {
      return 'Open time must be before close time';
    }

    return "";
  }, [openError, openTime, isClosed]);

  const closeErrorMessage = useMemo(() => {
    if (!isClosed && (!closeTime || !closeTime.isValid())) {
      return 'Please select a valid time';
    }

    if (closeError === 'minTime' || closeTime?.isSame(openTime)) {
      return 'Close time must be after open time';
    }

    return "";
  }, [closeError, closeTime, isClosed]);

  const openMaxTime = closeTime ?? dayjs("23:59:59", dayJsTimeFormat);
  const closeMinTime = openTime ?? dayjs("00:00:00", dayJsTimeFormat);

  const updateOpenTime = (time: Dayjs | null) => {
    updateAndValidateBusinessDay(day, time, closeTime);
  }

  const updateCloseTime = (time: Dayjs | null) => {
    updateAndValidateBusinessDay(day, openTime, time);
  }

  const toggleClosedCheckbox = (checked: boolean) => {
    if (checked) {
      const prevOpenTime = !!openTime && openTime.isValid() ? openTime : prevTimes.openTime;
      const prevCloseTime = !!closeTime && closeTime.isValid() ? closeTime : prevTimes.closeTime;

      setPrevTimes({ openTime: prevOpenTime, closeTime: prevCloseTime });

      updateAndValidateBusinessDay(day, null, null);
    } else {
      updateAndValidateBusinessDay(day, prevTimes.openTime, prevTimes.closeTime);
    }
    setIsClosed(checked);
  }

  const timeDisplayFormat = "hh:mm A";

  return <Row className="pb-3" data-testid={`business-hours-${day}-container`}>
    <div className="col-2 pt-1">
      {capitalizeFirstLetter(day)}
    </div>
    <div className="col-4">
      <TimePicker
        label={isClosed ? "CLOSED" : "Open Time"}
        value={openTime}
        onChange={(newValue) => updateOpenTime(newValue)}
        onError={(error) => setOpenError(error)}
        format={timeDisplayFormat}
        disabled={isClosed}
        slotProps={{
          textField: {
            helperText: openErrorMessage,
            error: !!openErrorMessage,
          }
        }}
        maxTime={openMaxTime}
      />
    </div>
    <div className="col-4">
      <TimePicker
        label={isClosed ? "CLOSED" : "Close Time"}
        value={closeTime}
        onChange={(newValue) => updateCloseTime(newValue)}
        onError={(error) => setCloseError(error)}
        format={timeDisplayFormat}
        disabled={isClosed}
        slotProps={{
          textField: {
            helperText: closeErrorMessage,
            error: !!closeErrorMessage,
          }
        }}
        minTime={closeMinTime}
      />
    </div>
    <div className="col-2 pt-3">
      <input
        id={`closedCbx${day}`}
        type="checkbox"
        className="form-check-input me-2"
        checked={isClosed}
        onChange={(ev) => toggleClosedCheckbox(ev.target.checked)} />
      <label
        className="form-check-label-lg"
        htmlFor={`closedCbx${day}`}>
        Closed
      </label>
    </div>
  </Row>
}

export default BusinessHoursDayEntry;