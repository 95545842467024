import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import Row from "../../../common/Row";

interface DragItem {
  id: string,
  index: number,
}

interface DraggableAnswerProps {
  id: string,
  index: number,
  dragAndDropEnabled: boolean,
  moveAnswer: (dragIndex: number, hoverIndex: number) => void,
  children: React.ReactNode,
}

const DraggableAnswer = (props: DraggableAnswerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'answer',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.moveAnswer(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: 'answer',
    canDrag: props.dragAndDropEnabled,
    item: () => {
      return { id: props.id, index: props.index }
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  drag(drop(ref))
  return <Row className="my-2" innerRef={preview} style={{ opacity }} data-testid={`answer-${props.index}`}>
    <div className="col-1 pt-2 ps-4" ref={ref} style={{ cursor: props.dragAndDropEnabled ? 'move' : 'auto', fontSize: "1.5rem" }} data-handler-id={handlerId}>
      {props.dragAndDropEnabled &&
        <i className="bi-list" />
      }
    </div>
    {props.children}
  </Row>
}

export default DraggableAnswer;
