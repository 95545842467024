export interface DisplayMessage {
  message: string,
  success: boolean,
}

interface KaAlertProps {
  displayMessage: DisplayMessage | null
  onClose?: () => void,
}

const KaAlert = ({ displayMessage, onClose }: KaAlertProps) => {
  return displayMessage &&
    <div className={`alert alert-dismissible ${displayMessage != null ? 'show' : 'hidden'} ${displayMessage?.success ? 'alert-success' : 'alert-danger'}`}
      style={{ wordBreak: "break-word" }}
      role="alert">
      {displayMessage?.message ?? ""}
    <button type="button" className="btn-close" aria-label="Close" onClick={onClose}/>
  </div>
}

export default KaAlert;