import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { UserFacingMessageCode } from "./userFacingMessageCodes";
import { Error } from "../lib/fetch/useFetch";

export const adminFacingErrorMessage = (error: Error) : string => 
  error.errorCode !== undefined ? 
    adminFacingMessage(error.errorCode, error.parameters ?? []) : 
    `${error.message}`;

const adminFacingMessage = (errorCode: UserFacingMessageCode, parameters: string[]) : string => {
  switch (errorCode) {
    case UserFacingMessageCode.SiteNotFound: return `Site not found`;
    case UserFacingMessageCode.StagedOrderNotFound: return `Staged order not found`;
    case UserFacingMessageCode.EmailAddressNotSpecified: return `Email address must be specified`;
    case UserFacingMessageCode.EmailAddressInvalid: return `Email address is invalid`;
    case UserFacingMessageCode.AdminAlreadyExists: return `Admin with email '${parameters[0]}' already exists`;
    case UserFacingMessageCode.SiteAlreadyExists: return `Site already exists`;
    case UserFacingMessageCode.SiteWithNumberAlreadyExists: return `Site with number '${parameters[0]}' already exists`;
    case UserFacingMessageCode.KioskNameRequired: return `Kiosk Name is required`;
    case UserFacingMessageCode.KioskWithNameAlreadyExists: return `Kiosk with name '${parameters[0]}' already exists for current site`;
    case UserFacingMessageCode.ScaleWithIdDoesNotExist: return `Scale with Id '${parameters[0]}' does not exist for current site`;
    case UserFacingMessageCode.ScaleWithNameAlreadyAssignedToKiosk: return `Scale with name '${parameters[0]}' is already assigned to a kiosk`;
    case UserFacingMessageCode.KioskAlreadyRegistered: return `Kiosk has already been registered`;
    case UserFacingMessageCode.KioskNotRegistered: return `Kiosk is not registered`;
    case UserFacingMessageCode.SiteAlreadyRegistered: return `Site has already been registered`;
    case UserFacingMessageCode.CannotUpdateScaleSettingsStagedOrdersInProgress: return `Cannot update scale settings because there are staged orders currently in progress`;
    case UserFacingMessageCode.QuestionLengthTooLong: return `Question ${parameters[0]} longer than max length of ${parameters[1]} characters`;
    case UserFacingMessageCode.AnswerLengthTooLong: return `Answer ${parameters[0]} for Question ${parameters[1]} longer than max length of ${parameters[2]} characters`;
    case UserFacingMessageCode.DuplicateAnswers: return `Duplicate answers for Question ${parameters[0]}`;
    case UserFacingMessageCode.NoCorrectAnswer: return `Question ${parameters[0]} has no correct answer`;
    case UserFacingMessageCode.ZeroOrNegativeNumberOfAnswers: return `Cannot have zero or negative numbers for minimum and maximum number of answers for Question ${parameters[0]}`;
    case UserFacingMessageCode.MultipleAnswersAndOtherOption: return `Minimum and maximum number of answers have to be 1 in order to use other option for Question ${parameters[0]}`;
    case UserFacingMessageCode.MissingRequiredAnswers: return `Missing required answers for Question ${parameters[0]}`;
    case UserFacingMessageCode.CommaInAnswers: return `Cannot have a comma in answers for Question ${parameters[0]}`;
    case UserFacingMessageCode.MinNumberOfAnswersExceedsMax: return `The minimum number of answers cannot be greater than the maximum number of answers for Question ${parameters[0]}`;
    case UserFacingMessageCode.MaxNumberOfAnswersExceedsAnswersAvailable: return `The maximum number of answers cannot be greater than the answers available for Question ${parameters[0]}`;

    default: return `An unknown error occurred`;
  }
}

export const driverFacingErrorMessage = (error: Error) : MessageDescriptor => 
  error.errorCode !== undefined ? 
    driverFacingMessage(error.errorCode, error.parameters ?? []) : 
    msg`${error.message}`;

export const driverFacingMessage = (errorCode: UserFacingMessageCode, parameters: string[]) : MessageDescriptor => {
  switch (errorCode) {
    case UserFacingMessageCode.SiteNotFound: return msg`Site not found`;
    case UserFacingMessageCode.StagedOrderNotFound: return msg`Staged order not found`;
    case UserFacingMessageCode.InvalidDriverCarrier: return msg`Invalid Driver/Carrier`;
    case UserFacingMessageCode.UnrecognizedDriverNumber: return msg`Unrecognized driver number`;
    case UserFacingMessageCode.IncorrectDriverPassword: return msg`Incorrect driver password`;
    case UserFacingMessageCode.UnrecognizedCarrierNumber: return msg`Unrecognized carrier number`;
    case UserFacingMessageCode.UnrecognizedTransportNumber: return msg`Unrecognized transport number`;
    case UserFacingMessageCode.UnrecognizedOrderNumber: return msg`Unrecognized order number`;
    case UserFacingMessageCode.TotalCompartmentWeightExceedsRequested: return msg`Total compartment weight exceeds order requested weight`;
    case UserFacingMessageCode.InvalidDestination: return msg`Invalid destination`;
    case UserFacingMessageCode.ShipToAndDestinationIdSet: return msg`ShipTo and DestinationId cannot both be set`;
    case UserFacingMessageCode.ShipToNameFiftyCharacters: return msg`ShipTo name must be less than 50 characters`;
    case UserFacingMessageCode.NoScaleTareWeight: return msg`No scale is assigned to this station. A tare weight is required for all bays compatible with this order. Please use a station with a scale or contact location manager.`;
    case UserFacingMessageCode.NoScaleGrossWeight: return msg`No scale is assigned to this station. A gross weight is required for this order. Please use a station with a scale or contact location manager.`;
    case UserFacingMessageCode.OrderNotDoneDispensing: return msg`Order is not done dispensing`;
    case UserFacingMessageCode.OrderNotReadyForGross: return msg`Order is not ready to be weighed out`;
    case UserFacingMessageCode.CouldNotFindOrderWithNumber: return msg`Could not find order with number ${parameters[0]}`;
    case UserFacingMessageCode.OrderNotStaged: return msg`Order ${parameters[0]} is not currently staged`;
    case UserFacingMessageCode.NoTareAvailable: return msg`No tare weight available`;
    case UserFacingMessageCode.OrderCurrentlyStaged: return msg`Order number ${parameters[0]} is currently staged`;
    case UserFacingMessageCode.CannotFindOrderWithNumber: return msg`Couldn't find order with number ${parameters[0]}`;
    case UserFacingMessageCode.OrderAlreadyLoaded: return msg`Order number ${parameters[0]} has already been loaded`;
    case UserFacingMessageCode.NotEnoughAnswersGiven: return msg`Not enough answers given for Question ${parameters[0]}. Min number of answers is ${parameters[1]}`;
    case UserFacingMessageCode.TooManyAnswersGiven: return msg`Too many answers given for Question ${parameters[0]}. Max number of answers is ${parameters[1]}`;
    case UserFacingMessageCode.InvalidAnswers: return msg`Invalid answer(s) ${parameters[0]} for Question ${parameters[1]}`;
    case UserFacingMessageCode.InvalidAnswer: return msg`Invalid answer ${parameters[0]}`;
    case UserFacingMessageCode.InvalidLoadQuestion: return msg`Invalid load question`;
    case UserFacingMessageCode.ProductNotConfiguredToABay: return msg`One or more of the products requested is not configured to a bay. Please contact location manager.`;
    case UserFacingMessageCode.InvalidBay: return msg`Invalid Bay. Leave this bay and proceed to ${parameters[0]} for loadout.`;
    case UserFacingMessageCode.InvalidTareWeight: return msg`Invalid tare weight`;
    case UserFacingMessageCode.InvalidGrossWeight: return msg`Invalid gross weight`;
    case UserFacingMessageCode.ScaleRemovedNoLongerAvailableForTare: return msg`Scale ${parameters[0]} has been removed and is no longer available for tare. Please scan at a valid station or contact location manager.`;
    case UserFacingMessageCode.ScaleRemovedNoLongerAvailableForGross: return msg`Scale ${parameters[0]} has been removed and is no longer available for gross. Please scan at a valid station or contact location manager.`;
    case UserFacingMessageCode.ScanSuccessfulAtValidBay: return msg`Scan Successful. You are at a valid bay (${parameters[0]}) for loadout.`;
    case UserFacingMessageCode.BayNoLongerAvailable: return msg`Bay ${parameters[0]} is no longer available. Contact facility manager.`;
    case UserFacingMessageCode.LoadCancelled: return msg`Load ${parameters[0]} has been cancelled and is no longer available for loadout.`;
    case UserFacingMessageCode.LoadCancelledByAnAdmin: return msg`Load for Order Number ${parameters[0]} has been cancelled by an administrator at ${parameters[1]}.`;
    case UserFacingMessageCode.ScanSuccessful: return msg`Scan Successful.`;
    case UserFacingMessageCode.ScanSuccessfulProceedToBay: return msg`Scan Successful. Proceed to ${parameters[0]} for loadout.`;
    case UserFacingMessageCode.AnotherTruckScannedAtScale: return msg`Another truck has scanned at the scale. Please scan again to verify your gross weight.`;
    case UserFacingMessageCode.SiteClosed: return msg`Site is closed`;

    default: return msg`An unknown error occurred`;
  }
}