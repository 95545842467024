import { useState } from "react";
import { convertUnit } from "./unitConversion";
import { Units } from "./units";

export interface Weight {
  value: string | number;
  unit: Units;
}

const useWeight = (iniWeight: Weight) => {
  const [weight, set] = useState<Weight>(iniWeight);

  const setWeight = (value: string | number, unit: Units) => {
    set({ value, unit });
  }

  const as = (toUnit: Units) => {
    return convertUnit(weight.value, weight.unit, toUnit);
  }

  return { value: weight.value, unit: weight.unit, as, setWeight };
}

export default useWeight;
