import { msg, plural } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useRef } from "react";
import { arraysEqual, createHashForStrings } from "../utilities";
import { SelectOption } from "./SelectSearch";

export interface MultiSelectSearchProps {
  id: string,
  onChange: (values: string[]) => void;
  values: string[],
  options: SelectOption[],
  searchInDropdown?: boolean,
  placeholder?: string,
  className?: string,
  hideSelected?: boolean,
  hideSearch?: boolean,
  singleMultiple?: boolean,
  disabled?: boolean,
  testId?: string,
}

const MultiSelectSearch = (props: MultiSelectSearchProps) => {
  const ref = useRef<HTMLSelectElement>(null);
  const disabled = props.disabled ?? false;
  const { _ } = useLingui();

  const displayItemsSelected = (numberSelected: number): string => { 
    if (numberSelected === 0) 
      return props.placeholder ?? _(msg`No items selected`);

    return plural(numberSelected, {
      one: "1 item selected",
      other: "# items selected",
    });
  }

  useEffect(() => {
    // @ts-ignore
    if (!ref.current.tomselect) {
      // @ts-ignore
      window.initJsSelect(ref.current);
    }

    // @ts-ignore
    ref.current.tomselect.sync(props.values);

    // @ts-ignore
    ref.current.tomselect.refreshOptions(false);

    if (props.singleMultiple) {
      // @ts-ignore
      const placeholder = ref.current.tomselect.wrapper?.querySelector('.items-placeholder');
      if (placeholder) placeholder.remove();

      // @ts-ignore
      let selectedCount = ref.current.tomselect.wrapper?.querySelector('.selected-count');

      if (!selectedCount) {
        const createSelectedCount = document.createElement('span')
        createSelectedCount.classList?.add('selected-count', 'text-secondary')
        // @ts-ignore
        ref.current.tomselect.wrapper?.querySelector('.ts-control').appendChild(createSelectedCount)
        // @ts-ignore
        selectedCount = ref.current.tomselect.wrapper?.querySelector('.selected-count');
      }

      const selectedCountText = document.createTextNode(displayItemsSelected(props.values.length));
      if (props.values.length > 0) selectedCount?.classList?.add('mt-1','mb-2','mx-2');
      else selectedCount?.classList?.remove('mt-1', 'mb-2', 'mx-2');
      selectedCount?.replaceChildren(selectedCountText);
    }
  });


  const selectOptions = JSON.stringify({
    hideSelected: props.hideSelected,
    singleMultiple: props.singleMultiple,
    hideSearch: props.hideSearch,
    hidePlaceholderOnSearch: true,
    placeholder: props.placeholder ?? "",
  });

  const key = createHashForStrings(
    [...props.options.map(o => o.value) ?? "", props.values.join() ?? "", props.disabled?.toString() ?? "false"]);

  return <div className={`tom-select-custom ${props.className}`} key={key}>
    <select className="js-select form-select"
      onChange={(ev) => {
        const newValues = Array.from(ev.target.selectedOptions).map(o => o.value);
        
        if (!arraysEqual(newValues, props.values)) {
          props.onChange(newValues);
        }
      }}
      autoComplete="off"
      multiple
      id={props.id}
      data-hs-tom-select-options={selectOptions}
      ref={ref}
      value={props.values}
      disabled={disabled}
      data-testid={props.testId}
    >
      {props.options.map(o =>
        <option
          key={o.value}
          value={o.value}
        >
          {o.name ?? o.value}
        </option>)
      }
    </select>
  </div>
}

export default MultiSelectSearch;
