interface RowProps extends
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    innerRef?: React.Ref<HTMLDivElement>;
}

const Row = (props: RowProps) => {
  const { innerRef, ...divProps } = props;

  return <div
    {...divProps}
    ref={innerRef}
    className={`row ${(props.className ?? "")}`} />
}

export default Row;
