const FullscreenLoadingSpinner = () => {
  return <>
    <div className="position-absolute top-50 start-50 translate-middle">
      <div style={{ width: "8rem", borderWidth: ".75rem", height: "8rem" }}
        className="spinner-border text-primary"
        role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </>
}

export default FullscreenLoadingSpinner;