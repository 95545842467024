export enum UserFacingMessageCode {
  SiteNotFound = 'SiteNotFound',
  StagedOrderNotFound = 'StagedOrderNotFound',
  EmailAddressNotSpecified = 'EmailAddressNotSpecified',
  EmailAddressInvalid = 'EmailAddressInvalid',
  AdminAlreadyExists = 'AdminAlreadyExists',
  SiteAlreadyExists = 'SiteAlreadyExists',
  SiteWithNumberAlreadyExists = 'SiteWithNumberAlreadyExists',
  InvalidDriverCarrier = 'InvalidDriverCarrier',
  UnrecognizedDriverNumber = 'UnrecognizedDriverNumber',
  IncorrectDriverPassword = 'IncorrectDriverPassword',
  UnrecognizedCarrierNumber = 'UnrecognizedCarrierNumber',
  UnrecognizedTransportNumber = 'UnrecognizedTransportNumber',
  UnrecognizedOrderNumber = 'UnrecognizedOrderNumber',
  TotalCompartmentWeightExceedsRequested = 'TotalCompartmentWeightExceedsRequested',
  InvalidDestination = 'InvalidDestination',
  ShipToAndDestinationIdSet = 'ShipToAndDestinationIdSet',
  ShipToNameFiftyCharacters = 'ShipToNameFiftyCharacters',
  NoScaleTareWeight = 'NoScaleTareWeight',
  NoScaleGrossWeight = 'NoScaleGrossWeight',
  OrderNotDoneDispensing = 'OrderNotDoneDispensing',
  OrderNotReadyForGross = 'OrderNotReadyForGross',
  CouldNotFindOrderWithNumber = 'CouldNotFindOrderWithNumber',
  OrderNotStaged = 'OrderNotStaged',
  KioskNameRequired = 'KioskNameRequired',
  KioskWithNameAlreadyExists = 'KioskWithNameAlreadyExists',
  ScaleWithIdDoesNotExist = 'ScaleWithIdDoesNotExist',
  ScaleWithNameAlreadyAssignedToKiosk = 'ScaleWithNameAlreadyAssignedToKiosk',
  KioskAlreadyRegistered = 'KioskAlreadyRegistered',
  KioskNotRegistered = 'KioskNotRegistered',
  SiteAlreadyRegistered = 'SiteAlreadyRegistered',
  NoTareAvailable = 'NoTareAvailable',
  CannotUpdateScaleSettingsStagedOrdersInProgress = 'CannotUpdateScaleSettingsStagedOrdersInProgress',
  QuestionLengthTooLong = 'QuestionLengthTooLong',
  AnswerLengthTooLong = 'AnswerLengthTooLong',
  DuplicateAnswers = 'DuplicateAnswers',
  NoCorrectAnswer = 'NoCorrectAnswer',
  ZeroOrNegativeNumberOfAnswers = 'ZeroOrNegativeNumberOfAnswers',
  MultipleAnswersAndOtherOption = 'MoreThanOnePlainTextAnswer',
  MissingRequiredAnswers = 'MissingRequiredAnswers',
  CommaInAnswers = 'CommaInAnswers',
  MinNumberOfAnswersExceedsMax = 'MinNumberOfAnswersExceedsMax',
  MaxNumberOfAnswersExceedsAnswersAvailable = 'MaxNumberOfAnswersExceedsAnswersAvailable',
  OrderCurrentlyStaged = 'OrderCurrentlyStaged',
  CannotFindOrderWithNumber = 'CannotFindOrderWithNumber',
  OrderAlreadyLoaded = 'OrderAlreadyLoaded',
  NotEnoughAnswersGiven = 'NotEnoughAnswersGiven',
  TooManyAnswersGiven = 'TooManyAnswersGiven',
  InvalidAnswers = 'InvalidAnswers',
  InvalidAnswer = 'InvalidAnswer',
  InvalidLoadQuestion = 'InvalidLoadQuestion',
  ProductNotConfiguredToABay = 'ProductNotConfiguredToABay',
  InvalidBay = 'InvalidBay',
  InvalidTareWeight = 'InvalidTareWeight',
  InvalidGrossWeight = 'InvalidGrossWeight',
  ScaleRemovedNoLongerAvailableForTare = 'ScaleRemovedNoLongerAvailableForTare',
  ScaleRemovedNoLongerAvailableForGross = 'ScaleRemovedNoLongerAvailableForGross',
  ScanSuccessfulAtValidBay = 'ScanSuccessfulAtValidBay',
  BayNoLongerAvailable = 'BayNoLongerAvailable',
  LoadCancelled = 'LoadCancelled',
  LoadCancelledByAnAdmin = 'LoadCancelledByAnAdmin',
  ScanSuccessful = 'ScanSuccessful',
  ScanSuccessfulProceedToBay = 'ScanSuccessfulProceedToBay',
  AnotherTruckScannedAtScale = 'AnotherTruckScannedAtScale',
  SiteClosed = 'SiteClosed',
}
