import { useState } from "react";
import useSubmitSignature from '../../requests/useSubmitSignature';
import DeviceOrientation, { LockOrientations, Orientation }
  from '../../common/orientation/Orientation';
import Container, { Progress, TextHeader } from "../../common/Container";
import KaSignaturePad from "./KaSignaturePad";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";

interface SignatureProps {
  stagedOrderId: string,
  progress?: Progress,
  signatureSubmitted: (emailTickets: boolean, userEmail?: string) => void,
  devToolItems?: React.ReactNode,
}

const Signature = (props: SignatureProps) => {
  const { _ } = useLingui();
  const [signature, setSignature] = useState("");
  const submitSignature = useSubmitSignature(props.stagedOrderId, {
    onSuccess: ({ emailTickets, userEmail }) =>
      props.signatureSubmitted(emailTickets, userEmail),
  });

  const submit = () => {
    const pngPrefix = 'data:image/png;base64,'
    const pngData = signature?.substring(pngPrefix.length)

    submitSignature.request({ data: pngData });
  }

  return <DeviceOrientation lockOrientation={LockOrientations.Landscape}>
    <Orientation orientation='landscape' alwaysRender={false}>
      <div className="height-100">
        <h1 className="text-center mb-3 mt-4 d-lg-none"><Trans>Sign for Ticket</Trans></h1>
        <div className="height-15 mb-10 d-none d-lg-block">
          <TextHeader title={_(msg`Sign for Ticket`)} />
        </div>
        <div className="height-50 w-100 px-5 px-md-10">
          <KaSignaturePad
            signatureImage={signature}
            setSignatureImage={sigImg => setSignature(sigImg)} 
            submit={submit}
            submitSignatureIsLoading={submitSignature.isLoading}/>
        </div>
      </div>
    </Orientation>
    <Orientation orientation='portrait' alwaysRender={false}>
      <Container
        title={_(msg`Signature`)}
        subtitle={_(msg`Rotate device for larger signing area`)}
        devToolItems={props.devToolItems}>
        <div className="d-block m-auto w-100 px-3 px-md-10">
          <h1 className="text-center mb-3 mt-4 d-lg-none"><Trans>Sign for Ticket</Trans></h1>
          <div className="h-50 w-100">
            <KaSignaturePad
              signatureImage={signature}
              setSignatureImage={sigImg => setSignature(sigImg)} 
              submit={submit}
              submitSignatureIsLoading={submitSignature.isLoading}/>
          </div>
          <div className="my-10"/>
        </div>
      </Container>
    </Orientation>
  </DeviceOrientation>
}

export default Signature;
