import { msg, Trans } from "@lingui/macro";
import { MessageDescriptor } from "@lingui/core";
import { useLingui } from "@lingui/react";
import Row from "./Row";
import { DayOfTheWeek, Hours } from "../requests/dtos/Hours";

type BusinessHoursProps = {
  timeZone?: string,
  hours?: Hours[],
}

const toHoursMinutes = (openTime: string, closeTime: string) => {
  return `${openTime} - ${closeTime.padStart(8)}`;
}

const businessHoursTitle = (timeZone: string | undefined): MessageDescriptor => {
  switch (timeZone) {
    case "Central": return msg`Business Hours (Central)`;
    case "Eastern": return msg`Business Hours (Eastern)`;
    case "Mountain": return msg`Business Hours (Mountain)`;
    case "Pacific": return msg`Business Hours (Pacific)`;
    default: return msg`An unknown error occurred`;
  }
}

export const BusinessHours = ({ hours, timeZone }: BusinessHoursProps) => {
  const { _ } = useLingui();
  const currentDay = Object.values(DayOfTheWeek)[new Date().getDay()];

  const hoursForDay = (dayLabel: string, day: DayOfTheWeek) => {
    const dayHours = hours?.find(h => h.day === day) ?? { day: day };

    return <Row className={currentDay === day ? "text-primary fw-bold" : ""}>
      <label className="col-auto pe-0">
        {dayLabel}:
      </label>
      <p className="col mb-0 ps-0 text-end">
        {
          (dayHours.openTime && dayHours.closeTime)
            ? toHoursMinutes(dayHours.openTime, dayHours.closeTime)
            : <em><Trans>Closed</Trans></em>
        }
      </p>
    </Row>
  }

  return <div className={'card border my-4 my-sm-8'}>
    <div className="card-body p-2">
      <h5>{_(businessHoursTitle(timeZone))}</h5>
      {hoursForDay(_(msg`Sunday`), DayOfTheWeek.Sunday)}
      {hoursForDay(_(msg`Monday`), DayOfTheWeek.Monday)}
      {hoursForDay(_(msg`Tuesday`), DayOfTheWeek.Tuesday)}
      {hoursForDay(_(msg`Wednesday`), DayOfTheWeek.Wednesday)}
      {hoursForDay(_(msg`Thursday`), DayOfTheWeek.Thursday)}
      {hoursForDay(_(msg`Friday`), DayOfTheWeek.Friday)}
      {hoursForDay(_(msg`Saturday`), DayOfTheWeek.Saturday)}
    </div>
  </div>
}