import React, { useEffect } from 'react';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { KaEmployeeAuthority, msalConfig } from './msalConfig';
import { UserProvider } from './userAccess/UserProvider';
import { KaEmployeeProvider } from './userAccess/kaEmployee/KaEmployeeProvider';
import { i18n } from "@lingui/core";
import { I18nProvider } from '@lingui/react';
import { defaultLocale, dynamicActivate } from './i18n';
import useLocalStorage from './common/lib/hooks/useLocalStorage';
import * as ReactDOMClient from "react-dom/client";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MonitoringContextProvider } from './common/lib/Monitoring';

const I18nApp = () => {
  const pca = new PublicClientApplication(msalConfig.pcaConfig);
  const kaMsalConfig = JSON.parse(JSON.stringify(msalConfig));
  kaMsalConfig.signInRequest.authority = KaEmployeeAuthority;

  const [currentLanguage, _] = useLocalStorage("language", defaultLocale);

  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(currentLanguage);
  }, [currentLanguage]);

  return (
    <MsalProvider instance={pca}>
      <MonitoringContextProvider>
        <UserProvider config={msalConfig}>
          <KaEmployeeProvider config={kaMsalConfig}>
            <DndProvider backend={HTML5Backend}>
              <I18nProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </I18nProvider>
            </DndProvider>
          </KaEmployeeProvider>
        </UserProvider>
      </MonitoringContextProvider>
    </MsalProvider>
  )
}

ReactDOMClient.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <I18nApp />
  </React.StrictMode>
);
