import React, { useEffect, useReducer } from 'react'
import useLocalStorage from '../../common/lib/hooks/useLocalStorage';
import useKioskAuthorize from '../../requests/useKioskAuthorize';
import { KioskContext, KioskState, Kiosk, KioskStatus, Role } from './KioskContext';

enum ActionType {
  Registered,
  Authorized,
}

type KioskCredentials = {
  id: string,
  secret: string,
}

type State =
  | { step: KioskState.NotRegistered }
  | { step: KioskState.Authorizing, credentials: KioskCredentials }
  | { step: KioskState.SignedIn, kiosk: Kiosk }

type Action =
  | { type: ActionType.Registered, kioskId: string, secret: string }
  | { type: ActionType.Authorized, token: string, siteId: string, role: Role };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.Registered:
      return {
        step: KioskState.Authorizing,
        credentials: { id: action.kioskId, secret: action.secret }
      };

    case ActionType.Authorized:
      if (state.step === KioskState.Authorizing) {
        return {
          step: KioskState.SignedIn,
          kiosk: {
            id: state.credentials.id,
            state: KioskState.SignedIn,
            ...action,
          }
        };
      }
      break;
    default:
      console.error("Unexpected action received", state, action);
  }

  return state;
}

const initializer = (credentials: KioskCredentials | null): State =>
  credentials
    ? { step: KioskState.Authorizing, credentials }
    : { step: KioskState.NotRegistered };

export const KioskProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [credentials, setCredentials] = useLocalStorage<KioskCredentials | null>("kioskCredentials", null);
  const [state, dispatch] = useReducer(reducer, credentials, initializer);

  const registered = (kioskId: string, secret: string) => {
    setCredentials({ id: kioskId, secret });
    dispatch({ type: ActionType.Registered, kioskId, secret });
  }

  const kioskId: string = state.step === KioskState.Authorizing
    ? state.credentials.id
    : "";

  const useAuth = useKioskAuthorize(kioskId, {
    onSuccess: (resp) => dispatch({ type: ActionType.Authorized, ...resp, }),
  });

  useEffect(() => {
    if (!useAuth.isLoading && state.step === KioskState.Authorizing) {
      useAuth.request({ secret: state.credentials.secret });
    }
  }, [state, useAuth]);

  const toKioskStatus = (): KioskStatus => {
    switch (state.step) {
      case KioskState.Authorizing:
        return { state: KioskState.Authorizing };

      case KioskState.SignedIn:
        return { ...state.kiosk, state: KioskState.SignedIn, };

      case KioskState.NotRegistered:
      default:
        return { state: KioskState.NotRegistered, register: registered };
    }
  }

  return <KioskContext.Provider value={toKioskStatus()}>
    {children}
  </KioskContext.Provider>;
};
