import { QueryOptions } from "../common/lib/fetch/useQuery";
import useUserQuery from "../common/lib/fetch/useUserQuery";
import { Hours } from "./dtos/Hours";

export interface Scale {
  id: string;
  name: string;
}

export interface Site {
  id: string;
  city: string;
  state: string;
  timeZone?: string;
  hours?: Hours[];
  scales: Scale[];
}

export interface Business {
  name: string;
  sites: Site[];
}

const useBusinesses = (options?: QueryOptions<Business[]>) =>
  useUserQuery<Business[]>({ path: '/api/businesses' }, options);

export default useBusinesses;
