import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import KaAlert from "../common/lib/KaAlert";
import QueryView from "../common/lib/QueryView";
import { Order, useGetOrders } from "./useGetOrders";
import { PastOrder, useGetPastOrders } from "./useGetPastOrders";
import { StagedOrder, useGetStagedOrders } from "./useGetStagedOrders";
import { useRemoveStagedOrder } from "./useRemoveStagedOrder";
import { Query } from "../common/lib/fetch/useQuery";
import { useContext, useState } from "react";
import { SelectedSiteContext } from "./KAEmployeeContainer";
import { AdminSite } from "../userAccess/UserContext";
import { isProdEnvironment } from "../common/utilities";
import { adminFacingErrorMessage } from "../common/userFacingMessages/userFacingMessages";

const StagedOrdersForm = ({ selectedSite, getStagedOrders, stagedOrders }: { selectedSite: AdminSite, getStagedOrders: Query<StagedOrder[]>, stagedOrders: StagedOrder[] }) => {
  const displayMessage = useDisplayMessage();
  const [orderToRemove, setOrderToRemove] = useState("");

  const removeStagedOrder = useRemoveStagedOrder(selectedSite!.id, orderToRemove, {
    onSuccess: () => {
      getStagedOrders.query();
      displayMessage.success(`Staged order successfully cancelled`);
    },
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const HandleCancelStagedOrder = (stagedOrderId: string) => {
    setOrderToRemove(stagedOrderId);
    removeStagedOrder.request(stagedOrderId);
  }

  // Separates strings based on capital letters
  const formatStagedOrderStep = (step: string) => step.replace(/([A-Z])/g, ' $1').trim();

  return <div className="container">
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Staged Order Id</th>
          <th scope="col">Order Number</th>
          <th scope="col">Order Status</th>
        </tr>
      </thead>
      <tbody>
        {stagedOrders.map((so, index) =>
          <>
            <tr key={so.id}>
              <td>{so.id}</td>
              <td>{so.orderNumber}</td>
              <td>{formatStagedOrderStep(so.step)}</td>
              <td className="d-flex">
                {!isProdEnvironment() && <button
                  type="button"
                  className="btn btn-danger w-100 mx-1"
                  data-bs-toggle="modal"
                  data-bs-target={"#stagedOrderCancel" + index}>
                  Cancel
                </button>}
                <button
                  type="button"
                  className="btn btn-primary w-100 mx-1"
                  data-bs-toggle="modal"
                  data-bs-target={"#stagedOrderInfo" + index}>
                  Details
                </button>
              </td>
            </tr>
            <div
              className="modal fade"
              id={"stagedOrderCancel" + index}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={"stagedOrderCancel" + index + "Label"}
              aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={"stagedOrderCancel" + index + "Label"}>Confirm</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to cancel staged order {so.id}?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-white"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => HandleCancelStagedOrder(so.id)}>
                      Cancel Staged Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id={"stagedOrderInfo" + index}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={"stagedOrderInfo" + index + "Label"}
              aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={"stagedOrderInfo" + index + "Label"}>Staged Order {so.id}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th scope="row">Order Number</th>
                          <td>{so.orderNumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">User Email</th>
                          <td>{so.userEmail}</td>
                        </tr>
                        <tr>
                          <th scope="row">Customer</th>
                          <td>{so.customer}</td>
                        </tr>
                        <tr>
                          <th scope="row">{so.products.length === 1 ? "Product" : "Products"}</th>
                          <td>{so.products.length !== 0 ? so.products.join(", ") : "None"}</td>
                        </tr>
                        {so.bay != null &&
                          <tr>
                            <th scope="row">Bay</th>
                            <td>{so.bay}</td>
                          </tr>  
                        }
                        {so.compartments.map((compartment, index) => <>
                          <tr>
                            {index === 0 && <th scope="row" rowSpan={so.compartments.length}>Compartments</th>}
                            <td>{compartment}lbs</td>
                          </tr>
                        </>)}
                        {so.tare != null && 
                          <tr>
                            <th scope="row">Tare Weight</th>
                            <td>{so.tare + so.tareUnit}</td>
                          </tr>}
                        <tr>
                          <th scope="row">Current Order Status</th>
                          <td>{formatStagedOrderStep(so.step)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </tbody>
    </table>
  </div>
}

const AvailableOrdersForm = ({ orders }: { orders: Order[] }) => {
  const displayMessage = useDisplayMessage();

  return <div className="container">
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Order Id</th>
          <th scope="col">Number</th>
          <th scope="col">Customer</th>
          <th scope="col">Products</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((o, index) =>
          <>
            <tr key={o.id}>
              <td>{o.id}</td>
              <td>{o.number}</td>
              <td>{o.customer}</td>
              <td>{ o.products.length !== 0 ? o.products.join(", ") : "None" }</td>
              <td className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary w-100 mx-1"
                  data-bs-toggle="modal"
                  data-bs-target={"#orderInfo" + index}>
                  Details
                </button>
              </td>
            </tr>
            <div
              className="modal fade"
              id={"orderInfo" + index}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={"orderInfo" + index + "Label"}
              aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={"orderInfo" + index + "Label"}>Order {o.id}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th scope="row">Number</th>
                          <td>{o.number}</td>
                        </tr>
                        <tr>
                          <th scope="row">Customer</th>
                          <td>{o.customer}</td>
                        </tr>
                        <tr>
                          <th scope="row">{o.products.length === 1 ? "Product" : "Products"}</th>
                          <td>{o.products.length !== 0 ? o.products.join(", ") : "None"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Destination</th>
                          <td>{o.destination == null ? "None" : o.destination}</td>
                        </tr>
                        <tr>
                          <th scope="row">Requested</th>
                          <td>{o.requested}</td>
                        </tr>
                        <tr>
                          <th scope="row">Blend</th>
                          <td>{!o.doNotBlend ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Available Bays</th>
                          <td>{o.bays.map(b => <p className="mb-1">{b}</p>)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </tbody>
    </table>
  </div>
}

const PastOrdersForm = ({ pastOrders }: { pastOrders: PastOrder[] }) => {
  const displayMessage = useDisplayMessage();

  return <div className="container">
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Order Id</th>
          <th scope="col">Number</th>
          <th scope="col">Completed At</th>
        </tr>
      </thead>
      <tbody>
        {pastOrders.map((po, index) =>
          <>
            <tr key={po.orderId}>
              <td>{po.orderId}</td>
              <td>{po.orderNumber}</td>
              <td>{po.completedAt}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  </div>
}

const Dashboard = () => {
  const displayMessage = useDisplayMessage();

  const selectedSite = useContext(SelectedSiteContext).selectedSite;

  const getStagedOrders = useGetStagedOrders(selectedSite!.id);
  const getAvailableOrders = useGetOrders(selectedSite!.id);
  const getPastOrders = useGetPastOrders(selectedSite!.id);

  const refreshOrders = () => {
    getStagedOrders.query();
    getAvailableOrders.query();
    getPastOrders.query();
  }

  return <div>
    <div className="text-center position-relative">
      <button
        className="btn btn-primary btn-icon rounded-pill position-absolute top-0 start-0 my-1 mx-8"
        onClick={() => refreshOrders()}>
        <i className="bi bi-arrow-clockwise"></i>
      </button>
      <ul className="nav nav-segment nav-pills mb-5" role="tablist">
        <li className="nav-item">
          <a className="nav-link active"
            id="staged-orders-display-tab"
            href="#staged-orders-display"
            data-bs-toggle="pill"
            data-bs-target="#staged-orders-display"
            role="tab"
            aria-controls="staged-orders-display"
            aria-selected="true">
            Staged Orders
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link"
            id="available-orders-display-tab"
            href="#available-orders-display"
            data-bs-toggle="pill"
            data-bs-target="#available-orders-display"
            role="tab"
            aria-controls="available-orders-display"
            aria-selected="false">
            Available Orders
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link"
            id="past-orders-display-tab"
            href="#past-orders-display"
            data-bs-toggle="pill"
            data-bs-target="#past-orders-display"
            role="tab"
            aria-controls="past-orders-display"
            aria-selected="false">
            Past Orders
          </a>
        </li>
      </ul>
    </div>
    <div className="tab-content">
      <div className="tab-pane fade show active" id="staged-orders-display" role="tabpanel" aria-labelledby="staged-orders-display-tab">
        <QueryView
        query={getStagedOrders}
        renderData={stagedOrders => <>
          <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
          <StagedOrdersForm selectedSite={selectedSite} getStagedOrders={getStagedOrders} stagedOrders={stagedOrders} />
        </>
        } />
      </div>
      <div className="tab-pane fade" id="available-orders-display" role="tabpanel" aria-labelledby="available-orders-display-tab">
        <QueryView
          query={getAvailableOrders}
          renderData={orders => <>
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <AvailableOrdersForm orders={orders} />
          </>
          } />
      </div>
      <div className="tab-pane fade" id="past-orders-display" role="tabpanel" aria-labelledby="past-orders-display-tab">
        <QueryView
          query={getPastOrders}
          renderData={pastOrders => <>
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <PastOrdersForm pastOrders={pastOrders} />
          </>
          } />
      </div>
    </div>
  </div>
}

export { Dashboard };
