import { useUserOrKioskRequest } from "../common/lib/fetch";
import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";

interface RejectGrossWeightResponse {
  officePhoneNumber?: string,
}

const useRejectGrossWeight = (stagedOrderId: string, options: RequestOptions<RejectGrossWeightResponse>) =>
  useUserOrKioskRequest<void, RejectGrossWeightResponse>({
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/rejectGrossWeight`,
  }, options);

export default useRejectGrossWeight;
