import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";
import { useContext } from "react";
import { SelectedSiteContext } from "./KAEmployeeContainer";
import { AdminSite } from "../userAccess/UserContext";


export function KAEmployeeNavMenu({ sites }: { sites: AdminSite[] }) {
  return (
    <header className="navbar navbar-expand-sm navbar-end navbar-sticky-top navbar-light bg-white">
      <div className="container-fluid">
        <div className="navbar-nav-wrap">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sideNavbar" aria-controls="sideNavbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-default">
              <i className="bi-list"></i>
            </span>
            <span className="navbar-toggler-toggled">
              <i className="bi-x"></i>
            </span>
          </button>

          <nav className="navbar-nav-wrap-col navbar-collapse">
            <ul className="navbar-nav">
              <KAEmployeeMenu sites={sites} />
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

const KAEmployeeMenu = ({ sites }: { sites: AdminSite[] }) => {
  const selectedSite = useContext(SelectedSiteContext).selectedSite;
  const setSelectedSite = useContext(SelectedSiteContext).setSelectedSite;
  const user = useKaEmployee();
  const menuText = user ? user.email + (selectedSite ? ` : ${selectedSite.city + ', ' + selectedSite.state}` : '') : '';
  const isSelectedSite = isSiteForId(selectedSite?.id);

  return <>
    <li className='nav-item dropdown'>
      <button id='blogMegaMenu' className='nav-link dropdown-toggle btn btn-link'
        data-bs-toggle='dropdown' aria-expanded='false'>{menuText}</button>
      <div className='dropdown-menu'>
        <span className='dropdown-header'>Site</span>
        {sites.sort(alphabeticallyByCity).map(site =>
          <button className={'dropdown-item btn btn-link ' + (isSelectedSite(site) ? 'active' : '')}
            key={site.id} onClick={() => setSelectedSite(sites.find(isSiteForId(site.id))!)}>
            {site.city + ', ' + site.state}</button>)}
        <div className='dropdown-divider'></div>
        <button className='dropdown-item btn btn-link' onClick={() => user.signOut()}>Sign Out</button>
      </div>
    </li>
  </>
};

const alphabeticallyByCity = (a: AdminSite, b: AdminSite) => a.city.localeCompare(b.city);

const isSiteForId = (id: string | undefined) =>
  (site: AdminSite) => site.id.toLowerCase() === id?.toLowerCase();
