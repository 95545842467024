import { useUserOrKioskQuery, QueryOptions } from "../common/lib/fetch";

export type BayDirectionsValidationSettings = {
  printBarcodesAtKiosk: boolean;
  showBarcodeForAutomatedLoadout: boolean;
}

export const useGetBayDirectionsValidationSettings = (siteId: string, options?: QueryOptions<BayDirectionsValidationSettings>) =>
  useUserOrKioskQuery<BayDirectionsValidationSettings>({
    path: `/api/sites/${siteId}/bayDirectionsValidationSettings`
  }, options);
