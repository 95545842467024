import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import useInterval from "../../common/lib/hooks/useInterval";
import Row from "../../common/Row";
import { Units } from "../../common/units/units";
import { unitsToDisplay } from "../../common/units/unitsToDisplay";
import { Weight } from "../../common/units/useWeight";
import useGetScaleWeight from "../../requests/useGetScaleWeight";

interface TareWeightProps {
  siteId: string,
  scaleId: string,
  requestedAmount: number;
  tareWeight: Weight;
  tareInLb: number;
  newTare: (value: string | number, unit: Units) => void;
}

const DisplayTareWeight = (props: TareWeightProps) => {
  const { _ } = useLingui();
  const refreshTimeMs = 2000;
  const getTare = useGetScaleWeight(
    props.siteId,
    props.scaleId,
    {
      onSuccess: tare => props.newTare(tare.value, tare.unit),
      onError: () => props.newTare(0, Units.lb),
    });

  useInterval(() => getTare.query(), refreshTimeMs, false);

  return <Row>
    <div className="col-7 mt-5">
      <label className="h5" htmlFor='tareWeight'><Trans>Tare Weight</Trans></label>
      <div className="input-group input-group-sm">
        <input
          id='tareWeight'
          type='text'
          className='form-control form-control-sm p-2 text-end'
          value={props.tareWeight.value}
          readOnly />
        <div className="input-group-append input-group-text">
          {_(unitsToDisplay(props.tareWeight.unit))}
        </div>
      </div>
    </div>
    <div className="col-5 mt-5 text-end p-1">
      <div>
        <h5><Trans>Projected Gross Weight</Trans></h5>
      </div>
      <div>
        <label>
          {_(msg`${props.requestedAmount + props.tareInLb} lb`)}
        </label>
      </div>
    </div>
  </Row>
}

export default DisplayTareWeight;
