type FooterProps = {
  nextButton: React.ReactNode;
  backButton?: React.ReactNode;
}

const FooterButton = ({ button }: { button: React.ReactNode }) => 
  <div className="col-auto mx-2 mx-sm-4 mb-4 mt-0" style={{ flexShrink: 1, overflow: "hidden", whiteSpace: "nowrap", borderRadius: ".3125rem" }}>
    {button}
  </div>

const Footer = ({ nextButton, backButton }: FooterProps) => 
  <footer className="row justify-content-between gx-0" style={{ flexWrap: "nowrap" }}>
    <FooterButton button={backButton} />
    <FooterButton button={nextButton} />
  </footer>

export default Footer;
