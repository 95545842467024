import { Units } from "./units";
import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";

export const unitsToDisplay = (units: Units) : MessageDescriptor => {
  switch (units) {
    case Units.lb: return msg`lb`;
    case Units.oz: return msg`oz`;
    case Units.kg: return msg`kg`;
    case Units.ton: return msg`ton`;
    case Units.t: return msg`t`;

    default: return msg`An unknown error occurred`;
  }
}