import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";

const useCancelStagedOrder = (options: RequestOptions) =>
  useUserRequest<void>({
    method: HttpMethod.POST,
    path: "/api/users/me/cancelStagedOrder"
  }, options);

export default useCancelStagedOrder;
