import { QueryOptions } from "../common/lib/fetch/useQuery";
import useUserQuery from "../common/lib/fetch/useUserQuery";
import { Units } from "../common/units/units";
import { UserFacingMessageCode } from "../common/userFacingMessages/userFacingMessageCodes";
import { StagedOrder } from "../pages/StageOrderFlow/StageOrderTypes";

export enum UserStates {
  ConfigureLoad = 'ConfigureLoad',
  CheckIn = 'CheckIn',
  TareWeight = 'TareWeight',
  BayDirections = 'BayDirections',
  GrossWeight = 'GrossWeight',
  RejectedGross = 'RejectedGross',
  Signature = 'Signature',
  TicketInstructions = 'TicketInstructions',
}

export enum OpenStatus {
  Open = 'Open',
  Closed = 'Closed',
  ClosingSoon = 'ClosingSoon',
}

type UserAlert = {
  messageCode: UserFacingMessageCode,
  parameters?: string[]
}

export type UserState =
  | {
    state: UserStates.ConfigureLoad,
    alert?: UserAlert
  }
  | {
    state: UserStates.CheckIn
    siteId: string,
    stagedOrder: StagedOrder,
    openStatus: OpenStatus,
    closeTime?: string,
    alert?: UserAlert
  }
  | {
    state: UserStates.TareWeight,
    siteId: string,
    stagedOrder: StagedOrder,
    alert?: UserAlert
  }
  | {
    state: UserStates.BayDirections,
    siteId: string,
    stagedOrder: StagedOrder,
    bayName?: string,
    alert?: UserAlert
  }
  | {
    state: UserStates.GrossWeight,
    siteId: string,
    stagedOrder: StagedOrder,
    tareValue: string,
    tareUnit: Units,
    alert?: UserAlert
  }
  | {
    state: UserStates.RejectedGross,
    siteId: string,
    stagedOrder: StagedOrder,
    officePhoneNumber?: string,
    alert?: UserAlert
  }
  | {
    state: UserStates.Signature,
    stagedOrder: StagedOrder,
    siteId: string,
    alert?: UserAlert
  }
  | {
    state: UserStates.TicketInstructions,
    siteId: string,
    stagedOrder: StagedOrder,
    emailTickets: boolean,
    userEmail: string
    alert?: UserAlert
  }

const useUserState = (options?: QueryOptions<UserState>) =>
  useUserQuery<UserState>({ path: "/api/users/me/state" }, options);

export default useUserState;
