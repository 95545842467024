import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";
import { User } from "../userAccess/UserContext";

export enum ScaleUsage {
  None = "None",
  Tare = "Tare",
  TareAndGross = "TareAndGross",
}

export interface BayScaleSettingsDto {
  id: string;
  name: string;
  scaleUsage: ScaleUsage;
}
export interface UpdateBayScaleSettingsDto extends Omit<BayScaleSettingsDto, "name">{};

export default function useUpdateScaleSettings(user: User, options?: RequestOptions) {
  return useUserRequest<UpdateBayScaleSettingsDto[]>({
    path: `/api/sites/${user.selectedSite!.id}/scaleSettings`,
    method: HttpMethod.PUT
  }, options)
}