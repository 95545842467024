import { HttpMethod, RequestOptions } from "../common/lib/fetch";
import useUserOrKioskRequest from "../common/lib/fetch/useUserOrKioskRequest";
import { StagedOrder } from "../pages/StageOrderFlow/StageOrderTypes";

export type Compartment = {
  weight: number;
}

export type AnsweredQuestion = {
  id: string;
  answers: string[];
}

export interface CreateStagedOrder {
  carrierNumber?: string,
  transportNumber?: string,
  driverNumber?: string,
  driverPassword?: string,
  destinationId?: string,
  shipToName?: string,
  orderNumber: string,
  compartments: Compartment[],
  answeredQuestions: AnsweredQuestion[],
  kioskId?: string,
}

const useCreateStagedOrder = (siteId: string, options: RequestOptions<StagedOrder>) =>
  useUserOrKioskRequest<CreateStagedOrder, StagedOrder>({
    path: `/api/sites/${siteId}/stagedOrders`,
    method: HttpMethod.POST
  }, options);

export default useCreateStagedOrder;
