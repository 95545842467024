import useQuery, { QueryOptions } from "../common/lib/fetch/useQuery";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface Order {
  id: string;
  number: string;
  customer: string;
  products: string[];
  destination?: string;
  requested: string;
  doNotBlend: boolean;
  bays: string[];
}

export const useGetOrders = (siteId: string, options?: QueryOptions<Order[]>) => {
  const kaEmployee = useKaEmployee();
  return useQuery<Order[]>({
    headers: kaEmployee.fetchHeaders,
    path: `/api/developer/sites/${siteId}/orders`,
  }, options);
}