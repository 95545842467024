import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { BusinessHours } from "../common/BusinessHours";
import Button from "../common/Button";
import Container from "../common/Container";
import { Hours } from "../requests/dtos/Hours";
import { OpenStatus } from "../requests/useGetStagingSettings";
import { Role } from "../userAccess/kiosk/KioskContext";

export type KioskStartProps = {
  role: Role;
  openStatus: OpenStatus;
  hours?: Hours[];
  timeZone?: string;
  toInbound: () => void;
  toOutbound: () => void;
}

const BlockButton = ({ onClick, disabled, text }: { onClick: () => void, disabled?: boolean, text: string }) => {
  return <Button className="py-10 w-35" onClick={onClick} disabled={disabled}>
    <blockquote className="blockquote my-7 blockquote-light">
      <h1 className="text-light">{text}</h1>
    </blockquote>
  </Button>
}

export const KioskStart = (props: KioskStartProps) => {
  const { _ } = useLingui();

  const showClosed = props.openStatus !== OpenStatus.Open;

  const inboundText = props.role === Role.Both ? "Inbound" : "Start";
  const outboundText = props.role === Role.Both ? "Outbound" : "Start";

  const inboundAllowed = props.openStatus === OpenStatus.ClosedShutOffAccess
    || props.openStatus === OpenStatus.OutboundOnlyShutOffAccess;

  return <Container title="Welcome">
    {
      showClosed &&
        <div className='text-center mt-2'>
          <h1 className="text-danger">{_(msg`Closed`)}</h1>
        </div>
    }
    <div className={`d-flex justify-content-around ${showClosed ? 'mt-3' : 'mt-7'}`}>
      {props.role !== Role.Outbound &&
        <BlockButton
          onClick={props.toInbound}
          text={inboundText}
          disabled={inboundAllowed} />}
      {props.role !== Role.Inbound &&
        <BlockButton
          onClick={props.toOutbound}
          text={outboundText}
          disabled={props.openStatus === OpenStatus.ClosedShutOffAccess}
        />}
    </div>
    <div className="mx-7">
      <BusinessHours hours={props.hours} timeZone={props.timeZone} />
    </div>
  </Container>
}
