import { HttpMethod, RequestOptions, useRequest } from "../common/lib/fetch";
import { Role } from "../userAccess/kiosk/KioskContext";

type AuthorizeKiosk = {
  secret: string;
}

type Response = {
  token: string;
  siteId: string;
  scaleId?: string;
  role: Role;
}

const useKioskAuthorize = (kioskId: string, options?: RequestOptions<Response>) =>
  useRequest<AuthorizeKiosk, Response>({
    path: `/api/kiosks/${kioskId}/auth`,
    method: HttpMethod.POST,
    headers: () => Promise.resolve(new Headers())
  }, options);

export default useKioskAuthorize;
