import { useState } from "react";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../requests/useGetSiteSettings";
import useUpdateCustomerDestinationSettings, { DestinationOptions } from "../../requests/useUpdateCustomerDestinationSettings";

const CustomerDestination = () => {
  const displayMessage = useDisplayMessage();

  const useGetCustomerDestinationSettings = useGetSiteSettings<{ destinationSetting: DestinationOptions }>({
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  return <QueryView
    query={useGetCustomerDestinationSettings}
    renderData={({ destinationSetting }) => <>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <CustomerDestinationForm
        initialSetting={destinationSetting} />
    </>
    }
  />
}

interface CheckboxProps {
  id: string,
  onChange: (checked: boolean) => void,
  checked: boolean,
  disabled?: boolean,
  text: string,
}

const Checkbox = (props: CheckboxProps) => {
  return <div className="form-check">
    <input
      {...props}
      type="checkbox"
      className="form-check-input"
      onChange={ev => props.onChange(ev.target.checked)} />
    <label className="form-check-label" htmlFor={props.id} >{props.text}</label>
  </div>;
}

interface DestinationFormProps {
  initialSetting: DestinationOptions,
}

const CustomerDestinationForm = ({ initialSetting }: DestinationFormProps) => {
  const [setting, setSetting] = useState(initialSetting);
  const displayMessage = useDisplayMessage();

  const updateCustomerDestinationSettings = useUpdateCustomerDestinationSettings({
    onSuccess: () => displayMessage.success("Customer Destination settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const showDestinationChecked = (checked: boolean) =>
    setSetting(checked ? DestinationOptions.ShowDestination : DestinationOptions.None);

  const allowChangeChecked = (checked: boolean) =>
    setSetting(checked ? DestinationOptions.AllowDestinationChange : DestinationOptions.ShowDestination);

  const requireDestinationChecked = (checked: boolean) =>
    setSetting(checked ? 
      setting === DestinationOptions.AllowEnteredShipTo ? 
        DestinationOptions.AllowEnteredShipToAndRequireDestination : DestinationOptions.AllowDestinationChangeAndRequireDestination : 
      setting === DestinationOptions.AllowEnteredShipToAndRequireDestination ? 
        DestinationOptions.AllowEnteredShipTo : DestinationOptions.AllowDestinationChange);

  const allowShipToChecked = (checked: boolean) =>
    setSetting(checked ? 
      setting === DestinationOptions.AllowDestinationChangeAndRequireDestination ?
        DestinationOptions.AllowEnteredShipToAndRequireDestination : DestinationOptions.AllowEnteredShipTo :
      setting === DestinationOptions.AllowEnteredShipToAndRequireDestination ?
        DestinationOptions.AllowDestinationChangeAndRequireDestination : DestinationOptions.AllowDestinationChange);

  return <>
    {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
    <form className="m-auto w-50">
      <h2>Customer Destination</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <Checkbox
            id="ShowCustomerDestination"
            onChange={showDestinationChecked}
            checked={setting !== DestinationOptions.None}
            text="Show Customer Destination"
          />
          <Checkbox
            id="AllowDestinationChange"
            onChange={allowChangeChecked}
            checked={setting !== DestinationOptions.None && setting !== DestinationOptions.ShowDestination}
            disabled={setting === DestinationOptions.None}
            text="Allow drivers to select a Customer Destination"
          />
          <div className="ms-5">
            <Checkbox
              id="RequireDestination"
              onChange={requireDestinationChecked}
              checked={setting === DestinationOptions.AllowDestinationChangeAndRequireDestination || 
                setting === DestinationOptions.AllowEnteredShipToAndRequireDestination}
              disabled={setting === DestinationOptions.None || setting === DestinationOptions.ShowDestination}
              text="Require drivers to select a Customer Destination"
            />
            <Checkbox
              id="AllowEnteredShip"
              onChange={allowShipToChecked}
              checked={setting === DestinationOptions.AllowEnteredShipTo || 
                setting === DestinationOptions.AllowEnteredShipToAndRequireDestination}
              disabled={setting === DestinationOptions.None || setting === DestinationOptions.ShowDestination}
              text="Allow drivers to enter a new Customer Destination"
            />
          </div>
        </div>
      </div>
    </form>
    <div className="align-self-end">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateCustomerDestinationSettings.isLoading}
        onClick={() => updateCustomerDestinationSettings.request({ setting: setting })}>
        Save
      </SpinnerButton>
    </div>
  </>
}

export { CustomerDestination };
