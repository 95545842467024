import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from "reactstrap";

export default function KaModal(props: ModalProps) {
  return <Modal {...props}>
    <ModalHeader>
      {props.title}
    </ModalHeader>
    <ModalBody>
      {props.body}
    </ModalBody>
    <ModalFooter>
      {props.footer}
    </ModalFooter>
  </Modal>
}