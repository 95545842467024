import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";
import { useUser } from "../userAccess/useUser";

export const useRemoveStagedOrder = (stagedOrderId: string, options?: RequestOptions) => {
  const user = useUser();

  return useUserRequest({
    method: HttpMethod.POST,
    path: `/api/sites/${user.selectedSite?.id}/stagedOrders/${stagedOrderId}/remove`
  }, options);
}
