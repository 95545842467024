import { Path, useForm } from "react-hook-form";
import { FormText, FormCheckbox } from "../../common/forms";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import { CarrierDriverInfo } from "../../requests/useGetStagingSettings";
import useAuthenticateForSite, { AuthenticateCarrierDriver } from "../../requests/useAuthenticateForSite";
import Container from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import SwitchToMobileQRCode from "../../common/SwitchToMobileQRCode";
import useIsAnonymous from "../../common/lib/hooks/useIsAnonymous";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { driverFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";

export interface CarrierDriverProps {
  siteId: string;
  enableRememberMe: boolean;
  savedCarrierDriverInfo?: CarrierDriverInfo;
  requireCarrierNumber: boolean;
  requireTransportNumber: boolean;
  requireDriverNumber: boolean;
  requireDriverPassword: boolean;
  carrierDriverEntered: (carrierDriverInfo: CarrierDriverInfo) => void;
}

const CarrierDriver = (props: CarrierDriverProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();

  const displayMessage = useDisplayMessage();
  const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } =
    useForm<AuthenticateCarrierDriver>({
      defaultValues: {
        carrierNumber: props.savedCarrierDriverInfo?.carrierNumber ?? '',
        transportNumber: props.savedCarrierDriverInfo?.transportNumber ?? '',
        driverNumber: props.savedCarrierDriverInfo?.driverNumber ?? '',
        driverPassword: props.savedCarrierDriverInfo?.driverPassword ?? '',
        remember: !!props.savedCarrierDriverInfo,
      }
    });

  const anyFieldBlank = (props.requireCarrierNumber && !watch().carrierNumber) ||
    (props.requireTransportNumber && !watch().transportNumber) ||
    (props.requireDriverNumber && !watch().driverNumber) ||
    (props.requireDriverPassword && !watch().driverPassword);

  const useAuthenticateForSiteRequest = useAuthenticateForSite(props.siteId, {
    onSuccess: () => {
      const { remember: _, ...carrierDriver } = getValues();
      props.carrierDriverEntered(carrierDriver);
    },
    onError: err => {
      displayMessage.fail(_(driverFacingErrorMessage(err)));
    }
  });

  const handleAuthorize = (carrierDriverInfo: AuthenticateCarrierDriver) => {
    useAuthenticateForSiteRequest.request(carrierDriverInfo);
  }

  const formProps = (name: Path<AuthenticateCarrierDriver>) => ({
    name,
    setValue,
    register,
    errors,
  })

  return <Container title={_(msg`Carrier/Driver Information`)}>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />

    {isAnonymous && <SwitchToMobileQRCode />}
    <form className="m-auto pt-3 w-75">
      <div className="row">
        <div className="col-lg">
          {props.requireCarrierNumber && <FormText autoComplete={!isAnonymous} {...formProps("carrierNumber")}
            displayName={_(msg`Carrier Number`)}
            placeholder={_(msg`Enter carrier number`)}
            options={{
              required: _(msg`Carrier Number is required`),
              validate: {
                length: v => v.length < 51 || _(msg`Carrier Number cannot be longer than 50 characters`)
              }
            }}
          />}
          {props.requireTransportNumber && <FormText autoComplete={!isAnonymous} {...formProps("transportNumber")}
            displayName={_(msg`Transport Number`)}
            placeholder={_(msg`Enter transport number`)}
            options={{
              required: _(msg`Transport Number is required`),
              validate: {
                length: v => v.length < 51 || _(msg`Transport Number cannot be longer than 50 characters`)
              }
            }}
          />}
        </div>
        <div className="col-lg">
          {props.requireDriverNumber && <FormText autoComplete={!isAnonymous} {...formProps("driverNumber")}
            displayName={_(msg`Driver Number`)}
            placeholder={_(msg`Enter driver number`)}
            options={{
              required: _(msg`Driver Number is required`),
              validate: {
                length: v => v.length < 51 || _(msg`Driver Number cannot be longer than 50 characters`)
              }
            }}
          />}
          {props.requireDriverPassword && <FormText autoComplete={!isAnonymous} {...formProps("driverPassword")}
            displayName={_(msg`Driver Password`)}
            placeholder={_(msg`Enter driver password`)}
            options={{
              required: _(msg`Driver Password is required`),
              validate: {
                length: v => v.length < 51 || _(msg`Driver Password cannot be longer than 50 characters`)
              }
            }}
          />}
          {props.enableRememberMe && <div className="row justify-content-between">
            <div className="col-auto">
              <FormCheckbox {...formProps("remember")} label={_(msg`Remember for this site`)} />
            </div>
          </div>}
        </div>
      </div>
      </form>
    <Footer nextButton={
      <FooterButton
        disabled={anyFieldBlank}
        spinning={useAuthenticateForSiteRequest.isLoading}
        onClick={handleSubmit(handleAuthorize)}>
        <Trans>Next</Trans>
      </FooterButton>
    } />
  </Container>
}

export default CarrierDriver;
