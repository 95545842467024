import ContainerTextHeader from '../common/ContainerTextHeader';
import { useForm, SubmitHandler } from "react-hook-form";
import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import { HttpMethod } from '../common/lib/fetch/useFetch';
import KaAlert from '../common/lib/KaAlert';
import { useState } from 'react';
import SelectSearch, { SelectOption } from '../common/forms/SelectSearch';
import QueryView from '../common/lib/QueryView';
import useUserQuery from '../common/lib/fetch/useUserQuery';
import useUserRequest from '../common/lib/fetch/useUserRequest';
import { NavLink } from 'react-router-dom';
import { adminFacingErrorMessage } from '../common/userFacingMessages/userFacingMessages';

const AddSite = () => {
  const displayMessage = useDisplayMessage();
  interface Site {
    city: string,
    state: string,
    RequireDriverNumber: boolean,
    RequireDriverPassword: boolean,
    RequireCarrierNumber: boolean,
    RequireTransportNumber: boolean,
    ShowCustomerDestination: boolean,
    AllowOrderDestinationChanges: boolean
  };
  interface Business {
    id: string;
    name: string;
    locations: Array<Site>;
  }
  const [business, setBusiness] = useState<undefined | Business>(undefined);
  const createLocationRequest = useUserRequest<Site>({
    path: '/api/businesses/' + business?.id + '/Sites/addSite',
    method: HttpMethod.POST
  }, {
    onSuccess: () => {
      displayMessage.success("Site created successfully");
      reset();
    },
    onError: (err) => {displayMessage.fail("Failed to create site: " + adminFacingErrorMessage(err));}
  });
  const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<Site>();
  const onSubmit: SubmitHandler<Site> = (data) => createLocationRequest.request(data);
  const disableFormInputs = (business == undefined);
  const useBusinessesQuery = useUserQuery<Business[]>({
    path: "/api/businesses/asAdmin"
  }, {
    onError: (err) => { displayMessage.fail("Error: " + adminFacingErrorMessage(err))}
  });
  const requireDriverNumber = watch("RequireDriverNumber");
  if (!requireDriverNumber) { setValue("RequireDriverPassword", false) }
  const showCustomerDestination = watch("ShowCustomerDestination");
  if (!showCustomerDestination) { setValue("AllowOrderDestinationChanges", false) }
  return (
    <ContainerTextHeader message="Create Site">
      <QueryView
        query={useBusinessesQuery}
        renderData={businesses => <>
          {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
          <div className="mb-3">
            <h2 className="mt-4">Enter Site Information:</h2>
            <div className="mt-3 mb-3">
              <SelectSearch
                id='business'
                placeholder='Select a business...'
                options={businesses.map<SelectOption>(b => ({ value: b.id, name: b.name }))}
                onChange={bId => setBusiness(businesses.find(b => b.id === bId))}
              />
            </div>
            <form>
              <fieldset disabled={disableFormInputs}>
                <input className="form-control" placeholder="Site City" {...register("city", { required: true })} />
                {errors.city && <p className="text-danger">Site City is required</p>}
                <input className="form-control mt-3" placeholder="Site State" {...register("state", { required: true })} />
                {errors.state && <p className="text-danger">Site State is required</p>}
                <div className="mt-4">
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="RequireDriverNumber" className="form-check-input" {...register("RequireDriverNumber") }/>
                    <label className="form-check-label" htmlFor="RequireDriverNumber">Require Driver Number</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="RequireDriverPassword" className="form-check-input" disabled={!requireDriverNumber} {...register("RequireDriverPassword") } />
                    <label className="form-check-label" htmlFor="RequireDriverPassword">Require Driver Password</label>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="RequireCarrierNumber" className="form-check-input" {...register("RequireCarrierNumber") }/>
                    <label className="form-check-label" htmlFor="RequireCarrierNumber">Require Carrier Number</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="RequireTransportNumber" className="form-check-input" {...register("RequireTransportNumber") }/>
                    <label className="form-check-label" htmlFor="RequireTransportNumber">Require Transport Number</label>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="ShowCustomerDestination" className="form-check-input" {...register("ShowCustomerDestination")}/>
                    <label className="form-check-label" htmlFor="ShowCustomerDestination">Show Customer Destination</label> 
                  </div>
                  <div className="form-check form-check-inline">
                    <input type="checkbox" id="AllowOrderDestinationChanges" className="form-check-input" disabled={!showCustomerDestination} {...register("AllowOrderDestinationChanges") } />
                    <label className="form-check-label" htmlFor="AllowOrderDestinationChanges">Allow Order Destination Changes</label>
                  </div>
                </div>
                <div className="text-right mt-5 position-relative">
                  <button className="btn btn-primary position-absolute top-0 end-0 w-25" onClick={handleSubmit(onSubmit)} >
                    Create Site
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
          <NavLink to='/addSiteAdmins'>
            Add Site Administrators
          </NavLink>
      </> } />
    </ContainerTextHeader> 
  );
}

export default AddSite;