import { HttpMethod } from "../common/lib/fetch/useFetch";
import useRequest, { RequestOptions } from "../common/lib/fetch/useRequest";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export const useRemoveStagedOrder = (siteId: string, stagedOrderId: string, options?: RequestOptions) => {
  const kaEmployee = useKaEmployee();
  return useRequest<string>({
    headers: kaEmployee.fetchHeaders,
    method: HttpMethod.POST,
    path: `/api/developer/sites/${siteId}/removeStagedOrder/${stagedOrderId}`
  }, options);
}
