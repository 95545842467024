import { useContext } from 'react';
import { KioskContext, KioskState } from '../../../userAccess/kiosk/KioskContext';
import { UserContext, UserState } from '../../../userAccess/UserContext';
import useQuery, { Query, QueryOptions } from './useQuery';
import { UserQuerySpec } from './useUserQuery';

export default function useUserOrKioskQuery<Resp>(spec: UserQuerySpec, options?: QueryOptions<Resp>): Query<Resp> {
  const userStatus = useContext(UserContext);
  const kioskStatus = useContext(KioskContext);

  let headers: (() => Promise<Headers>) | null = null;

  if (kioskStatus.state === KioskState.SignedIn) {
    headers = () => Promise.resolve<Headers>(
      new Headers({ 'Authorization': `Bearer ${kioskStatus.token}` }));
  }

  if (!headers && userStatus.state === UserState.SignedIn) {
    headers = userStatus.fetchHeaders;
  }

  if (!headers) {
    throw new Error("useUserOrKioskQuery hook can only be used inside of " +
      "components that ensure a user or kiosk is signed in");
  }

  return useQuery<Resp>({
    path: spec.path,
    headers,
  }, options);
}