import { useEffect, useState } from 'react';
import { getFullNameFromAlias } from './utilities';

interface InputPredictProps {
  dictionary: Array<string>;
  value: string;
  onChange: (value: string, match: boolean) => void;
  onBlur: () => void;
  placeholder: string;
  error?: string;
  disabled?: boolean;
}

const MIN_NUM_CHARACTERS = 3;

// Inspired from: https://github.com/KhaledSakr/react-inline-predict
const InputPredict = (props: InputPredictProps) => {
  const [index, setIndex] = useState(0);
  const [suggestions, setSuggestions] = useState<Array<string>>([]);

  const compareNames = (original: string, newValue: string, newValueWithAliasesReplaced: string) =>
    original.toLowerCase() === newValue.toLowerCase() || original.toLowerCase() === newValueWithAliasesReplaced.toLowerCase();

  useEffect(() => {
    if (!props.value) {
      setSuggestions([]);
    }
  }, [props.value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.code === 'Tab' || e.code === 'NumpadEnter') {
      e.preventDefault();
      let val = suggestions[index];
      props.onChange(val, true);
    }
    else if (e.code === 'ArrowUp' && index > 0) {
      setIndex(index - 1);
    }
    else if (e.code === 'ArrowDown' && index < (props.dictionary.length - 1)) {
      setIndex(index + 1);
    }
  }

  const handleBlurCapture = () => {
    setSuggestions([]);
    props.onBlur();
  }

  const handleFocus = () => {
    handleInputChange(props.value);
  }

  const handleSuggestionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    let val = suggestions[index];
    props.onChange(val, true);
  }

  const handleInputChange = (newValue: string) => {
    let newSuggestions: string[] = [];
    let match = false;

    if (newValue.trim().length >= MIN_NUM_CHARACTERS) {
      let newValueWithAliasesReplaced = getFullNameFromAlias(newValue);

      let regex = new RegExp('^' + newValue, 'i');
      let regexWithAliasesReplaced = new RegExp('^' + newValueWithAliasesReplaced, 'i');
      newSuggestions = props.dictionary
        .filter(word => (regex.test(word) || regexWithAliasesReplaced.test(word)));

      match = newSuggestions
        .some(suggestion => (compareNames(suggestion, newValue, newValueWithAliasesReplaced)));
    }

    if (newSuggestions.length === 0) {
      newSuggestions = [''];
    }

    props.onChange(newValue, match);
    setSuggestions(newSuggestions);
    setIndex(0);
  }

  return <>
    <div className="has-validation">
      <input
        type="text"
        className={`form-control ${props.error && 'is-invalid'}`}
        onChange={(ev) => handleInputChange(ev.target.value)}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        disabled={props.disabled ?? false}
        onBlurCapture={handleBlurCapture}
        value={props.value} />
      {suggestions.length !== 0 && suggestions[index] !== '' && !(compareNames(suggestions[index], props.value, getFullNameFromAlias(props.value))) && <div
        className="form-control input-predict-suggestion text-secondary border shadow p-2 position-absolute"
        onMouseDown={handleSuggestionClick}>
        {suggestions[index] ?? ''}
      </div>}
    </div>
    {
      props.error &&
      <span className="invalid-feedback" style={{ display: 'inline' }}>
        {props.error}
      </span>
    }
  </>
}

export default InputPredict;
