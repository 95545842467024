import {
  AccountInfo, Configuration, InteractionRequiredAuthError,
  InteractionStatus, RedirectRequest
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useReducer } from 'react';
import useHandleForgotPassword from './useHandleForgotPassword';

export interface MsalConfiguration {
  pcaConfig: Configuration,
  signInRequest: RedirectRequest,
  forgotPasswordRequest: RedirectRequest,
}

export interface TokenResult {
  token: string,
  uniqueId: string,
  idTokenClaims: object,
}

export interface MsalUser {
  id: string,
  email: string,
  expirationTime: number;
  getToken: () => Promise<string>;
  signOut: (options?: { postLogoutUrl?: string }) => void;
}

export type MsalStatus = {
  busy: boolean;
  signIn: () => void;
  user: MsalUser | null;
}

enum Step {
  NotAuthenticated,
  GetToken,
  UserDone,
}

enum ActionType {
  GetUserProfile,
  ProfileAcquired,
  AcquireTokenComplete,
}

type State =
  | { step: Step.NotAuthenticated }
  | { step: Step.GetToken, msalAccount: AccountInfo }
  | { step: Step.UserDone, id: string, email: string, msalAccount: AccountInfo }

type Action =
  | { type: ActionType.GetUserProfile, msalAccount: AccountInfo }
  | { type: ActionType.ProfileAcquired, id: string, email: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.GetUserProfile:
      return { step: Step.GetToken, msalAccount: action.msalAccount }

    case ActionType.ProfileAcquired:
      if (state.step === Step.GetToken) {
        return { ...action, ...state, step: Step.UserDone }
      }
      break;
  }

  return state;
}

const initializeState = (account?: AccountInfo): State =>
  account
    ? { step: Step.GetToken, msalAccount: account }
    : { step: Step.NotAuthenticated };

const isMatchingAccount = (account: AccountInfo, authority?: string) =>
  (account.idTokenClaims as any).tfp.toLocaleLowerCase() ===
  authority?.split('/').pop()?.toLocaleLowerCase();

export const useMsalAuth = (config: MsalConfiguration): MsalStatus => {
  const { instance, accounts, inProgress } = useMsal();
  const account = accounts.find(a => isMatchingAccount(a, config.signInRequest.authority));
  const [state, dispatch] = useReducer(reducer, account, initializeState);

  useHandleForgotPassword(instance, config);

  const getEmail = (idTokenClaims: any): string => {
    return idTokenClaims.email ?? idTokenClaims.emails[0];
  }

  const acquireTokenForAccount = useCallback((account: AccountInfo) => {
    const request = { ...config.signInRequest, account, forceRefresh: false };
    return instance.acquireTokenSilent(request)
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
        throw error;
      });
  }, [config, instance]);

  useEffect(() => {
    if (state.step === Step.NotAuthenticated && account) {
      dispatch({ type: ActionType.GetUserProfile, msalAccount: account });
    }
  }, [state, account]);

  useEffect(() => {
    if (state.step !== Step.GetToken) return;

    acquireTokenForAccount(state.msalAccount)
      .then(r => dispatch({
        type: ActionType.ProfileAcquired,
        id: r.uniqueId,
        email: getEmail(r.idTokenClaims),
      }));
  }, [state, acquireTokenForAccount]);

  const signIn = async () => {
    try {
      await instance.ssoSilent(config.signInRequest);
    } catch (err) {
      instance.loginRedirect(config.signInRequest)
    }
  }

  return {
    signIn: signIn,
    busy: inProgress !== InteractionStatus.None || state.step === Step.GetToken,
    user: state.step === Step.UserDone
      ? {
        id: state.id,
        email: state.email,
        expirationTime: account!.idTokenClaims!.exp!,
        getToken: async () => (await acquireTokenForAccount(state.msalAccount)).accessToken,
        signOut: (options) => instance.logout({
          account: account,
          postLogoutRedirectUri: options?.postLogoutUrl
        }),
      }
      : null,
  }
}