import useQuery, { QueryOptions } from "../common/lib/fetch/useQuery";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface StagedOrder {
  id: string;
  orderNumber: string;
  userEmail: string;
  customer: string;
  products: string[];
  step: string;
  bay: string | null;
  compartments: string[];
  tare: string | null;
  tareUnit: string | null;
}

export const useGetStagedOrders = (siteId: string, options?: QueryOptions<StagedOrder[]>) => {
  const kaEmployee = useKaEmployee();
  return useQuery<StagedOrder[]>({
    headers: kaEmployee.fetchHeaders,
    path: `/api/developer/sites/${siteId}/stagedOrders`,
  }, options);
}
