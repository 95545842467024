import { useUserOrKioskRequest } from "../common/lib/fetch";
import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";

const useAcceptGrossWeight = (stagedOrderId: string, options: RequestOptions) =>
  useUserOrKioskRequest<void>({
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/grossWeight`,
  }, options);

export default useAcceptGrossWeight;
