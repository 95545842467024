import React from 'react'

export type UserStatus =
  | { state: UserState.Loading }
  | {
    state: UserState.SignedOut;
    signIn: () => void;
  }
  | User;

export enum UserState {
  Loading,
  SignedOut,
  SignedIn,
}

export interface User {
  state: UserState.SignedIn;
  id: string;
  email: string;
  adminSites: AdminSite[];
  selectedSite: AdminSite | null;
  setSelectedSite: (site: AdminSite | null) => void;
  fetchHeaders: () => Promise<Headers>;
  signOut: (postLogoutUrl?: string) => void;
}

export interface AdminSite {
  id: string,
  city: string,
  state: string
}

export const UserContext = React.createContext<UserStatus>({ state: UserState.Loading });
