import React, { ComponentType, createContext } from 'react';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory()
const reactPlugin = new ReactPlugin();
const clickPlugin = new ClickAnalyticsPlugin();

class MonitoringService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
        extensions: [reactPlugin, clickPlugin as any],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
          [clickPlugin.identifier]: {
            autoCapture: process.env.REACT_APP_APPINSIGHTS_LOG_CLICK_EVENTS === 'true',
            dataTags: { useDefaultContentNameOrId: true }
          }
        }
      }
    });

    this.appInsights.loadAppInsights();
    this.logEvent = this.logEvent.bind(this);
    this.logMetric = this.logMetric.bind(this);
    this.logException = this.logException.bind(this);
    this.logTrace = this.logTrace.bind(this);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number, properties?: { [key: string]: any }) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel, properties: properties });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}

const monitoring: MonitoringService = new MonitoringService();

const MonitoringContext = createContext(monitoring);

type Props = {
  children: React.ReactNode
}

const MonitoringContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <MonitoringContext.Provider value={monitoring} >
      {children}
    </MonitoringContext.Provider>
  );
};

function withMonitoring<T>(Component: ComponentType<T>) {
  return withAITracking<T>(reactPlugin, Component);
}

export { withMonitoring, MonitoringContext, MonitoringContextProvider };
