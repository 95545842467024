import useAcknowledgeAlert from "../requests/useAcknowledgeAlert";
import { PopUpMessageView } from "./PopUpMessageView";

interface AlertMessageViewProps {
  message?: string,
  messageAcknowledged: () => void,
  children: React.ReactNode,
}

export const AlertMessageView = (props: AlertMessageViewProps): JSX.Element => { 
  const acknowledgeAlertRequest = useAcknowledgeAlert({
    onSuccess: _ => props.messageAcknowledged()
  });

  return <PopUpMessageView
    {...props}
    isLoading={acknowledgeAlertRequest.isLoading}
    messageAcknowledged={acknowledgeAlertRequest.request} />
}