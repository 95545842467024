import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export interface UpdateLoadConfigurationSettings {
  allowExceedOrderAmount: boolean;
}

export default function useUpdateLoadConfigurationSettings(options?: RequestOptions) {
  const user = useUser();

  return useUserRequest<UpdateLoadConfigurationSettings>({
    path: `/api/sites/${user.selectedSite!.id}/loadConfigurationSettings`,
    method: HttpMethod.PUT
  }, options);
}
