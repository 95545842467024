import React, { Children, useEffect, useState } from 'react'

// Source derived from https://github.com/zeroseven/react-screen-orientation

type Orientations = 'portrait' | 'landscape';

interface OrientationProps {
  children: React.ReactNode;
  orientation: Orientations,
  alwaysRender: boolean;
}

const Orientation = ({ children }: OrientationProps) => <>{children}</>;

type OrientationLock = ((orientation: OrientationLockType) => boolean) | undefined;
type OrientationPromiseLock = (((orientation: OrientationLockType) => Promise<void>) | undefined);

const lockOrientationUniversal =
  (window.screen.orientation?.lock) as OrientationPromiseLock ||
  (window.screen as any).mozLockOrientation as (OrientationLock) ||
  (window.screen as any).msLockOrientation as (OrientationLock);

const lock = (orientation: OrientationLockType) => {
  const { screen } = window
  if (screen.orientation && typeof screen.orientation.lock === 'function') {
    return screen.orientation.lock(orientation).catch(err => console.log(err));
  } else if (lockOrientationUniversal) {
    return new Promise((resolve, reject) => {
      if (lockOrientationUniversal(orientation)) {
        resolve(orientation)
      } else {
        reject()
      }
    })
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock
export enum LockOrientations {
  PortraitPrimary = 'portrait-primary',
  PortraitSecondary = 'portrait-secondary',
  LandscapePrimary = 'landscape-primary',
  LandscapeSecondary = 'landscape-secondary',
  Portrait = 'portrait',
  Landscape = 'landscape',
}

type OrientationAngle = -90 | 0 | 90 | 180;

interface DeviceOrientationProps {
  className?: string;
  children: React.ReactElement<OrientationProps>[];
  lockOrientation: LockOrientations;
}

interface State {
  orientation: Orientations;
  type: 'primary' | 'secondary';
  angle: OrientationAngle;
}

const DeviceOrientation = (props: DeviceOrientationProps) => {
  const [state, setState] = useState<State | null>(null);

  useEffect(() => {
    onOrientationChange();
  }, []);

  useEffect(() => {
    lock(props.lockOrientation);
  });

  useEffect(() => {
    if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
      window.screen.orientation.addEventListener('change', onOrientationChange)
    } else if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', onOrientationChange)
    }

    return () => {
      if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
        window.screen.orientation.removeEventListener('change', onOrientationChange)
      } else if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', onOrientationChange)
      }
    };
  });

  const onOrientationChange = () => {
    var orientation = 'portrait';
    var type = 'primary';
    var angle = 0;
    if (window.orientation) {
      angle = window.orientation as OrientationAngle;
      orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
    }

    if (window.screen.orientation) {
      [orientation, type] = window.screen.orientation.type.split('-');
      angle = window.screen.orientation.angle;
    }

    setState({
      orientation: orientation as Orientations,
      type: type as ('primary' | 'secondary'),
      angle: angle as OrientationAngle,
    })
  }

  return <>
    {
      Children.map(props.children, (child) => {
        const { props } = child
        if (props.alwaysRender || props.orientation === state?.orientation) {
          return child
        }
      })
    }
  </>
}

export default DeviceOrientation;
export { Orientation };
