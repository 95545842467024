import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Toast } from "./Toast";

interface PopUpMessageViewProps {
  message?: string,
  messageAcknowledged: () => void,
  children: React.ReactNode,
  isLoading?: boolean
}

export const PopUpMessageView = (props: PopUpMessageViewProps): JSX.Element => {
  const { _ } = useLingui();
  const hasMessage = props.message != null;

  return <>
    {hasMessage && <Toast
      headerText={_(msg`Alert`)}
      bodyText={props.message!}
      okClicked={props.messageAcknowledged}
      loading={props.isLoading ?? false} />}
    <div style={hasMessage ? { pointerEvents: "none", opacity: "0.1" } : {}}>
      {props.children}
    </div>
  </>
}