import SignaturePad from 'react-signature-canvas';
import { useRef } from "react";
import Row from '../../common/Row';
import SpinnerButton from '../../common/SpinnerButton';
import { Trans } from '@lingui/macro';

export interface KaSignatureProps {
  signatureImage: string;
  setSignatureImage: (sigImgData: string) => void;
  submit: () => void;
  submitSignatureIsLoading: boolean;
}

const KaSignaturePad = (props: KaSignatureProps) => {
  const sigPad = useRef<SignaturePad | null>();

  const onEnd = () => {
    props.setSignatureImage(sigPad.current?.getTrimmedCanvas().toDataURL()!);
  }

  const onClear = () => {
    props.setSignatureImage("");
    sigPad.current?.clear();
  }

  return <>
    <SignaturePad
      onEnd={onEnd}
      canvasProps={{ style: { position: "relative", width: "100%", height: "100%", border: "solid" } }}
      ref={ref => sigPad.current = ref} />
    <Row className="justify-content-center">
      <SpinnerButton
        className="col-4"
        onClick={onClear}
        spinning={props.submitSignatureIsLoading}>
        <Trans>Clear Signature</Trans>
      </SpinnerButton>
      <div className="col-1" />
      <SpinnerButton
        className="col-4"
        onClick={props.submit}
        spinning={props.submitSignatureIsLoading}
        disabled={!props.signatureImage}>
        <Trans>Submit Signature</Trans>
      </SpinnerButton>
    </Row>
  </>
}

export default KaSignaturePad;
