import { HttpMethod, useUserRequest } from "../common/lib/fetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";

const useCancelLoad = (stagedOrderId: string, options?: RequestOptions) => { 
  return useUserRequest<void>({
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/cancelLoad`
  }, options);
}

export default useCancelLoad;