import { useRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface AddSiteAdmin {
  email: string,
}

export const useAddSiteAdmin = (siteId: string, options?: RequestOptions) => {
  const kaEmployee = useKaEmployee();

  return useRequest<AddSiteAdmin>({
    headers: kaEmployee.fetchHeaders,
    method: HttpMethod.POST,
    path: `/api/sites/${siteId}/admins`
  }, options);
}
