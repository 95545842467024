import { Query } from './fetch/useQuery';
import { Error } from './fetch/useFetch';


export interface QueryViewProps<Resp> {
  query: Query<Resp>;
  renderData: (data: Resp) => JSX.Element;
  renderLoading?: () => JSX.Element;
  renderError?: (err: Error) => JSX.Element;
}

const DefaultError = (e: Error) => {
  return <div className="alert alert-danger">{e.message}</div>
}

const DefaultLoader = () => {
  return <label>loading</label>
}

export default function QueryView<Resp>(props: QueryViewProps<Resp>) {
  const loading = props.renderLoading ?? DefaultLoader;
  const error = props.renderError ?? DefaultError;

  if (props.query.isLoading || props.query.isIdle) return loading();
  if (props.query.isError) return error(props.query.error as Error);

  return props.renderData(props.query.data as Resp);
}
