import React from 'react';
import { NavLink } from 'react-router-dom';
import { AdminSite } from '../userAccess/UserContext';
import { KAEmployeeNavMenu } from './KAEmployeeNavMenu';

const KAEmployeeNavSideMenu = () => {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-sm navbar-sidebar navbar-vertical navbar-light bg-white">
        <div className="navbar-brand-wrapper" style={{ height: 'auto' }}>
          <div className="d-flex align-items-center">
            <a className="navbar-brand" href="/" target="_self" rel="noreferrer" aria-label="LP_Logo">
              <img className="navbar-brand-logo" src="/images/lp_logo.png" alt="LogoPass" width='100' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
            </a>
          </div>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sideNavbar" aria-controls="sideNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-default">
            <i className="bi-list"></i>
          </span>
          <span className="navbar-toggler-toggled">
            <i className="bi-x"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse navbar-sidebar-aside-body" id="sideNavbar">
          <ul className="navbar-nav nav nav-vertical nav-tabs nav-tabs-borderless">
            <li className="nav-item">
              <NavLink className="nav-link px-3" to="/dashboard">
                Order Dashboard
              </NavLink>
              <NavLink className="nav-link px-3" to="/sites">
                Manage Sites
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="position-absolute bottom-0 start-0">
          <a className="navbar-brand" href="https://www.kahlerautomation.com" target="_blank" rel="noreferrer" aria-label="KA_Logo">
            <img className="navbar-brand-logo m-2" src="/images/logo.png" alt="Logo" width='100' style={{ minWidth: '15rem', maxWidth: '15rem' }} />
          </a>
        </div>
      </nav>
    </div>
  );
}


const KAEmployeeLayout: React.FC<{ children?: React.ReactNode, sites: AdminSite[] }> = ({ children, sites }) => {
  return (
    <>
      <KAEmployeeNavMenu sites={sites} />
      <main id="content" role="main" className="navbar-sidebar-aside-sm">
        <KAEmployeeNavSideMenu />
        <div className="navbar-sidebar-aside-content content-space-t-3 content-space-b-2 px-lg-5 px-xl-10 height-100 d-flex flex-column">
          {children}
        </div>
      </main>
    </>
  );
}

export { KAEmployeeLayout };
