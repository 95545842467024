import { useContext } from 'react';
import { KaEmployee, KaEmployeeContext, KaEmployeeState } from './KaEmployeeContext';

export const useKaEmployee = (): KaEmployee => {
  const userStatus = useContext(KaEmployeeContext);

  if (userStatus.state === KaEmployeeState.SignedIn) {
    return userStatus as KaEmployee;
  }

  throw new Error(`useKaEmployee hook can only be used inside 
    of components contained inside an KaEmployeeTemplate component`);
}