import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { MultiSelectSearch, SelectSearch } from "../../../common/forms";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import QueryView from "../../../common/lib/QueryView";
import Row from "../../../common/Row";
import SpinnerButton from "../../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import { Product } from "../../../requests/useGetProducts";
import useGetSiteSettings from "../../../requests/useGetSiteSettings";
import { GeneralSettingsDto } from "../../../requests/useUpdateGeneralSettings";
import useUpdateLoadQuestions, { LoadQuestion, QuestionType } from "../../../requests/useUpdateLoadQuestions";
import Question, { ALL_PRODUCT_ID } from "./Question";

type LoadQuestionSettings = {
  loadQuestions: LoadQuestion[];
  products: Product[];
  generalSettings: GeneralSettingsDto;
}

type EditProps = { initialQuestions: LoadQuestion[], products: Product[], officePhoneNumber?: string };

const Edit = ({ initialQuestions, products, officePhoneNumber }: EditProps) => {
  const displayMessage = useDisplayMessage();
  const [questions, setQuestions] = useState<LoadQuestion[]>(initialQuestions);
  const [questionIndex, setQuestionIndex] = useState<number>(0);

  const [previewAnswers, setPreviewAnswers] = useState<string[][]>([[], [], []]);
  const [otherPreviewAnswer, setOtherPreviewAnswer] = useState<string>("");

  const updateLoadQuestions = useUpdateLoadQuestions({
    onSuccess: () => displayMessage.success("Load Questions updated"),
    onError: err => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const update = () => {
    questions.forEach(q => {
      q.productIds = q.productIds.filter(p => p !== ALL_PRODUCT_ID);

      q.answers = q.answers.filter(a => a.text.trim().length > 0);
    })

    updateLoadQuestions.request(questions)
  }

  const updateQuestion = (index: number, update: Partial<LoadQuestion>) => {
    const oldValue = questions[index];
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...oldValue, ...update };
    if (update.type !== oldValue.type) {
      updatePreviewAnswers(index, []);
    }
    setQuestions(updatedQuestions);
  }

  const updatePreviewAnswers = (index: number, newValues: string[]) => {
    let newPreviewAnswers = [...previewAnswers];
    newPreviewAnswers[index] = newValues;
    setPreviewAnswers(newPreviewAnswers);
  }

  const selectOptions = () => {
    const selectOptions = questions[questionIndex].answers.map(a => ({ value: a.text })) ?? [];
    if (questions[questionIndex].allowPlainTextAnswer) {
      selectOptions.push({ value: "Other..." });
    }
    return selectOptions;
  }

  const displayAnswerCount = (loadQuestion: LoadQuestion) => 
    loadQuestion.minNumOfAnswers !== loadQuestion.maxNumOfAnswers
      ? loadQuestion.minNumOfAnswers + "-" + loadQuestion.maxNumOfAnswers
      : loadQuestion.maxNumOfAnswers;

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <div className="m-auto w-85">
      <Row>
        <h2 className="col-4">Load Questions</h2>
        <p>
          Administrators have the capability to set up to three questions. These questions serve to
          determine whether a truck complies with the loading criteria for a designated facility. In
          the event of a driver answering a question incorrectly, they will be presented with a
          custom-defined message indicating the error. The message is configured below in
          the "Incorrect Response to Driver" text box.
        </p>
      </Row>
      <Nav tabs className="d-flex justify-content-center border-bottom-0 mb-2">
        <NavItem>
          <NavLink active={questionIndex === 0} onClick={() => setQuestionIndex(0)}>
            Question 1
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={questionIndex === 1} onClick={() => setQuestionIndex(1)}>
            Question 2
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={questionIndex === 2} onClick={() => setQuestionIndex(2)}>
            Question 3
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={questionIndex} >
        <Row className="border border-dark rounded p-0">
          <div className="col-8 my-4 px-4">
            <Question
              updateQuestion={(u) => updateQuestion(questionIndex, u)}
              loadQuestion={questions[questionIndex]}
              products={products}
              officePhoneNumber={officePhoneNumber} />
          </div>
          <div className="col-4 border-start border-dark">
            <div className="m-auto px-4 text-center" style={{ wordBreak: "break-word" }}>
              <Row>
                <h3 className="p-3 text-muted">Preview</h3>
              </Row>
              <Row>
                <h3 className="pb-5 text-primary">
                  {!questions[questionIndex].question ? "(Your question text here)" : questions[questionIndex].question}
                </h3>
              </Row>
              <Row>
                <div>
                  {(questions[questionIndex].type === QuestionType.MultipleChoice &&
                    questions[questionIndex].maxNumOfAnswers > 1) ?
                    <>
                      <div className="mb-1 text-start">{`(Select ${displayAnswerCount(questions[questionIndex])} answers)`}</div>
                      <MultiSelectSearch
                        id={questions[questionIndex].id}
                        className="p-0"
                        onChange={v => updatePreviewAnswers(questionIndex, v)}
                        singleMultiple={true}
                        hideSelected={false}
                        hideSearch={questions[questionIndex].answers.length < 5}
                        values={previewAnswers[questionIndex]}
                        placeholder={"No answers selected"}
                        options={selectOptions()}
                        testId={questions[questionIndex].id}
                      />
                    </>
                    : (questions[questionIndex].type !== QuestionType.UserDefined) ?
                      <>
                        <SelectSearch
                          id={questions[questionIndex].id}
                          className="p-0"
                          onChange={v => updatePreviewAnswers(questionIndex, [v])}
                          hideSearch={questions[questionIndex].answers.length < 5}
                          placeholder={"No answer selected"}
                          options={selectOptions()}
                        />
                        {questions[questionIndex].allowPlainTextAnswer &&
                          previewAnswers[questionIndex][0] === "Other..." &&
                          <input
                            className="form-control mt-2"
                            type="text"
                            placeholder={"Enter other answer..."}
                            onChange={ev => setOtherPreviewAnswer(ev.target.value)}
                            value={otherPreviewAnswer}
                            autoComplete={"on"} />
                        }
                      </>
                      : <input
                        className="form-control"
                        type="text"
                        placeholder={"Enter answer..."}
                        onChange={ev => updatePreviewAnswers(questionIndex, [ev.target.value])}
                        value={previewAnswers[questionIndex]}
                        autoComplete={"on"}
                      />
                  }
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </TabContent>
    </div>
    <div className="align-self-end mt-3">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateLoadQuestions.isLoading}
        onClick={update}>
        Save
      </SpinnerButton>
    </div>
  </>
}

const LoadQuestions = () => {
  const useSettings = useGetSiteSettings<LoadQuestionSettings>();

  return <QueryView
    query={useSettings}
    renderData={settings => {
      return <Edit
        initialQuestions={settings.loadQuestions}
        products={settings.products.sort((a, b) => a.name.localeCompare(b.name))}
        officePhoneNumber={settings.generalSettings.officePhoneNumber}
      />
    }}
  />
};

export default LoadQuestions;
