import { useState } from "react";
import useUserQuery from "../../common/lib/fetch/useUserQuery";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useUpdateScaleSettings, { ScaleUsage, BayScaleSettingsDto, UpdateBayScaleSettingsDto } from "../../requests/useUpdateScaleSettings";
import { User } from "../../userAccess/UserContext";
import { useUser } from "../../userAccess/useUser";

export interface BayScaleSettings {
  bays: BayScaleSettingsDto[];
}

const ScaleAutomation = () => {
  const displayMessage = useDisplayMessage();
  const user = useUser(); 

  const useGetScaleSettings = useUserQuery<BayScaleSettings>({
    path: `/api/sites/${user.selectedSite!.id}/settings`
  }, {
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  return <QueryView
    query={useGetScaleSettings}
    renderData={response => <>
      {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
      <ScaleAutomationForm 
        initialSettings={response.bays}
        user={user} />
    </>
  }/>
}

interface ScaleAutomationFormProps {
  initialSettings: BayScaleSettingsDto[],
  user: User
}

const ScaleAutomationForm = (props: ScaleAutomationFormProps) => {
  const displayMessage = useDisplayMessage();
  const [bayScaleSettings, setBayScaleSettings] = useState<BayScaleSettingsDto[]>(props.initialSettings);

  const updateScaleSettings = useUpdateScaleSettings(props.user, {
    onSuccess: () => displayMessage.success("Scale settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const toggleTare = (ev: React.ChangeEvent<HTMLInputElement>, bayIndex: number) => {
    const updatedBayScaleSettings = JSON.parse(JSON.stringify(bayScaleSettings));
    updatedBayScaleSettings[bayIndex].scaleUsage = ev.target.checked ? ScaleUsage.Tare : ScaleUsage.None;
    setBayScaleSettings(updatedBayScaleSettings);
  }

  const toggleGross = (ev: React.ChangeEvent<HTMLInputElement>, bayIndex: number) => {
    const updatedBayScaleSettings = JSON.parse(JSON.stringify(bayScaleSettings));
    updatedBayScaleSettings[bayIndex].scaleUsage = ev.target.checked ? ScaleUsage.TareAndGross : ScaleUsage.Tare;
    setBayScaleSettings(updatedBayScaleSettings);
  }

  const save = () => {
    const bayScaleSettingsUpdates = bayScaleSettings.map<UpdateBayScaleSettingsDto>(b => ({
      id: b.id,
      scaleUsage: b.scaleUsage,
    }));

    updateScaleSettings.request(bayScaleSettingsUpdates);
  }

  if (!bayScaleSettings.length) {
    return <>
      <div className="alert alert-dismissible alert-danger" role="alert">
        Current site does not have any bays 
      </div>
    </>
  } else { 
    return <>
      <div className="m-auto w-85">
        {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
        <h2>Scale Automation</h2>
        <div className="border border-dark rounded w-75">
          <table className="table">
            <thead>
              <tr>
                <th className="w-50">Bay</th>
                <th>Check Inbound Weight</th>
                <th>Check Outbound Weight</th>
              </tr>
            </thead>
            <tbody>
              {bayScaleSettings.map((bay, i) => 
                <tr key={bay.id}>
                  <td>{bay.name}</td>
                  <td>
                    <div className="m-auto">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`cbx-tare${bay.name}`}
                        onChange={(ev) => toggleTare(ev, i)}
                        checked={bay.scaleUsage !== ScaleUsage.None} />
                    </div>
                  </td>
                  <td>
                    <div className="align-center">
                      <input
                        disabled={bay.scaleUsage === ScaleUsage.None}
                        type="checkbox"
                        className="form-check-input"
                        id={`cbx-gross${bay.name}`}
                        onChange={(ev) => toggleGross(ev, i)}
                        checked={bay.scaleUsage === ScaleUsage.TareAndGross} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="align-self-end">
        <SpinnerButton 
          className="btn btn-primary mx-4 mb-4 px-5 py-3" 
          spinning={updateScaleSettings.isLoading}
          onClick={save}>
          Save
        </SpinnerButton>
      </div>
    </>
  }
}

export { ScaleAutomation };
