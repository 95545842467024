import { HttpMethod } from "../common/lib/fetch/useFetch";
import useRequest, { RequestOptions } from "../common/lib/fetch/useRequest";

export interface CodeScanned {
  scaleId?: string,
}

const useCodeScanned = (stagedOrderId: string, options?: RequestOptions) =>
  useRequest<CodeScanned>({
    headers: () => Promise.resolve(new Headers()),
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/codeScanned`
  }, options);

export default useCodeScanned;
