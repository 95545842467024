import Button from "./Button";
import SpinnerButton from "./SpinnerButton";

export type FooterButtonProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  spinning?: boolean;
}

const FooterButton = (props: FooterButtonProps) =>
  props.spinning
    ? <SpinnerButton className='px-5 py-3' spinning={props.spinning} {...props}>
      {props.children}
    </SpinnerButton>
    : <Button className='px-5 py-3' disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </Button>


export default FooterButton;
