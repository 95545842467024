import { useContext } from 'react';
import { KioskContext, KioskState } from '../../../userAccess/kiosk/KioskContext';
import { UserContext, UserState } from '../../../userAccess/UserContext';
import useRequest, { Request, RequestOptions } from './useRequest';
import { UserRequestSpec } from './useUserRequest';

const useUserOrKioskRequest = <Req, Resp = void>(spec: UserRequestSpec, options?: RequestOptions<Resp>): Request<Resp, Req> => {
  const userStatus = useContext(UserContext);
  const kioskStatus = useContext(KioskContext);

  let headers: (() => Promise<Headers>) | null = null;

  if (kioskStatus.state === KioskState.SignedIn) {
    headers = () => Promise.resolve<Headers>(
      new Headers({ 'Authorization': `Bearer ${kioskStatus.token}` }));
  }

  if (!headers && userStatus.state === UserState.SignedIn) {
    headers = userStatus.fetchHeaders;
  }

  if (!headers) {
    throw new Error("useUserOrKioskRequest hook can only be used inside of " +
      "components that ensure a user or kiosk is signed in");
  }

  return useRequest<Req, Resp>({
    path: spec.path,
    method: spec.method,
    headers,
  }, options);
}

export default useUserOrKioskRequest;
