import { useUserRequest, RequestOptions, HttpMethod } from "../common/lib/fetch";
import { useUser } from "../userAccess/useUser";

export enum BusinessTimeZone {
  Eastern = "Eastern",
  Central = "Central",
  Mountain = "Mountain",
  Pacific = "Pacific",
}

export type BusinessHours = {
  sundayOpenTime?: string; sundayCloseTime?: string;
  mondayOpenTime?: string; mondayCloseTime?: string;
  tuesdayOpenTime?: string; tuesdayCloseTime?: string;
  wednesdayOpenTime?: string; wednesdayCloseTime?: string;
  thursdayOpenTime?: string; thursdayCloseTime?: string;
  fridayOpenTime?: string; fridayCloseTime?: string;
  saturdayOpenTime?: string; saturdayCloseTime?: string;
  timeZone: BusinessTimeZone;
  shutOffAccessWhenClosed: boolean;
}

const useUpdateBusinessHours = (options?: RequestOptions) => {
  const user = useUser();

  return useUserRequest({
    path: `/api/sites/${user.selectedSite!.id}/businessHoursSettings`,
    method: HttpMethod.POST
  }, options);
}

export default useUpdateBusinessHours;