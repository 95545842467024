import { SubmitHandler, useForm } from "react-hook-form";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../requests/useGetSiteSettings";
import useUpdateGeneralSettings, { GeneralSettingsDto, ReleaseOption } from "../../requests/useUpdateGeneralSettings";

interface SettingsDto {
  generalSettings: GeneralSettingsDto;
}

const GeneralSettings = () => {
  const displayMessage = useDisplayMessage();

  const useGetSettings = useGetSiteSettings<SettingsDto>({
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  return <QueryView
    query={useGetSettings}
    renderData={response => <>
        <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
        <SettingsForm initialSettings={response.generalSettings} />
      </>
    } />
}

interface SettingsFormProps {
  initialSettings: GeneralSettingsDto,
}

interface GeneralSettingsForm {
  releaseUnclaimed: boolean,
  unclaimedHours?: number,
  completedDays: number,
  officePhoneNumber?: string,
  shutOffAccess?: boolean,
  emailTickets?: boolean,
}

function isWholeNumber(num: number | undefined): boolean {
  return (num ?? 0) % 1 === 0;
}

function isValidPhone(number: string | undefined): boolean {
  return !number || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/.test(number);
}

const SettingsForm = (props: SettingsFormProps) => {
  const displayMessage = useDisplayMessage();

  const updateReleaseSettings = useUpdateGeneralSettings({
    onSuccess: () => displayMessage.success("Settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm<GeneralSettingsForm>({
    defaultValues: {
      releaseUnclaimed: props.initialSettings.unclaimedOption === ReleaseOption.AfterTime,
      ...props.initialSettings
    },
  });

  const onSubmit: SubmitHandler<GeneralSettingsForm> = (formValue) => updateReleaseSettings.request({
    unclaimedOption: formValue.releaseUnclaimed ? ReleaseOption.AfterTime : ReleaseOption.DoNotRelease,
    unclaimedHours: formValue.releaseUnclaimed ? formValue.unclaimedHours : undefined,
    completedDays: formValue.completedDays,
    officePhoneNumber: !!formValue.officePhoneNumber ? formValue.officePhoneNumber : undefined,
    shutOffAccess: formValue.shutOffAccess,
    emailTickets: formValue.emailTickets,
  });

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form className="m-auto w-50">
      <h2>General</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <div className="form-check">
            <input type="checkbox" id="CheckReleaseUnclaimed" className="form-check-input" {...register("releaseUnclaimed")} />
            <label className="form-check-label" htmlFor="CheckReleaseUnclaimed">Release unclaimed barcodes/orders after:</label>
          </div>
          <div className="input-group input-group-sm px-9">
            <input
              type="number"
              className={`form-control ${!!errors?.unclaimedHours && 'is-invalid'}`}
              {...register("unclaimedHours", {
                disabled: !watch("releaseUnclaimed"),
                valueAsNumber: true,
                required: true,
                max: 8760,
                min: 1,
                validate: isWholeNumber
              })} />
            <span className="input-group-text">hours</span>
            <div className="invalid-feedback">
              Must be a whole number between 1 and 8760
            </div>
          </div>
        </div>
      </div>
      <div className="border border-dark rounded mt-3">
        <div className="m-3">
          <label className="form-check-label">Retain completed order records for:</label>
          <div className="input-group input-group-sm px-9">
            <input
              type="number"
              className={`form-control ${!!errors?.completedDays && 'is-invalid'}`}
              {...register("completedDays", {
                valueAsNumber: true,
                required: true,
                max: 365,
                min: 1,
                validate: isWholeNumber
              })} />
            <span className="input-group-text">days</span>
            <div className="invalid-feedback">
              Must be a whole number between 0 and 365
            </div>
          </div>
        </div>
      </div>
      <div className="border border-dark rounded mt-3">
        <div className="m-3">
          <label htmlFor="tbxPhone" className="form-check-label">Office Phone Number:</label>
          <div className="input-group input-group-sm px-9">
            <input
              id="tbxPhone"
              type="tel"
              placeholder="###-###-####"
              className={`form-control ${!!errors?.officePhoneNumber && 'is-invalid'}`}
              {...register("officePhoneNumber", { validate: isValidPhone })} />
            <div className="invalid-feedback">
              Must be a valid phone number
            </div>
          </div>
        </div>
      </div>
      <div className="border border-dark rounded mt-3">
        <div className="m-3">
          <div className="form-check">
            <input
              type="checkbox"
              id="shutOffAccess"
              className="form-check-input"
              {...register("shutOffAccess")} />
            <label
              className="form-check-label"
              htmlFor="shutOffAccess">
              Shut off access to the site
            </label>
          </div>
        </div>
      </div>
      <div className="border border-dark rounded mt-3">
        <div className="m-3">
          <div className="form-check">
            <input
              type="checkbox"
              id="emailTickets"
              className="form-check-input"
              {...register("emailTickets")} />
            <label
              className="form-check-label"
              htmlFor="emailTickets">
              Email tickets
            </label>
          </div>
        </div>
      </div>
    </form>
    <div className="align-self-end">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateReleaseSettings.isLoading}
        onClick={handleSubmit(onSubmit)}>
        Save
      </SpinnerButton>
    </div>
  </>
}

export { GeneralSettings };
