import { useContext } from 'react';
import { MonitoringContext } from '../Monitoring';

const useLogEvent = (): (name: string, properties?: { [key: string]: any }) => void => {
  const monitoring = useContext(MonitoringContext);
  if (monitoring) return monitoring.logEvent;

  throw new Error("useLogEvent hook can only be used in components inside a MonitoringContextProvider");
}

const useLogMetric = (): (name: string, average: number, properties?: { [key: string]: any }) => void => {
  const monitoring = useContext(MonitoringContext);
  if (monitoring) return monitoring.logMetric;

  throw new Error("useLogMetric hook can only be used in components inside a MonitoringContextProvider");
}

const useLogException = (): (exception: Error, severityLevel?: number, properties?: { [key: string]: any }) => void => {
  const monitoring = useContext(MonitoringContext);
  if (monitoring) return monitoring.logException;

  throw new Error("useLogEvent hook can only be used in components inside a MonitoringContextProvider");
}

const useLogTrace = (): (message: string, properties?: { [key: string]: any }) => void => {
  const monitoring = useContext(MonitoringContext);
  if (monitoring) return monitoring.logTrace;

  throw new Error("useLogEvent hook can only be used in components inside a MonitoringContextProvider");
}

const useTraceID = (): string => {
  const monitoring = useContext(MonitoringContext);
  if (monitoring && monitoring.appInsights.context.telemetryTrace.traceID) {
    return monitoring.appInsights.context.telemetryTrace.traceID;
  }

  throw new Error("useTraceID hook can only be used in components inside a MonitoringContextProvider");
}

export { useLogEvent, useLogMetric, useLogException, useLogTrace, useTraceID };
