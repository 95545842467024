import { useContext } from "react";
import { KioskContext, KioskState } from "../../../userAccess/kiosk/KioskContext";

const useIsAnonymous = () => {
  const kiosk = useContext(KioskContext);
  const isAnonymous = kiosk.state !== KioskState.NotRegistered;

  return isAnonymous;
};

export default useIsAnonymous;