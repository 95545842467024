import { HttpMethod } from "../common/lib/fetch/useFetch";
import useRequest, { RequestOptions } from "../common/lib/fetch/useRequest";

const useArrivedAtBay = (stagedOrderId: string, bayId?: string, options?: RequestOptions) =>
  useRequest<void>({
    headers: () => Promise.resolve(new Headers()),
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/arrivedAtBay/${bayId}`
  }, options);

export default useArrivedAtBay;