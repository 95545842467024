import { useState } from "react";
import { Path, useForm, SubmitHandler } from "react-hook-form";
import Button from "../../common/Button";
import { FormText } from "../../common/forms";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from '../../common/lib/KaAlert';
import QueryView from "../../common/lib/QueryView";
import Row from "../../common/Row";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import { AddSite, useAddSite } from '../../requests/useAddSite';
import { AddSiteAdmin, useAddSiteAdmin } from "../../requests/useAddSiteAdmin";
import { useGetSites } from "../../requests/useGetSites";

type SiteAdminProps = {
  siteId: string;
  created: () => void;
  close: () => void;
}

const SiteAdminModal = ({ siteId, created, close }: SiteAdminProps) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<AddSiteAdmin>();
  const onSubmit: SubmitHandler<AddSiteAdmin> = (data) => addAdmin.request(data)
  const displayMessage = useDisplayMessage();

  const addAdmin = useAddSiteAdmin(siteId, {
    onSuccess: created,
    onError: (err) => displayMessage.fail("Failed to add admin: " + adminFacingErrorMessage(err))
  });

  const formProps = (name: Path<AddSiteAdmin>) => ({
    name,
    setValue,
    register,
    errors
  });

  return <>
    <div className="modal fade show d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Add New Admin</h1>
            <button type="button" className="btn-close" onClick={close} />
          </div>
          <div className="modal-body pb-0">
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <FormText {...formProps("email")}
              options={{
                required: 'email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }
              }}
            />
          </div>
          <div className="modal-footer mt-0">
            <button type="button"
              className="btn btn-white"
              onClick={close}>
              Close
            </button>
            <SpinnerButton
              className="btn btn-primary"
              spinning={addAdmin.isLoading}
              onClick={handleSubmit(onSubmit)}>
              Add Admin
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
  </>
}

const SiteModal = ({ created, close }: { created: () => void, close: () => void }) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<AddSite>();
  const onSubmit: SubmitHandler<AddSite> = (data) => createSiteRequest.request(data)
  const displayMessage = useDisplayMessage();

  const createSiteRequest = useAddSite({
    onSuccess: created,
    onError: (err) => displayMessage.fail("Failed to add site: " + adminFacingErrorMessage(err)),
  });

  const formProps = (name: Path<AddSite>) => ({
    name,
    setValue,
    register,
    errors
  });

  return <>
    <div className="modal fade show d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Add Site</h1>
            <button type="button" className="btn-close" onClick={close} />
          </div>
          <div className="modal-body pb-0">
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <FormText {...formProps("city")} options={{ required: 'City is required' }} />
            <FormText {...formProps("state")} options={{ required: 'State is required' }} />
            <FormText {...formProps("number")} options={{ required: 'Number is required' }} />
            <FormText {...formProps("serviceContractNumber")} options={{ required: 'Service Contract Number is required' }} />
            <FormText {...formProps("business")} options={{ required: 'Business is required' }} />
          </div>
          <div className="modal-footer mt-0">
            <button type="button"
              className="btn btn-white"
              onClick={close}>
              Close
            </button>
            <SpinnerButton
              className="btn btn-primary"
              spinning={createSiteRequest.isLoading}
              onClick={handleSubmit(onSubmit)}>
              Create Site
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
  </>
}

const SitesPage = () => {
  const getSites = useGetSites();
  const displayMessage = useDisplayMessage();
  const [showModal, setShowModal] = useState(false);
  const [adminSiteId, setAdminSiteId] = useState<string | undefined>(undefined);

  const siteCreated = () => {
    getSites.query();
    setShowModal(false);
    displayMessage.success("Site created");
  }

  const adminAdded = () => {
    getSites.query();
    setAdminSiteId(undefined);
    displayMessage.success("Admin added");
  }

  return <div className="mb-3">
    {showModal && <SiteModal created={siteCreated} close={() => setShowModal(false)} />}
    {adminSiteId &&
      <SiteAdminModal siteId={adminSiteId} created={adminAdded} close={() => setAdminSiteId(undefined)} />}
    <Row className="mb-4">
      <h2 className="col-10 mb-0 mt-1">Sites</h2>
      <Button
        onClick={() => setShowModal(true)}
        className="mt-0 col-2">
        Add Site
      </Button>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <QueryView
      query={getSites}
      renderData={(sites) =>
        <table className="table table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="col-2">Location</th>
              <th scope="col" className="col-2">Business</th>
              <th scope="col" className="col-1">Number</th>
              <th scope="col" className="col-2">Service Contract Number</th>
              <th scope="col" className="col-6">Administrators</th>
            </tr>
          </thead>
          <tbody>
            {
              sites.sort((a, b) => a.city.localeCompare(b.city)).map(s => {
                return <tr key={s.id}>
                  <td>{s.city + ", " + s.state}</td>
                  <td>{s.business}</td>
                  <td>{s.number}</td>
                  <td>{s.serviceContractNumber}</td>
                  <td>
                    <Row>
                      <div className="col-lg-10 col-6">
                        {s.admins.sort((a, b) => a.localeCompare(b)).map(email =>
                          <p key={email} className="mb-0 col-10">{email}</p>)}
                      </div>
                      <div className="col-lg-2 col-4">
                        <Button
                          className="btn-sm"
                          onClick={() => setAdminSiteId(s.id)}>
                          Add
                        </Button>
                      </div>
                    </Row>
                  </td>
                </tr>
              })}
          </tbody>
        </table>
      } />
  </div>
}

export default SitesPage;
