import { useContext } from 'react';
import { Kiosk, KioskContext, KioskState } from './KioskContext';

export const useKiosk = (): Kiosk => {
  const kioskStatus = useContext(KioskContext);

  if (kioskStatus.state === KioskState.SignedIn) {
    return kioskStatus as Kiosk;
  }

  throw new Error("useKiosk hook can only be used inside of components with a signed in kiosk");
}