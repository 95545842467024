import React, { useReducer } from "react";
import CarrierDriver from "./CarrierDriver";
import ConfigureLoad from "./ConfigureLoad";
import OrderNumber from "./OrderNumber";
import SelectDestination from "./SelectDestination";
import { CarrierDriverInfo, StagingSettings } from "../../requests/useGetStagingSettings";
import { Destination, Order, ShipTo, SODestination } from "../../UserFlow";
import useCreateStagedOrder, { AnsweredQuestion, Compartment, CreateStagedOrder } from "../../requests/useCreateStagedOrder";
import LoadQuestion from "./LoadQuestions";
import { FooterButtonProps } from "../../common/FooterButton";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import { StagedOrder } from "./StageOrderTypes";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { driverFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";

enum Step {
  CarrierDriver,
  OrderNumber,
  SelectDestination,
  ConfigureLoad,
  LoadQuestions,
  Done,
}

enum ActionType {
  Back,
  CarrierDriverEntered,
  OrderNumberEntered,
  DestinationSelected,
  CompartmentsConfigured,
  StagedOrderCreated,
  LoadQuestionsAnswered,
}

type WithKioskId = { kioskId?: string };
type WithSettings = { settings: StagingSettings };
type WithAll = WithSettings & WithKioskId & {
  carrierDriverInfo: CarrierDriverInfo,
  order: Order,
  destination?: SODestination,
  compartments: Compartment[]
};

type State =
  | { step: Step.CarrierDriver } & WithSettings & WithKioskId
  | { step: Step.OrderNumber, initialOrderNumber?: string } & Omit<WithAll, 'order' | 'compartments' | 'destination'>
  | { step: Step.SelectDestination, initialDestination?: SODestination } & Omit<WithAll, 'compartments' | 'destination'>
  | { step: Step.ConfigureLoad, initialCompartments?: Compartment[] } & Omit<WithAll, 'compartments'>
  | { step: Step.LoadQuestions } & WithAll;

type Action =
  | { type: ActionType.Back }
  | { type: ActionType.CarrierDriverEntered, carrierDriverInfo: CarrierDriverInfo }
  | { type: ActionType.OrderNumberEntered, order: Order }
  | { type: ActionType.DestinationSelected, destination?: SODestination }
  | { type: ActionType.CompartmentsConfigured, compartments: Compartment[], create: (createSo: CreateStagedOrder) => void }
  | { type: ActionType.LoadQuestionsAnswered, answeredQuestions: AnsweredQuestion[], create: (createSo: CreateStagedOrder) => void }

function reducer(state: State, action: Action): State {
  const showDestinations = state.settings.showCustomerDestination;

  switch (action.type) {
    case ActionType.Back:
      if (state.step === Step.SelectDestination || (state.step === Step.ConfigureLoad && !showDestinations)) {
        return { ...state, step: Step.OrderNumber, initialOrderNumber: state.order.number };
      } else if (state.step === Step.ConfigureLoad && showDestinations) {
        return { ...state, step: Step.SelectDestination, initialDestination: state.destination };
      } else if (state.step === Step.LoadQuestions) {
        return { ...state, step: Step.ConfigureLoad, initialCompartments: state.compartments }
      }
      break;

    case ActionType.CarrierDriverEntered:
      if (state.step === Step.CarrierDriver) {
        return { ...state, step: Step.OrderNumber, carrierDriverInfo: action.carrierDriverInfo };
      }
      break;

    case ActionType.OrderNumberEntered:
      if (state.step === Step.OrderNumber && showDestinations) {
        return { ...state, ...action, initialDestination: action.order.destination, step: Step.SelectDestination };
      } else if (state.step === Step.OrderNumber && !showDestinations) {
        return { ...state, ...action, destination: action.order.destination, step: Step.ConfigureLoad };
      }
      break;

    case ActionType.DestinationSelected:
      if (state.step === Step.SelectDestination) {
        return { ...state, destination: action.destination, step: Step.ConfigureLoad };
      }
      break;

    case ActionType.CompartmentsConfigured:
      if (state.step !== Step.ConfigureLoad) break;
      if (state.order.loadQuestions.length === 0) {
        action.create({
          ...state.carrierDriverInfo,
          orderNumber: state.order.number,
          destinationId: getDestinationId(state.destination),
          shipToName: getShipToName(state.destination),
          compartments: action.compartments,
          answeredQuestions: [],
          kioskId: state.kioskId
        });

        return state;
      } else {
        return { ...state, ...action, step: Step.LoadQuestions };
      }

    case ActionType.LoadQuestionsAnswered:
      if (state.step !== Step.LoadQuestions) break;
      action.create({
        ...state.carrierDriverInfo,
        orderNumber: state.order.number,
        destinationId: getDestinationId(state.destination),
        shipToName: getShipToName(state.destination),
        compartments: state.compartments,
        answeredQuestions: action.answeredQuestions,
        kioskId: state.kioskId
      });
      return state;
  }

  throw Error(`Invalid action received: ${JSON.stringify(action)} for state: ${JSON.stringify(state)}`);
}

const initializer = ({ settings, kioskId }: Props): State => {
  const common = {
    totalSteps: (settings.showCustomerDestination ? 3 : 2),
    settings,
  }

  if (settings.requireAuthentication) {
    return { ...common, step: Step.CarrierDriver, kioskId: kioskId };
  } else {
    return {
      ...common,
      step: Step.OrderNumber,
      carrierDriverInfo: {
        carrierNumber: '',
        driverNumber: '',
        driverPassword: '',
        transportNumber: '',
      },
      kioskId: kioskId
    };
  }
}

const isDestination = (dest?: SODestination): dest is Destination => !!dest && ("id" in dest);

const getShipToName = (destination?: SODestination) =>
  isDestination(destination) ? undefined : destination?.name;

const getDestinationId = (destination?: SODestination) =>
  !isDestination(destination) ? undefined : destination.id;

type Props = {
  siteId: string,
  kioskId?: string,
  enableRememberMe: boolean,
  settings: StagingSettings;
  stagedOrderCreated: (so: StagedOrder) => void;
  loadQuestionsAnswerRejected: () => void;
}

const StageOrderFlow: React.FC<Props> = (props) => {
  const { _ } = useLingui();
  const [state, dispatch] = useReducer(reducer, props, initializer);

  const createDisplay = useDisplayMessage();
  const createSoRequest = useCreateStagedOrder(props.siteId, {
    onSuccess: so => props.stagedOrderCreated(so),
    onError: err => createDisplay.fail(_(driverFacingErrorMessage(err))),
  });

  const carrierDriverEntered = (carrierDriverInfo: CarrierDriverInfo) =>
    dispatch({ type: ActionType.CarrierDriverEntered, carrierDriverInfo });

  const orderSelected = (order: Order) =>
    dispatch({ type: ActionType.OrderNumberEntered, order });

  const destinationSelected = (destination?: SODestination) =>
    dispatch({ type: ActionType.DestinationSelected, destination });

  const compartmentsConfigured = (compartments: Compartment[]) =>
    dispatch({ type: ActionType.CompartmentsConfigured, compartments, create: createSoRequest.request });

  const loadQuestionsAnswered = (answeredQuestions: AnsweredQuestion[]) =>
    dispatch({ type: ActionType.LoadQuestionsAnswered, answeredQuestions, create: createSoRequest.request });

  const backProps: FooterButtonProps = {
    children: _(msg`Back`),
    onClick: () => dispatch({ type: ActionType.Back })
  };

  const totalSteps = 5;

  switch (state.step) {
    case Step.CarrierDriver:
      return <CarrierDriver
        {...props.settings} 
        siteId={props.siteId}
        carrierDriverEntered={carrierDriverEntered}
        enableRememberMe={props.enableRememberMe} />

    case Step.OrderNumber:
      return <OrderNumber
        {...props.settings} 
        siteId={props.siteId}
        kioskId={props.kioskId}
        initialOrderNumber={state.initialOrderNumber}
        orderSelected={orderSelected}
        progress={{ totalSteps: totalSteps, currentStep: 1 }} />

    case Step.SelectDestination:
      return <SelectDestination
        {...props.settings} 
        order={state.order}
        initialDestination={state.initialDestination}
        destinationSelected={destinationSelected}
        backButton={backProps}
        progress={{ totalSteps: totalSteps, currentStep: 2 }} />

    case Step.ConfigureLoad:
      return <ConfigureLoad
        {...props.settings} 
        orderNumber={state.order.number}
        requested={state.order.requested}
        compartmentsConfigured={compartmentsConfigured}
        initialCompartments={state.initialCompartments}
        message={createDisplay.message}
        clearMessage={createDisplay.clear}
        backButton={backProps}
        nextButton={{
          children: state.order.loadQuestions.length > 0 ? _(msg`Next`) : _(msg`Complete`),
          spinning: createSoRequest.isLoading,
        }}
        progress={{ totalSteps: totalSteps, currentStep: 3 }} />

    case Step.LoadQuestions:
      return <LoadQuestion
        {...props.settings}
        loadQuestions={state.order.loadQuestions}
        loadQuestionsAnswered={loadQuestionsAnswered}
        loadQuestionsAnswerRejected={props.loadQuestionsAnswerRejected}
        message={createDisplay.message}
        clearMessage={createDisplay.clear}
        backButton={backProps}
        nextButton={{ spinning: createSoRequest.isLoading }}
        progress={{ totalSteps: totalSteps, currentStep: 4 }} />
  }
}

export default StageOrderFlow;
