import { HttpMethod, RequestOptions, useUserOrKioskRequest } from "../common/lib/fetch";

interface Request {
  data: string,
}

export interface SubmitSignatureResponse {
  emailTickets: boolean,
  userEmail?: string,
}

const useSubmitSignature = (stagedOrderId: string, options?: RequestOptions<SubmitSignatureResponse>) =>
  useUserOrKioskRequest<Request, SubmitSignatureResponse>({
    method: HttpMethod.POST,
    path: `/api/stagedOrders/${stagedOrderId}/submitSignature`
  }, options);

export default useSubmitSignature;
