import { Header, HeaderProps } from "../common/Header"

interface ContainerTextHeaderProps extends HeaderProps {
  message: string;
  children: React.ReactNode;
  devToolItems?: React.ReactNode;
}

const ContainerTextHeader = (props: ContainerTextHeaderProps) => {
  return <div className="height-100">
  <div className="height-15">
    <Header devToolItems={props.devToolItems}>
      <h1 className="w-100 text-white text-center top-50">
        {props.message}
      </h1>
      </Header>
    </div>
    <div className="d-flex flex-column height-85">
      {props.children}
    </div>
  </div>
}

export default ContainerTextHeader;
