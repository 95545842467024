import { useState } from "react";
import Button from "../../../common/Button";
import Confirm from "../../../common/Confirm";
import { Query } from "../../../common/lib/fetch";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import QueryView from "../../../common/lib/QueryView";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../../requests/useGetSiteSettings";
import useRemoveKiosk from "../../../requests/useRemoveKiosk";
import useUnregisterKiosk from "../../../requests/useUnregisterKiosk";
import { useUser } from "../../../userAccess/useUser";
import { CreateUpdateKioskModal } from "./CreateUpdateKioskModal";

interface KioskDto {
  id: string,
  role: string,
  name: string,
  scaleId?: string,
}

interface ScaleDto { 
  id: string,
  name: string,
}

export interface SiteSettingsDto {
  kiosks: Array<KioskDto>;
  scales: Array<ScaleDto>;
}

export const KioskManagement = () => {
  const displayMessage = useDisplayMessage();
  const getSiteSettings = useGetSiteSettings<SiteSettingsDto>();

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <QueryView
      query={getSiteSettings}
      renderData={response => <KioskManagementForm 
        displayMessageSuccess={(m) => displayMessage.success(m)}  
        displayMessageFail={(m) => displayMessage.fail(m)}  
        kiosks={response.kiosks} 
        scales={response.scales} 
        getSiteSettings={getSiteSettings} />}
    />
  </>
}

type Props = {
  displayMessageSuccess: (message: string) => void;
  displayMessageFail: (message: string) => void;
  kiosks: Array<KioskDto>;
  scales: Array<ScaleDto>;
  getSiteSettings: Query<SiteSettingsDto>;
}

const KioskManagementForm = (props: Props) => {
  const user = useUser();
  const [selectedKiosk, setSelectedKiosk] = useState<KioskDto | undefined>(undefined);
  const [showCreateUpdateKiosk, setShowCreateUpdateKiosk] = useState(false);
  const [showRemoveKiosk, setShowRemoveKiosk] = useState(false);
  const [showUnregisterKiosk, setShowUnregisterKiosk] = useState(false);

  const removeKioskRequest = useRemoveKiosk(user.selectedSite!.id, selectedKiosk?.id ?? "", {
    onSuccess: () => {
      setSelectedKiosk(undefined);
      props.getSiteSettings.query();
      props.displayMessageSuccess(`Successfully removed kiosk ${selectedKiosk?.name}`);
    },
    onError: (err) => {
      toggleRemoveKioskModal();
      props.displayMessageFail("Failed to remove kiosk: " + adminFacingErrorMessage(err));
    }
  });

  const unregisterKioskRequest = useUnregisterKiosk(user.selectedSite!.id, selectedKiosk?.id ?? "", {
    onSuccess: () => {
      props.displayMessageSuccess(`Successfully unregistered kiosk ${selectedKiosk?.name}`);
      setSelectedKiosk(undefined);
      props.getSiteSettings.query();
    },
    onError: (err) => {
      toggleUnregisterKioskModal();
      props.displayMessageFail("Failed to unregister kiosk: " + adminFacingErrorMessage(err));
    }
  });
  
  const toggleCreateUpdateKioskModal = (): void => {
    if (showCreateUpdateKiosk) setSelectedKiosk(undefined);
    setShowCreateUpdateKiosk(!showCreateUpdateKiosk);
  }

  const toggleUnregisterKioskModal = (): void => {
    if (showUnregisterKiosk) setSelectedKiosk(undefined);
    setShowUnregisterKiosk(!showUnregisterKiosk);
  }

  const toggleRemoveKioskModal = (): void => {
    if (showRemoveKiosk) setSelectedKiosk(undefined);
    setShowRemoveKiosk(!showRemoveKiosk);
  }

  const onCreateUpdateSuccess = (): void => {
    setSelectedKiosk(undefined);
    props.getSiteSettings.query();
  }

  const onEditClicked = (kiosk: KioskDto): void => { 
    setSelectedKiosk(kiosk);
    toggleCreateUpdateKioskModal();
  }

  const onUnregisterClicked = (kiosk: KioskDto): void => { 
    setSelectedKiosk(kiosk);
    toggleUnregisterKioskModal();
  }

  const onRemoveClicked = (kiosk: KioskDto): void => { 
    setSelectedKiosk(kiosk);
    toggleRemoveKioskModal();
  }

  const scaleOptions: Array<ScaleDto> = props.scales.filter(s => !props.kiosks.some(k => k.scaleId === s.id && selectedKiosk?.scaleId !== s.id));

  return <>
    {showCreateUpdateKiosk && <CreateUpdateKioskModal 
      show={showCreateUpdateKiosk} 
      kiosk={selectedKiosk} 
      scaleOptions={scaleOptions} 
      onSuccess={onCreateUpdateSuccess} 
      toggle={toggleCreateUpdateKioskModal}/>}
    {showUnregisterKiosk && <Confirm
      visible={showUnregisterKiosk}
      title={"Unregister Kiosk"}
      body={`Are you sure you want to unregister kiosk ${selectedKiosk?.name}?`}
      onConfirm={() => unregisterKioskRequest.request({})}
      onDismiss={toggleUnregisterKioskModal}/>}
    {showRemoveKiosk && <Confirm
      visible={showRemoveKiosk}
      title={"Remove Kiosk"}
      body={`Are you sure you want to remove kiosk ${selectedKiosk?.name}?`}
      onConfirm={() => removeKioskRequest.request({})}
      onDismiss={toggleRemoveKioskModal}/>}
    <form className="m-auto w-85">
      <h2>Kiosks</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <div className="row mb-2">
            <h5 className="col-2 my-auto w-50">
              Kiosk
            </h5>
            <h5 className="col-2 my-auto w-50">
              Scale
            </h5>
          </div>
          {props.kiosks != null && <div>
            {props.kiosks
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((p, i) =>
                <div key={p.id} className="row mb-2">
                  <label className="col-2 my-auto w-50">
                    {p.name}
                  </label>
                  <label className="col-2 my-auto w-25">
                    {p.scaleId === undefined ? "" : props.scales.find(s => s.id === p.scaleId)?.name}
                  </label>
                  <div className="col-2 my-auto w-25 dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item" type="button" onClick={() => onEditClicked(p)}>Edit</button>
                      <button className="dropdown-item" type="button" onClick={() => onUnregisterClicked(p)}>Unregister</button>
                      <button className="dropdown-item" type="button" onClick={() => onRemoveClicked(p)}>Remove</button>
                    </div>
                  </div>
                </div>
              )}
          </div>}
          <div className="row mt-3">
            <Button
              className="mx-auto w-25"
              onClick={toggleCreateUpdateKioskModal}>
              Add Kiosk
            </Button>
          </div>
        </div>
      </div>
    </form>
  </>
}
