interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> { }

const Button = (props: ButtonProps) => {
  return <button
    {...props}
    type="button"
    className={`btn btn-primary ${props.className}`}>
    {props.children}
  </ button>
}

export default Button;