import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";

export interface RegisterResponse {
  siteId: string,
  clientSecret: string,
}

const useRegisterSite = (siteId: string, options?: RequestOptions<RegisterResponse>) =>
  useUserRequest<void, RegisterResponse>({
    method: HttpMethod.POST,
    path: `/api/sites/${siteId}/register`
  }, options);

export default useRegisterSite;
