import { useReducer, useState } from "react";
import SafetyMessage from "./pages/SafetyMessage";
import SelectSite from "./pages/SelectSite";
import CheckIn from "./pages/CheckIn";
import TareWeight from "./pages/TareWeight/TareWeight";
import useUserState, { OpenStatus, UserState, UserStates } from "./requests/useUserStatus";
import GrossWeight from "./pages/GrossWeight";
import BayDirections from "./pages/BayDirections/BayDirections";
import { Weight } from "./common/units/useWeight";
import Signature from "./pages/Signature/Signature";
import TicketInstructions from "./pages/TicketInstructions";
import StageOrderFlow from "./pages/StageOrderFlow/StageOrderFlow";
import StagingSettingsQuery from "./common/StagingSettingsQuery";
import CancelStagedOrderButton from "./dev/CancelStagedOrderButton";
import useInterval from "./common/lib/hooks/useInterval";
import { AlertMessageView } from "./common/AlertMessageView";
import { StagedOrder } from "./pages/StageOrderFlow/StageOrderTypes";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import RejectedGross from "./pages/RejectedGross";
import { driverFacingMessage } from "./common/userFacingMessages/userFacingMessages";

enum Step {
  SafetyMessage,
  SelectSite,
  StageOrder,
  CheckIn,
  TareWeight,
  BayDirections,
  GrossWeight,
  RejectedGross,
  Signature,
  TicketInstructions,
}

enum ActionType {
  Back,
  InitialStateReceived,
  SiteSelected,
  StagedOrderCreated,
  LoadCancelled,
  LoadCompleted,
  CheckedIn,
  TareAccepted,
  DirectToBay,
  AtScale,
  GetSignature,
  GrossVerified,
  GrossRejected,
  SignatureSubmitted,
}

export interface Customer {
  id: string;
  name: string;
  destinations?: Array<Destination>;
}

export interface Destination {
  id: string;
  name: string;
  city?: string;
  state?: string;
}

export type ShipTo = { name: string; }

export type SODestination = Destination | ShipTo;

export type LoadQuestionAnswer = { text: string; correct: boolean; }
export interface LoadQuestion {
  id: string;
  question: string;
  answers: LoadQuestionAnswer[];
  allowPlainTextAnswer: boolean;
  minNumOfAnswers: number;
  maxNumOfAnswers: number;
  incorrectAnswerMessage: string;
}

export interface Order {
  number: string;
  requested: number,
  customer: Customer,
  products: Array<string>;
  destination?: Destination,
  loadQuestions: LoadQuestion[],
}

type WithSite = { siteId: string };
type WithStagedOrder = { siteId: string, stagedOrder: StagedOrder };

type State =
  | { step: Step.SafetyMessage }
  | { step: Step.SelectSite }
  | { step: Step.StageOrder } & WithSite
  | { step: Step.CheckIn, checkedInTrucksQueued?: number, openStatus?: OpenStatus, closeTime?: string } & WithStagedOrder
  | { step: Step.TareWeight } & WithStagedOrder
  | { step: Step.BayDirections, bayName?: string } & WithStagedOrder
  | { step: Step.GrossWeight, tareWeight: Weight } & WithStagedOrder
  | { step: Step.RejectedGross, officePhoneNumber?: string } & WithStagedOrder
  | { step: Step.Signature } & WithStagedOrder
  | { step: Step.TicketInstructions, emailTickets: boolean, userEmail?: string } & WithStagedOrder

type Action =
  | { type: ActionType.LoadCancelled }
  | { type: ActionType.LoadCompleted }
  | { type: ActionType.InitialStateReceived, userState: UserState }
  | { type: ActionType.SiteSelected, siteId: string }
  | { type: ActionType.StagedOrderCreated, stagedOrder: StagedOrder }
  | { type: ActionType.TareAccepted, bayName?: string }
  | { type: ActionType.DirectToBay, bayName?: string }
  | { type: ActionType.AtScale, tareWeight: Weight }
  | { type: ActionType.GrossVerified }
  | { type: ActionType.GrossRejected, officePhoneNumber?: string }
  | { type: ActionType.GetSignature }
  | { type: ActionType.SignatureSubmitted, emailTickets: boolean, userEmail?: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.InitialStateReceived:
      if (action.userState.state === UserStates.ConfigureLoad) {
        return {
          ...action.userState,
          step: Step.SelectSite
        };
      }

      if (action.userState.state === UserStates.CheckIn) {
        return {
          ...action.userState,
          step: Step.CheckIn,
        }
      } else if (action.userState.state === UserStates.TareWeight) {
        return {
          ...action.userState,
          step: Step.TareWeight,
        }
      } else if (action.userState.state === UserStates.BayDirections) {
        return {
          ...action.userState,
          step: Step.BayDirections,
        }
      } else if (action.userState.state === UserStates.GrossWeight) {
        return {
          ...action.userState,
          step: Step.GrossWeight,
          tareWeight: {
            unit: action.userState.tareUnit,
            value: action.userState.tareValue,
          }
        }
      } else if (action.userState.state === UserStates.RejectedGross) {
        return {
          ...action.userState,
          officePhoneNumber: action.userState.officePhoneNumber,
          step: Step.RejectedGross,
        }
      } else if (action.userState.state === UserStates.Signature) {
        return {
          ...action.userState,
          step: Step.Signature,
        }
      } else {
        return {
          ...action.userState,
          step: Step.TicketInstructions,
        }
      }

    case ActionType.SiteSelected:
      return { step: Step.StageOrder, siteId: action.siteId };

    case ActionType.StagedOrderCreated:
      if (state.step === Step.StageOrder) {
        return { ...state, ...action, step: Step.CheckIn };
      }
      break;

    case ActionType.LoadCancelled:
      return { step: Step.SelectSite };

    case ActionType.LoadCompleted:
      return { step: Step.SelectSite };

    case ActionType.TareAccepted:
    case ActionType.DirectToBay:
      if (state.step === Step.CheckIn || state.step === Step.TareWeight) {
        return { ...state, step: Step.BayDirections, bayName: action.bayName };
      }
      break;

    case ActionType.AtScale:
      if (state.step === Step.BayDirections) {
        return { ...state, step: Step.GrossWeight, tareWeight: action.tareWeight };
      }
      break;

    case ActionType.GrossVerified:
    case ActionType.GetSignature:
      if (state.step === Step.BayDirections || state.step === Step.GrossWeight) {
        return { ...state, step: Step.Signature };
      }
      break;

    case ActionType.GrossRejected:
      if (state.step === Step.GrossWeight) {
        return { ...state, officePhoneNumber: action.officePhoneNumber, step: Step.RejectedGross };
      }
      break;

    case ActionType.SignatureSubmitted:
      if (state.step === Step.Signature) {
        return {
          ...state,
          step: Step.TicketInstructions,
          emailTickets: action.emailTickets,
          userEmail: action.userEmail
        };
      }
      break;
  }
  throw Error("Invalid action received: " + JSON.stringify(action));
}

const StagedOrderCreationStep = (step: Step): boolean => {
  return step === Step.SelectSite || step === Step.StageOrder;
}

const UserFlow = (): JSX.Element => {
  const { _ } = useLingui();
  const [state, dispatch] = useReducer(reducer, { step: Step.SafetyMessage });
  const [userAlertMessage, setUserAlertMessage] = useState<string | undefined>(undefined)

  const stateRefreshTimeMs = parseInt(process.env.REACT_APP_GET_STATE_INTERVAL_MS ?? "3000");

  const userStateQuery = useUserState({
    autoQuery: false,
    onSuccess: dto => {
      if (!StagedOrderCreationStep(state.step) && state.step !== Step.TicketInstructions)
        dispatch({ type: ActionType.InitialStateReceived, userState: dto });
      setUserAlertMessage(dto.alert != undefined ? _(driverFacingMessage(dto.alert.messageCode, dto.alert.parameters ?? [])) : undefined);
    }
  });

  const loadCancelled = () => dispatch({ type: ActionType.LoadCancelled });

  const loadCompleted = () => dispatch({ type: ActionType.LoadCompleted });

  const cancelButton = () => <CancelStagedOrderButton onCancelled={loadCancelled} />;

  const shouldQueryState = state.step !== Step.SafetyMessage;
  useInterval(() => shouldQueryState ? userStateQuery.query() : undefined, stateRefreshTimeMs, true);

  const additionalInstructions = (emailTickets: boolean, userEmail?: string) => 
    emailTickets && !!userEmail
      ? _(msg`Additional copy emailed to ${userEmail}`)
      : undefined;

  switch (state.step) {
    case Step.SafetyMessage:
      return <SafetyMessage signedIn={true} ackSafetyMessage={() => userStateQuery.query()} />

    case Step.SelectSite:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <SelectSite siteSelected={siteId => dispatch({ type: ActionType.SiteSelected, siteId })} />
      </AlertMessageView>

    case Step.StageOrder:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <StagingSettingsQuery
          siteId={state.siteId}
          render={settings =>
            <StageOrderFlow
              siteId={state.siteId}
              enableRememberMe={true}
              settings={settings}
              stagedOrderCreated={(so) =>
                dispatch({ type: ActionType.StagedOrderCreated, stagedOrder: { ...so } })
              }
              loadQuestionsAnswerRejected={loadCancelled} />
          }
        />
      </AlertMessageView>

    case Step.CheckIn:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <CheckIn
          stagedOrderId={state.stagedOrder.id}
          siteId={state.siteId}
          orderNumber={state.stagedOrder.orderNumber}
          checkedInTrucksQueued={state.checkedInTrucksQueued}
          openStatus={state.openStatus}
          closeTime={state.closeTime}
          onCancelled={loadCancelled} />
      </AlertMessageView>

    case Step.TareWeight:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <TareWeight
          siteId={state.siteId}
          scaleId={state.stagedOrder.tareScaleId!}
          soId={state.stagedOrder.id}
          requestedAmount={state.stagedOrder.requestedAmount}
          tareAccepted={(bayName) => dispatch({ type: ActionType.TareAccepted, bayName })}
          devToolItems={cancelButton()} />
      </AlertMessageView>

    case Step.BayDirections:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <BayDirections
          bayName={state.bayName}
          useDataMatrix={state.stagedOrder.requiresGross}
          orderNumber={state.stagedOrder.orderNumber}
          stagedOrderId={state.stagedOrder.id}
          siteId={state.siteId}
          devToolItems={cancelButton()} />
      </AlertMessageView>

    case Step.GrossWeight:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <GrossWeight
          tareWeight={state.tareWeight}
          allowReject={true}
          grossAccepted={() => dispatch({ type: ActionType.GrossVerified })}
          grossRejected={officePhoneNumber => dispatch({ type: ActionType.GrossRejected, officePhoneNumber })}
          siteId={state.siteId}
          scaleId={state.stagedOrder.grossScaleId!}
          stagedOrderId={state.stagedOrder.id}
          devToolItems={cancelButton()} />
      </AlertMessageView>

    case Step.RejectedGross:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <RejectedGross
          siteId={state.siteId}
          orderNumber={state.stagedOrder.orderNumber}
          phoneNumber={state.officePhoneNumber}
          stagedOrderId={state.stagedOrder.id}
          devToolItems={cancelButton()} />
      </AlertMessageView>

    case Step.Signature:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <Signature
          stagedOrderId={state.stagedOrder.id}
          signatureSubmitted={(emailTickets, userEmail) =>
            dispatch({
              type: ActionType.SignatureSubmitted,
              emailTickets: emailTickets,
              userEmail: userEmail
            })}
          devToolItems={cancelButton()} />
      </AlertMessageView>

    case Step.TicketInstructions:
      return <AlertMessageView message={userAlertMessage} messageAcknowledged={() => userStateQuery.query()}>
        <TicketInstructions
          stagedOrderId={state.stagedOrder.id}
          additionalInstructions={additionalInstructions(state.emailTickets, state.userEmail)}
          loadCompleted={loadCompleted} />
      </AlertMessageView>
  }
}

export default UserFlow;
