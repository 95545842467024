import { useState } from "react";
import SelectSearch, { SelectOption } from "../common/forms/SelectSearch";
import { useUserQuery } from "../common/lib/fetch";
import QueryView from "../common/lib/QueryView";
import SlideOutButton from "../common/SlideOutButton";
import useArrivedAtBay from "../requests/useArrivedAtBay";
import useCodeScanned from "../requests/useCodeScanned";
import useDispensingCompleted from "../requests/useDispensingCompleted";
import useTicketCreated from "../requests/useTicketCreated";

interface ScanButtonProps {
  stagedOrderId: string;
  siteId?: string;
  location: 'CheckIn' | 'ArrivedAtBay' | 'AtOutbound' | 'DispensingComplete' | 'TicketCreated';
}

interface ScaleDto {
  id: string,
  name: string,
}

interface BayDto {
  id: string,
  name: string,
}

interface SiteSettingsDto { 
  scales: Array<ScaleDto>;
  bays: Array<BayDto>;
}

const emptyId = '00000000-0000-0000-0000-000000000000';

const ScanButton = ({ stagedOrderId, siteId, location }: ScanButtonProps) => {
  const [scaleId, setScaleId] = useState<undefined | string>(undefined);
  const [bayId, setBayId] = useState<string>(emptyId);

  const codeScanned = useCodeScanned(stagedOrderId);
  const dispensingCompleted = useDispensingCompleted(stagedOrderId);
  const ticketCreated = useTicketCreated(stagedOrderId);
  const arrivedAtBay = useArrivedAtBay(stagedOrderId, bayId);

  const getSiteSettings = useUserQuery<SiteSettingsDto>({
    path: `/api/sites/${siteId}/settings`,
  });

  const noScaleOption: SelectOption = { value: emptyId, name: 'No scale' };

  const scaleOptions = (siteSettings: SiteSettingsDto) =>
    [noScaleOption, ...siteSettings.scales.map<SelectOption>(s => ({ value: s.id, name: s.name }))];

  const bayOptions = (siteSettings: SiteSettingsDto) =>
    siteSettings.bays.map<SelectOption>(s => ({ value: s.id, name: s.name }));

  const request = () => {
    if (location === 'CheckIn') {
      codeScanned.request({ scaleId: scaleId === emptyId ? undefined : scaleId });
    } else if (location === 'AtOutbound') {
      codeScanned.request({ scaleId: scaleId === emptyId ? undefined : scaleId });
    } else if (location === 'ArrivedAtBay') {
      arrivedAtBay.request();
    } else if (location === 'TicketCreated') {
      ticketCreated.request();
    } else {
      dispensingCompleted.request();
    }
  }

  return <>
    <SlideOutButton
      text={
        location === 'DispensingComplete'
          ? "Done Dispensing"
          : location === 'TicketCreated' 
            ? "Ticket Created"
            : location === 'ArrivedAtBay'
              ? "Arrive at Bay"
              : "Scan"
      }
      onClick={request} />
    {(location === 'CheckIn' || location === 'AtOutbound') && <QueryView query={getSiteSettings} renderData={siteSettings =>
      <SelectSearch
        id='scale'
        className='dropdown-item pt-0 mb-2'
        placeholder='Select a scale...'
        options={scaleOptions(siteSettings) ?? []}
        onChange={id => setScaleId(id)}
      />}
    />}
    {(location === 'ArrivedAtBay') && <QueryView query={getSiteSettings} renderData={siteSettings =>
      <SelectSearch
        id='bay'
        className='dropdown-item pt-0'
        placeholder='Select a bay...'
        options={bayOptions(siteSettings) ?? []}
        onChange={id => setBayId(id)}
      />}
    />}
  </>
}

export default ScanButton;
