import { Path, useForm } from "react-hook-form";
import { FormText } from "../../common/forms/FormText";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import Container, { Progress } from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import useVerifyOrderNumber from "../../requests/useVerifyOrderNumber";
import { Order } from "../../UserFlow";
import SwitchToMobileQRCode from "../../common/SwitchToMobileQRCode";
import useIsAnonymous from "../../common/lib/hooks/useIsAnonymous";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { driverFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";

interface OrderNumberProps {
  siteId: string;
  progress?: Progress;
  initialOrderNumber?: string;
  orderSelected: (number: Order) => void
  kioskId?: string;
}

interface OrderNumberForm {
  number: string;
}

const OrderNumber = (props: OrderNumberProps) => {
  const { _ } = useLingui();
  const isAnonymous = useIsAnonymous();
  const displayMessage = useDisplayMessage();

  const { register, handleSubmit, setValue, formState, watch } = useForm<OrderNumberForm>({
    defaultValues: { number: props.initialOrderNumber ?? "" }
  });

  const orderNumber = watch("number");

  const formProps = (name: Path<OrderNumberForm>) => ({
    name,
    setValue,
    register,
    errors: formState.errors
  });

  const getOrder = useVerifyOrderNumber(props.siteId, orderNumber, {
    autoQuery: false,
    onSuccess: props.orderSelected,
    onError: (err) => displayMessage.fail(_(driverFacingErrorMessage(err))),
  }, props.kioskId);

  const submit = () => {
    getOrder.query();
  }

  const formWidth = isAnonymous ? "w-65" : "w-75";

  return <Container
    title={_(msg`Load Information`)}
    subtitle={_(msg`What's your order number?`)}
    progress={props.progress}>

    {isAnonymous && <SwitchToMobileQRCode />}
    <div className={formWidth + " m-auto"}>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <FormText {...formProps("number")}
        displayName={_(msg`Enter Order Number`)}
        placeholder={_(msg`Enter order number`)}
        autoComplete={!isAnonymous}
        options={{
          required: _(msg`Order Number is required`),
          validate: {
            length: v => v.length < 51 || _(msg`Order Number cannot be longer than 50 characters`)
          },
        }}
      />
    </div>
    <Footer nextButton={
      <FooterButton
        onClick={handleSubmit(submit)}
        spinning={getOrder.isLoading}
        disabled={!orderNumber}>
        <Trans>Next</Trans>
      </FooterButton>
    } />
  </Container>
}

export default OrderNumber;