import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Container from "../common/Container";
import DataMatrix from "../common/DataMatrix";
import Row from "../common/Row";
import ScanButton from "../dev/ScanButton";

interface RejectedGrossProps {
  stagedOrderId: string,
  siteId: string,
  orderNumber: string,
  phoneNumber?: string,
  devToolItems?: React.ReactNode,
}

const RejectedGross = (props: RejectedGrossProps) => {
  const { _ } = useLingui();
  const sizePx = 320;

  return <Container
    title={_(msg`Gross Weight Rejected`)}
    subtitle={_(msg`Call office for directions ${props.phoneNumber ?? ''}`)}
    devToolItems={
      <>
        <ScanButton location={'CheckIn'} siteId={props.siteId} stagedOrderId={props.stagedOrderId} />
        {props.devToolItems}
      </>
    }>
    <div className="container m-auto">
      <Row className="mt-5" style={{ height: sizePx }}>
        <div className="position-relative">
          <div className="position-absolute top-0 start-50 translate-middle-x">
            <DataMatrix content={props.stagedOrderId} />
          </div>
        </div>
      </Row>
      <Row className="mt-5 text-center">
        <h2><Trans>Order Number {props.orderNumber}</Trans></h2>
      </Row>
    </div>
  </Container>
}

export default RejectedGross;
