import { HttpMethod } from "../common/lib/fetch/useFetch";
import { RequestOptions } from "../common/lib/fetch/useRequest";
import useUserRequest from "../common/lib/fetch/useUserRequest";
import { useUser } from "../userAccess/useUser";

export enum DestinationOptions {
  None = "None",
  ShowDestination = "ShowDestination",
  AllowDestinationChange = "AllowDestinationChange",
  AllowEnteredShipTo = "AllowEnteredShipTo",
  AllowDestinationChangeAndRequireDestination = "AllowDestinationChangeAndRequireDestination",
  AllowEnteredShipToAndRequireDestination = "AllowEnteredShipToAndRequireDestination",
}

export interface CustomerDestinationSettings { setting: DestinationOptions; }

export default function useUpdateCustomerDestinationSettings(options?: RequestOptions) {
  const user = useUser();

  return useUserRequest<CustomerDestinationSettings>({
    path: `/api/sites/${user.selectedSite!.id}/destinationSettings`,
    method: HttpMethod.PUT
  }, options)
}
