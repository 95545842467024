import { HttpMethod, RequestOptions, useUserOrKioskRequest } from "../common/lib/fetch";
import { Units } from "../common/units/units";

export type OutboundStagedOrder = {
  id: string,
  tareValue: string,
  tareUnit: Units,
  hasBeenGrossed: boolean,
  requiresGross: boolean,
}

const useGetOutboundStagedOrder = (siteId: string, orderNumber: string, kioskId: string, options: RequestOptions<OutboundStagedOrder>) =>
  useUserOrKioskRequest<void, OutboundStagedOrder>({
    method: HttpMethod.POST,
    path: `/api/sites/${siteId}/stagedOrders/getOutbound?number=${orderNumber}&kioskId=${kioskId}`
  }, options);

export default useGetOutboundStagedOrder;
