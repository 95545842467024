import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Container from "../../common/Container";
import Footer from "../../common/Footer";
import FooterButton from "../../common/FooterButton";
import useInterval from "../../common/lib/hooks/useInterval";
import DataMatrixDisplay from "./DataMatrixDisplay";

interface BayDirectionsProps {
  stagedOrderId: string,
  orderNumber: string,
  bayName: string,
  shouldPrint: boolean,
  confirmed: () => void,
}

const AnonymousBayDirections = (props: BayDirectionsProps) => {
  const helperText = "Proceed To Bay";
  const leaveTimeMs = 15000;
  const [hasPrinted, setHasPrinted] = useState(false);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Check-in Successful",
    onAfterPrint: () => setHasPrinted(true),
    content: () => componentRef.current!,
  });

  useEffect(() => {
    if (!hasPrinted && props.shouldPrint) {
      handlePrint();
    }
  }, [handlePrint, hasPrinted, props.shouldPrint]);

  useInterval(props.confirmed, leaveTimeMs, false);

  return <Container
    title={props.bayName ? "Proceed to " + props.bayName : helperText}
    subtitle={props.bayName ? helperText : undefined}>
    <div className="m-auto">
      {props.shouldPrint &&
        <span className="display-2">
          {hasPrinted ? "Pick up ticket at printer" : "Printing ticket"}
        </span>
      }
    </div>
    <div className="d-none">
      <div ref={componentRef} className="m-auto px-5">
        <DataMatrixDisplay
          stagedOrderId={props.stagedOrderId}
          orderNumber={props.orderNumber}
          bayDirections={"Proceed to " + props.bayName} />
      </div>
    </div>
    <Footer
      nextButton={
        <FooterButton onClick={props.confirmed}>
          Return
        </FooterButton>
      } />
  </Container>
}

export default AnonymousBayDirections;
