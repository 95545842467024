import { SubmitHandler, useForm } from "react-hook-form";
import useDisplayMessage from "../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../common/lib/KaAlert";
import QueryView from "../../common/lib/QueryView";
import SpinnerButton from "../../common/SpinnerButton";
import { adminFacingErrorMessage } from "../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../requests/useGetSiteSettings";
import useUpdateBayValidationSettings, { BayValidationSettings } from "../../requests/useUpdateBayValidationSettings";

const BayDirectionsValidation = () => {
  const getBaySettings = useGetSiteSettings<BayValidationSettings>();

  return <QueryView
    query={getBaySettings}
    renderData={settings =>
      <ValidationForm
        printBarcodesAtKiosk={settings.printBarcodesAtKiosk} 
        showBarcodeForAutomatedLoadout={settings.showBarcodeForAutomatedLoadout} />
    } />
}

interface FormProps { printBarcodesAtKiosk: boolean, showBarcodeForAutomatedLoadout: boolean }

const ValidationForm = (props: FormProps) => {
  const displayMessage = useDisplayMessage();

  const updateBayValidationSettings = useUpdateBayValidationSettings({
    onSuccess: () => displayMessage.success("Settings updated"),
    onError: (err) => displayMessage.fail(adminFacingErrorMessage(err))
  });

  const { register, handleSubmit } = useForm<BayValidationSettings>({
    defaultValues: {
      printBarcodesAtKiosk: props.printBarcodesAtKiosk,
      showBarcodeForAutomatedLoadout: props.showBarcodeForAutomatedLoadout
    },
  });

  const onSubmit: SubmitHandler<BayValidationSettings> = (formValue) => updateBayValidationSettings.request({
    printBarcodesAtKiosk: formValue.printBarcodesAtKiosk,
    showBarcodeForAutomatedLoadout: formValue.showBarcodeForAutomatedLoadout
  });

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form className="m-auto w-50">
      <h2>Bay Directions Validation</h2>
      <div className="border border-dark rounded">        
        <div className="m-3">
          <div className="form-check">
            <input type="checkbox" id="PrintBarcodesAtKiosk" className="form-check-input" {...register("printBarcodesAtKiosk")} />
            <label className="form-check-label" htmlFor="PrintBarcodesAtKiosk">Print Barcodes at Kiosk</label>
          </div>
          <div className="form-check">
            <input type="checkbox" id="showBarcodeForAutomatedLoadout" className="form-check-input" {...register("showBarcodeForAutomatedLoadout")} />
            <label className="form-check-label" htmlFor="showBarcodeForAutomatedLoadout">Show Barcode When Not Returning to Scale</label>
          </div>
        </div>
      </div>
    </form>
    <div className="align-self-end">
      <SpinnerButton
        className="btn btn-primary mx-4 mb-4 px-5 py-3"
        spinning={updateBayValidationSettings.isLoading}
        onClick={handleSubmit(onSubmit)}>
        Save
      </SpinnerButton>
    </div>
  </>
}

export { BayDirectionsValidation };
