import { useContext, useEffect } from "react";
import FullscreenLoadingSpinner from "./common/FullscreenLoadingSpinner";
import { KioskContext, KioskState } from "./userAccess/kiosk/KioskContext";
import AnonymousFlow from "./AnonymousFlow";
import StagingSettingsQuery from "./common/StagingSettingsQuery";
import { KioskRegistration } from "./pages/AdminPortal/Kiosks/KioskRegistration";
import SiteAdminRequired from "./SiteAdminRequired";
import { UserContext, UserState } from "./userAccess/UserContext";

export const Anonymous = () => {
  const kiosk = useContext(KioskContext);
  const userStatus = useContext(UserContext);

  useEffect(() => {
    if (kiosk.state === KioskState.SignedIn && userStatus.state === UserState.SignedIn) {
      userStatus.signOut("/anonymous");
    }
  });

  switch (kiosk.state) {
    case KioskState.Authorizing:
      return <FullscreenLoadingSpinner />

    case KioskState.NotRegistered:
      return <SiteAdminRequired>
        <KioskRegistration registered={kiosk.register} />
      </SiteAdminRequired>

    case KioskState.SignedIn:
      return <StagingSettingsQuery
        siteId={kiosk.siteId}
        render={settings => <AnonymousFlow siteId={kiosk.siteId} settings={settings} />} />
  }
}
