import ContainerTextHeader from '../common/ContainerTextHeader';
import { useForm } from "react-hook-form";
import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import { HttpMethod } from '../common/lib/fetch/useFetch';
import KaAlert from '../common/lib/KaAlert';
import { useState } from 'react';
import SelectSearch, { SelectOption } from '../common/forms/SelectSearch';
import QueryView from '../common/lib/QueryView';
import useUserQuery from '../common/lib/fetch/useUserQuery';
import useUserRequest from '../common/lib/fetch/useUserRequest';
import { adminFacingErrorMessage } from '../common/userFacingMessages/userFacingMessages';

const AddSiteAdmins = () => {
  const displayMessage = useDisplayMessage();
  interface Site {
    id: string,
    city: string,
    state: string,
  };
  interface Business {
    id: string;
    name: string;
    sites: Array<Site>;
  }

  interface SiteAdminDto {
    adminEmail: string;
  }

  const [business, setBusiness] = useState<undefined | Business>(undefined);
  const [site, setSite] = useState<undefined | Site>(undefined);
  const addSiteAdminRequest = useUserRequest<SiteAdminDto>({
    path: '/api/businesses/' + business?.id + '/sites/' + site?.id + '/addSiteAdmin',
    method: HttpMethod.POST
  }, {
    onSuccess: () => {
      displayMessage.success("Site Admin added successfully");
    },
    onError: (err) => {displayMessage.fail("Failed to add site admin: " + adminFacingErrorMessage(err));}
  });

  const { register, handleSubmit, formState: { errors } } = useForm<SiteAdminDto>();

  const disableInput = (site == undefined);

  const useBusinessesQuery = useUserQuery<Business[]>({
    path: "/api/businesses/asAdmin"
  }, {
    onError: (err) => { displayMessage.fail("Error: " + adminFacingErrorMessage(err))}
  });

  return (
    <ContainerTextHeader message="Add Site Administrators">
      <QueryView
        query={useBusinessesQuery}
        renderData={businesses => <>
          {displayMessage?.message != null && <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />}
          <div className="mt-3">
            <SelectSearch
              id='business'
              placeholder='Select a business...'
              options={businesses.map<SelectOption>(b => ({ value: b.id, name: b.name }))}
              onChange={bId => { 
                setBusiness(businesses.find(b => b.id === bId)); 
                setSite(undefined);
              }}
            />
          </div>
          <div className="mt-3">
            <SelectSearch
              id='site'
              placeholder='Select a site...'
              options={business ? business.sites.map<SelectOption>(s => ({ value: s.id, name: s.city + ', ' + s.state })) : []}
              onChange={sId => setSite(business ? business.sites.find(s => s.id === sId) : undefined)}
            />
          </div>
          <div className="mt-3">
            <form onSubmit={handleSubmit(data => addSiteAdminRequest.request(data))}>
              <input className="form-control" placeholder="Site Admin Email Address" disabled={disableInput} {...register("adminEmail", { required: true })} />
              {errors.adminEmail && <p className="text-danger">Email address is required</p>}
              <div className="text-right mt-5 position-relative">
                <button className="btn btn-primary position-absolute top-0 end-0 w-25" type="submit" disabled={disableInput} >
                  Add Site Admin
                </button>
              </div>
            </form>
          </div>
        </>}/>
    </ContainerTextHeader> 
  );
}

export default AddSiteAdmins;