import useQuery, { QueryOptions } from "../common/lib/fetch/useQuery";
import { useKaEmployee } from "../userAccess/kaEmployee/useKaEmployee";

export interface PastOrder {
  orderId: string;
  orderNumber: string;
  completedAt: string;
}

export const useGetPastOrders = (siteId: string, options?: QueryOptions<PastOrder[]>) => {
  const kaEmployee = useKaEmployee();
  return useQuery<PastOrder[]>({
    headers: kaEmployee.fetchHeaders,
    path: `/api/developer/sites/${siteId}/pastOrders`,
  }, options);
}