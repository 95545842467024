import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import Button from "../../../common/Button";
import { Query } from "../../../common/lib/fetch";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import QueryView from "../../../common/lib/QueryView";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import useGetSiteSettings from "../../../requests/useGetSiteSettings";
import useRegisterKiosk, { RegisterKiosk } from "../../../requests/useRegisterKiosk";
import { useUser } from "../../../userAccess/useUser";
import { CreateUpdateKioskModal } from "./CreateUpdateKioskModal";

interface KioskDto {
  id: string,
  role: string,
  name: string,
  scaleId?: string,
}

interface ScaleDto { 
  id: string,
  name: string,
}

export interface SiteSettingsDto {
  kiosks: Array<KioskDto>;
  scales: Array<ScaleDto>;
}

type RProps = {
  registered: (Kioskid: string, secret: string) => void;
}

export const KioskRegistration = (props: RProps) => {
  const getSiteSettings = useGetSiteSettings<SiteSettingsDto>();

  return <QueryView
    query={getSiteSettings}
    renderData={response => <KioskRegistrationForm {...props} kiosks={response.kiosks} scales={response.scales} getSiteSettings={getSiteSettings} />}
  />
}

type Props = {
  kiosks: Array<KioskDto>;
  scales: Array<ScaleDto>;
  getSiteSettings: Query<SiteSettingsDto>;
  registered: (Kioskid: string, secret: string) => void;
}

const KioskRegistrationForm = (props: Props) => {
  const displayMessage = useDisplayMessage();
  const user = useUser();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const registerKioskRequest = useRegisterKiosk(user.selectedSite!.id, {
    onSuccess: (resp) => {
      props.registered(resp.id, resp.clientSecret);
    },
    onError: (err) => {
      displayMessage.fail(adminFacingErrorMessage(err));
    } 
  });
  
  const onCreateSuccess = (): void => {
    setShowCreateModal(false);
    props.getSiteSettings.query();
  }

  const toggleKioskModal = (): void => {
    setShowCreateModal(!showCreateModal);
  }

  const scaleOptions: Array<ScaleDto> = props.scales.filter(s => !props.kiosks.some(k => k.scaleId === s.id));

  const registerKiosk: SubmitHandler<RegisterKiosk> = (kiosk) => registerKioskRequest.request(kiosk);

  return <>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    {showCreateModal && <CreateUpdateKioskModal 
      show={showCreateModal} 
      scaleOptions={scaleOptions} 
      onSuccess={onCreateSuccess} 
      toggle={toggleKioskModal}/>}
    <form className="m-auto w-85">
      <h2>Kiosks</h2>
      <div className="border border-dark rounded">
        <div className="m-3">
          <div className="row mb-2">
            <h5 className="col-2 my-auto w-50">
              Kiosk
            </h5>
            <h5 className="col-2 my-auto w-50">
              Scale
            </h5>
          </div>
          {props.kiosks != null && <div>
            {props.kiosks
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((p, i) =>
                <div key={p.id} className="row mb-2">
                  <label className="col-2 my-auto w-50">
                    {p.name}
                  </label>
                  <label className="col-2 my-auto w-25">
                    {p.scaleId === undefined ? "" : props.scales.find(s => s.id === p.scaleId)?.name}
                  </label>
                  <Button
                    className="col w-25"
                    id={`cbx${p.name}`}
                    onClick={() => registerKiosk(p)}>
                    Register
                  </Button>
                </div>
              )}
          </div>}
          <div className="row mt-3">
            <Button
              className="mx-auto w-25"
              onClick={toggleKioskModal}>
              Add Kiosk
            </Button>
          </div>
        </div>
      </div>
    </form>
  </>
}
