import React from 'react'

export type KaEmployeeStatus =
  | { state: KaEmployeeState.Loading }
  | {
    state: KaEmployeeState.SignedOut;
    signIn: () => void;
  }
  | KaEmployee;

export enum KaEmployeeState {
  Loading,
  SignedOut,
  SignedIn,
}

export interface KaEmployee {
  state: KaEmployeeState.SignedIn;
  id: string;
  email: string;
  expirationTime: number;
  fetchHeaders: () => Promise<Headers>;
  signOut: () => void;
}

export const KaEmployeeContext =
  React.createContext<KaEmployeeStatus>({ state: KaEmployeeState.Loading });
