import { SubmitHandler, useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { adminFacingErrorMessage } from "../../../common/userFacingMessages/userFacingMessages";
import KaModal from "../../../common/KaModal";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import { UpsertKioskDto } from "../../../requests/dtos/UpsertKioskDto";
import useAddKiosk from "../../../requests/useAddKiosk";
import useUpdateKiosk from "../../../requests/useUpdateKiosk";
import { useUser } from "../../../userAccess/useUser";

interface KioskDto {
  id: string,
  name: string,
  role: string,
  scaleId?: string,
}

interface ScaleDto { 
  id: string,
  name: string,
}

interface Props {
  show: boolean,
  kiosk?: KioskDto,
  scaleOptions: ScaleDto[],
  onSuccess: () => void,
  toggle: () => void,
}

export const CreateUpdateKioskModal = (props: Props) => {
  const displayMessage = useDisplayMessage();
  const user = useUser();
  let { id, ...initialKiosk } = { ...props.kiosk };

  const createKioskRequest = useAddKiosk(user.selectedSite!.id, {
    onSuccess: () => {
      props.onSuccess();
    },
    onError: (err) => {
      displayMessage.fail("Failed to create kiosk: " + adminFacingErrorMessage(err))
    }
  });

  const updateKioskRequest = useUpdateKiosk(user.selectedSite!.id, props.kiosk?.id ?? "", {
    onSuccess: () => {
      props.onSuccess();
    },
    onError: (err) => {
      displayMessage.fail("Failed to update kiosk: " + adminFacingErrorMessage(err))
    }
  });
  
  const { register, handleSubmit } = useForm<UpsertKioskDto>({
    defaultValues: {
      name: initialKiosk.name,
      role: initialKiosk.role,
      scaleId: initialKiosk.scaleId ?? "",
    },
  });

  const onSubmit: SubmitHandler<UpsertKioskDto> = (data) => props.kiosk === undefined ? 
    createKioskRequest.request({ 
      name: data.name, role: data.role, scaleId: data.scaleId === "" ? undefined : data.scaleId }) :
    updateKioskRequest.request({ 
      name: data.name, role: data.role, scaleId: data.scaleId === "" ? undefined : data.scaleId });

  return <KaModal
    isOpen={props.show}
    toggle={props.toggle}
    title={"Kiosk Information"}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="modal-body">
        <Row>
          <Col xs={3}>
            <label htmlFor="kioskName" className="col-form-label">Kiosk Name</label>
          </Col>
          <Col xs={8}>
            <input
              type="text"
              id="kioskName"
              className="form-control my-auto"
              placeholder="Kiosk Name"
              {...register("name", {
              })} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={3}>
            <label htmlFor="kioskRole" className="col-form-label">Role</label>
          </Col>
          <Col xs={8}>
            <select
              id="kioskRole"
              className="form-select"
              {...register("role", {
                required: true
              })} >
              <option className='dropdown-item'>Inbound</option>
              <option className='dropdown-item'>Outbound</option>
              <option className='dropdown-item'>Both</option>
            </select>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={3}>
            <label htmlFor="kioskScale" className="col-form-label">Scale</label>
          </Col>
          <Col xs={8}>
            <select
              id="kioskScale"
              className="form-select"
              {...register("scaleId")}>
                <option value="">No scale</option>
                {props.scaleOptions.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
            </select>
          </Col>
        </Row>
      </div>
      </>)}
    footer={(<>
      <button type="button"
        className="btn btn-white"
        onClick={props.toggle}>
        Close
      </button>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={handleSubmit(onSubmit)}>
        {props.kiosk === undefined ? "Create Kiosk" : "Update Kiosk"}
      </button>
    </>)}
  />
}