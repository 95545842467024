import { QueryOptions, useUserOrKioskQuery } from "../common/lib/fetch";
import { Order } from "../UserFlow";

const useVerifyOrderNumber = (siteId: string, orderNumber: string, options: QueryOptions<Order>, kioskId?: string) => {
  const baseUrl = `/api/sites/${siteId}/getOrderByNumber`;
  const urlParams = new URLSearchParams();

  urlParams.append('number', orderNumber);
  if (kioskId) urlParams.append('kioskId', kioskId);

  return useUserOrKioskQuery<Order>({
    path: `${baseUrl}?${urlParams.toString()}`
  }, options);
}

export default useVerifyOrderNumber;
